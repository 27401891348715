import React, {useState, useContext} from 'react'
import { GlobalState } from '../../../GlobalState'
import Background from './background/Background'
import Logo from './logo/Logo'
import Categgory from './category/Categgory'
import CategoryRight from './categoryRight/CategoryRight'
import MediaVideo from './mediaVideo/MediaVideo'
import MediaPhotos from './mediaPhotos/MediaPhotos'
import LinkFacebook from './linkFacebook/LinkFacebook'
import LinkZalo from './linkZalo/LinkZalo'
import LinkYoutube from './linkYoutube/LinkYoutube'
import { LuMenuSquare } from "react-icons/lu";
import { CiMenuKebab } from "react-icons/ci";

const CSS_design = {
  li: 'bg-[#0099CC] mb-[10px] text-white font-bold px-[10px] py-[10px] flex items-center cursor-pointer hover:bg-[#339999] rounded-sm mx-[1px]'
}

export default function TrangChu({changeState}) {
  const state = useContext(GlobalState)
  const [isMobile] = state.responsive.isMobile
  const [background, setBackground] = useState(true)
  const [logo, setLogo] = useState(true)
  const [categgory, setCategory] = useState(false)
  const [categoryRight, setCategoryRight] = useState(false)
  const [mediaVideo, setMediaVideo] = useState(false)
  const [mediaPhotos, setMediaPhotos] = useState(false)
  const [linkFacebook, setLinkFacebook] = useState(false)
  const [linkZalo, setLinkZalo] = useState(false)
  const [linkYoutube, setLinkYoutube] = useState(false)
  const [showmenu, setShowmenu] = useState(true)
  const [showMenuChildHomePage, SetshowMenuChildHomePage] = useState(false)

  const handleClick = (value)=>{
    setShowmenu(false)
    if(value===1){
      setBackground(true)
      setLogo(false)
      setCategory(false)
      setCategoryRight(false)
      setMediaVideo(false)
      setMediaPhotos(false)
      setLinkFacebook(false)
      setLinkZalo(false)
      setLinkYoutube(false)
    }
    else if(value===2){
      setLogo(true)
      setBackground(false)
      setCategory(false)
      setCategoryRight(false)
      setMediaVideo(false)
      setMediaPhotos(false)
      setLinkFacebook(false)
      setLinkZalo(false)
      setLinkYoutube(false)
    }
    else if(value===3){
      setCategory(true)
      setLogo(false)
      setBackground(false)
      setCategoryRight(false)
      setMediaVideo(false)
      setMediaPhotos(false)
      setLinkFacebook(false)
      setLinkZalo(false)
      setLinkYoutube(false)
    }
    else if(value===4){
      setCategoryRight(true)
      setCategory(false)
      setLogo(false)
      setBackground(false)
      setMediaVideo(false)
      setMediaPhotos(false)
      setLinkFacebook(false)
      setLinkZalo(false)
      setLinkYoutube(false)
    }
    else if(value===5){
      setMediaVideo(true)
      setCategoryRight(false)
      setCategory(false)
      setLogo(false)
      setBackground(false)
      setMediaPhotos(false)
      setLinkFacebook(false)
      setLinkZalo(false)
      setLinkYoutube(false)
    }
    else if(value===6){
      setMediaPhotos(true)
      setMediaVideo(false)
      setCategoryRight(false)
      setCategory(false)
      setLogo(false)
      setBackground(false)
      setLinkFacebook(false)
      setLinkZalo(false)
      setLinkYoutube(false)
    }
    else if(value===7){
      setLinkFacebook(true)
      setMediaPhotos(false)
      setMediaVideo(false)
      setCategoryRight(false)
      setCategory(false)
      setLogo(false)
      setBackground(false)
      setLinkZalo(false)
      setLinkYoutube(false)
    }
    else if(value===8){
      setLinkZalo(true)
      setLinkFacebook(false)
      setMediaPhotos(false)
      setMediaVideo(false)
      setCategoryRight(false)
      setCategory(false)
      setLogo(false)
      setBackground(false)
      setLinkYoutube(false)
    }
    else if(value===9){
      setLinkYoutube(true)
      setLinkZalo(false)
      setLinkFacebook(false)
      setMediaPhotos(false)
      setMediaVideo(false)
      setCategoryRight(false)
      setCategory(false)
      setLogo(false)
      setBackground(false)
    }
  }

  const handleClick2 = ()=>{
    changeState(true)
  }

  return (
    <div className='w-full h-auto flex relative'>
        <div className={`${isMobile?'':'hidden'}`}>
          <LuMenuSquare size={'35px'} color='#777' className={`${isMobile?'':'hidden'} absolute top-[10px] left-[10px]`}
              onClick={handleClick2}/>
          <CiMenuKebab size={'35px'} color='#777' className={`${isMobile?'':'hidden'} absolute top-[10px] right-[10px]`}
              onClick={()=>setShowmenu(!showmenu)}/>
        </div>

        <div className={`${isMobile?`${!showmenu?'':'hidden'} w-full`:'w-[80%]'} h-auto`}>
          {
            background ? <Background/> :
            logo ? <Logo/> :
            categgory ? <Categgory/> :
            categoryRight ? <CategoryRight/> :
            mediaVideo ? <MediaVideo/> :
            mediaPhotos ? <MediaPhotos/> :
            linkFacebook ? <LinkFacebook/> :
            linkZalo ? <LinkZalo/> :
            linkYoutube ? <LinkYoutube/> :
            <></>
          }
        </div>

        <ul className={`${isMobile?`${showmenu?'translate-x-[0] transition ease-in-out':'translate-x-[100%]'} w-full`:'w-[20%]'} h-screen fixed top-0 right-0 text-[14px] mx-auto bg-[#f6f6f6] pb-[20px] px-[10px]`}>
            <LuMenuSquare size={'35px'} color='#777' className={`${isMobile?'':'hidden'} absolute top-[10px] left-[10px]`}
                onClick={handleClick2}/>
            <li className='font-bold text-[20px] text-center py-[20px]'>DANH MỤC QUẢN LÝ</li>
            <li className={CSS_design.li} onClick={()=>handleClick(1)}>Background</li>
            <li className={CSS_design.li} onClick={()=>handleClick(2)}>Logo</li>
            <li className={CSS_design.li} onClick={()=>handleClick(3)}>Danh mục hiển thị chính</li>
            <li className={CSS_design.li} onClick={()=>handleClick(4)}>Danh mục hiển thị bên phải</li>
            <li className={CSS_design.li} onClick={()=>handleClick(5)}>Media Video</li>
            <li className={CSS_design.li} onClick={()=>handleClick(6)}>Media photos</li>
            <li className={CSS_design.li} onClick={()=>handleClick(7)}>Liên kết facebook</li>
            <li className={CSS_design.li} onClick={()=>handleClick(8)}>Liên kết zalo</li>
            <li className={CSS_design.li} onClick={()=>handleClick(9)}>Liên kết youtube</li>
        </ul>
    </div>
  )
}
