import React, {useContext, useEffect, useState} from 'react'
import { GlobalState } from '../../../../GlobalState'
import { Link } from 'react-router-dom'

import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Section2() {
    const state = useContext(GlobalState)
    const [category] = state.categoryAPI.category
    const [utils] = state.utilsAPI.utils
    const [news] = state.newsFilterCate.news_filter
    const [news_menu_right] = state.newsMenuRight.menu_right
    const [isMobile] = state.responsive.isMobile

    const [utils_cate, setUtils_cate] = useState([])
    const [news_filter, setNews_filter] = useState([])
    const [menu_right, setMenu_right] = useState([])
    const [new_menu_right, setNew_menu_right] = useState([])

    const [hotline, setHotline] = useState('')
    const [email, setEmail] = useState('')

    useEffect(()=>{
        const getUtils = ()=>{
            utils.forEach(el=>{
                setUtils_cate(el.category.split('-').map(item=>parseInt(item)))
                setMenu_right(el.menu_right.split('-').map(item=>parseInt(item)))
                setHotline(el.hotline)
                setEmail(el.email)
            })
        }
        getUtils()
    },[utils])

    useEffect(()=>{
        const handleSortNews = ()=>{
            const groupedData = news.reduce((acc, curr) => {
                const { id, title, description, content, images, author, checked, category, date_created, cluster } = curr;
                if (!acc[category]) {
                    acc[category] = [];
                }
                acc[category].push({ id, title, description, content, images, author, checked, category, date_created, cluster });
                return acc;
            }, {});
            setNews_filter(groupedData)
        }
        handleSortNews()
    },[news])

    useEffect(()=>{
        const handleSortNews = ()=>{
            const groupedData = news_menu_right.reduce((acc, curr) => {
                const { id, title, category } = curr;
                if (!acc[category]) {
                    acc[category] = [];
                }
                acc[category].push({ id, title, category });
                return acc;
            }, {});
            setNew_menu_right(groupedData)
        }
        handleSortNews()
    },[news_menu_right])

    useEffect(() => {
        AOS.init();
    }, []);

  return (
    <div className={`${isMobile?'flex-wrap':''} w-full h-auto flex justify-between`}>
        <div className={`${isMobile?'w-full ml-[20px]':'w-[74%]'} h-auto pt-[20px]`}>
            <div className={`${isMobile?'flex-wrap h-auto':'h-[350px]'} w-full flex justify-between`}>
                {
                    utils_cate.map((u,i)=>{
                        if(i<=2){
                            return <div key={i} className={`${isMobile?'w-full mx-[10px]':'w-[32.2%]'}`}>
                                {
                                    category.map(cate=>{
                                        if(cate.id===u){
                                            return <Link to={`/danh-muc/${cate.link}/${cate.id}`} key={cate.id}>
                                                <p className='text-[20px] font-bold pb-[2px] border-b-[2px] border-[#ED0678]'>
                                                    {cate.name}
                                                </p>
                                            </Link>
                                        }
                                    })
                                }
                                {
                                    Object.keys(news_filter).map(k=>{
                                        if(parseInt(k)===u){
                                        return news_filter[k].map((ne,j)=>{
                                                return <Link to={`/chi-tiet-bai-viet/${ne.id}`} key={j}>
                                                    <div className='text-justify mt-[10px]'>
                                                    {
                                                        j===0 ? <img src={ne.images} alt='Ảnh đại diện' className={`${isMobile?'h-[200px]':'h-[150px]'} w-full object-cover`} />
                                                        : <></>
                                                    }
                                                    
                                                        <p className='text-[14px] border-t-[1px] border-[#ccc] py-[5px] hover:text-[#ED0678]'>
                                                            {ne.title.slice(0,50)}...
                                                        </p>
                                                    </div>
                                                </Link>
                                            })
                                        }
                                    })
                                }
                            </div>
                        }
                    })
                }
            </div>

            <div className={`${isMobile?'flex-wrap h-auto':'h-[270px]'} w-full flex justify-between pt-[20px] overflow-hidden`}>
                <div className={`${isMobile?'w-full ml-[10px]':'w-[49%] mt-[40px]'} h-auto overflow-y-scroll`}>
                    {
                        utils_cate.map((u,i)=>{
                            if(i===3){
                                return category.map(cate=>{
                                    if(cate.id===u){
                                        return  <Link to={`/danh-muc/${cate.link}/${cate.id}`} key={cate.id}>
                                            <p className='text-[20px] font-bold pb-[2px] border-b-[2px] border-[#ED0678]'>
                                                {cate.name}
                                            </p>
                                        </Link>
                                    }
                                })
                            }
                        })
                    }

                    <div className={`${isMobile?'flex-wrap':''} w-full flex justify-between`}>
                        <div className={`${isMobile?'flex-wrap w-full':'w-[49%]'}`}>
                            {
                                utils_cate.map((u,i)=>{
                                    if(i===3){
                                        return <div key={i}>
                                        {
                                            Object.keys(news_filter).map(k=>{
                                                if(parseInt(k)===u){
                                                return news_filter[k].map((ne,j)=>{
                                                        return <div key={j} className='text-justify mt-[10px]'>
                                                            <Link to={`/chi-tiet-bai-viet/${ne.id}`}>
                                                                {
                                                                    j===0 ? <>
                                                                        <img src={ne.images} alt='Ảnh đại diện' className={`${isMobile?'h-[200px]':'h-[100px]'} w-full object-cover`} />
                                                                        <p className='text-[14px] border-t-[1px] border-[#ccc] py-[5px] hover:text-[#ED0678]'>
                                                                            {ne.title.slice(0,50)}...
                                                                        </p>
                                                                    </>:<></>
                                                                }
                                                            </Link>
                                                        </div>
                                                    })
                                                }
                                            })
                                        }
                                        </div>
                                    }
                                })
                            }
                        </div>

                        <div className={`${isMobile?'w-full':'w-[48%]'}`}>
                            {
                                utils_cate.map((u,i)=>{
                                    if(i===3){
                                        return Object.keys(news_filter).map(k=>{
                                            if(parseInt(k)===u){
                                            return news_filter[k].map((ne,j)=>{
                                                    return <div key={j} className='text-justify mt-[10px]'>
                                                        <Link to={`/chi-tiet-bai-viet/${ne.id}`}>
                                                            {
                                                                j!==0 ? <p className='text-[14px] border-b-[1px] border-[#ccc] py-[15px] hover:text-[#ED0678]'>
                                                                    {ne.title.slice(0,50)}...
                                                                </p>:<></>
                                                            }
                                                        </Link>
                                                    </div>
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className={`${isMobile?'w-full ml-[10px] mt-[20px]':'w-[49%] mt-[40px]'} h-auto overflow-y-scroll`}>
                    {
                        utils_cate.map((u,i)=>{
                            if(i===4){
                                return category.map(cate=>{
                                    if(cate.id===u){
                                        return <Link to={`/danh-muc/${cate.link}/${cate.id}`} key={cate.id}>
                                            <p className='text-[20px] font-bold pb-[2px] border-b-[2px] border-[#ED0678]'>
                                                {cate.name}
                                            </p>
                                        </Link>
                                    }
                                })
                            }
                        })
                    }

                    <div className={`${isMobile?'flex-wrap':''} w-full flex justify-between`}>
                        <div className={`${isMobile?'flex-wrap w-full':'w-[49%]'}`}>
                            {
                                utils_cate.map((u,i)=>{
                                    if(i===4){
                                        return <div key={i}>
                                        {
                                            Object.keys(news_filter).map(k=>{
                                                if(parseInt(k)===u){
                                                return news_filter[k].map((ne,j)=>{
                                                        return <div key={j} className='text-justify mt-[10px]'>
                                                            <Link to={`/chi-tiet-bai-viet/${ne.id}`}>
                                                                {
                                                                    j===0 ? <>
                                                                        <img src={ne.images} alt='Ảnh đại diện' className={`${isMobile?'h-[200px]':'h-[100px]'} w-full object-cover`} />
                                                                        <p className='text-[14px] border-t-[1px] border-[#ccc] py-[5px] hover:text-[#ED0678]'>
                                                                            {ne.title.slice(0,50)}...
                                                                        </p>
                                                                    </>:<></>
                                                                }
                                                            </Link>
                                                        </div>
                                                    })
                                                }
                                            })
                                        }
                                        </div>
                                    }
                                })
                            }
                        </div>

                        <div className={`${isMobile?' w-full':' w-[48%]'}`}>
                            {
                                utils_cate.map((u,i)=>{
                                    if(i===4){
                                        return Object.keys(news_filter).map(k=>{
                                            if(parseInt(k)===u){
                                            return news_filter[k].map((ne,j)=>{
                                                    return <div key={j} className='text-justify mt-[10px]'>
                                                        <Link to={`/chi-tiet-bai-viet/${ne.id}`}>
                                                            {
                                                                j!==0 ? <p className='text-[14px] border-b-[1px] border-[#ccc] py-[15px] hover:text-[#ED0678]'>
                                                                    {ne.title.slice(0,50)}...
                                                                </p>:<></>
                                                            }
                                                        </Link>
                                                    </div>
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className={`${isMobile?'w-full ml-[20px] mx-auto':'w-[25%]'} h-auto pt-[20px]`}>
            {
                menu_right.map((m,index)=>{
                    return <div key={index} className='w-full h-[250px] mb-[10px] bg-[#eee] overflow-hidden overflow-y-scroll'>
                        {
                            category.map(cate=>{
                                if(cate.id===m){
                                    return <Link to={`/danh-muc/${cate.link}/${cate.id}`} key={cate.id}>
                                        <p className='bg-[#ED0678] text-white text-center font-bold py-[5px]'>
                                            {cate.name}
                                        </p>
                                    </Link>
                                }
                            })
                        }
                        <div className='aos-init' data-aos="slide-up" data-aos-duration="3000">
                            {
                                Object.keys(new_menu_right).map(k=>{
                                    if(parseInt(k)===m){
                                        return new_menu_right[k].map((new_menu,i)=>{
                                            return <Link to={`/chi-tiet-bai-viet/${new_menu.id}`} key={i}>
                                                <p className={`px-[15px] py-[10px] text-[14px] hover:text-[#ED0678] flex border-b-[1px] border-[#ccc]`}>
                                                    - {new_menu.title}
                                                </p>
                                            </Link>
                                        })
                                    }
                                })
                            }
                        </div>
                    </div>
                })
            }

            <div className='w-full h-auto mb-[10px] bg-[#eee]'>
                <p className='bg-[#ED0678] text-white text-center font-bold py-[5px]'>Thông tin liên hệ</p>
                <p className='px-[15px] py-[10px] text-[14px] border-b-[1px] border-[#ccc]'>
                    <b>Hotline:</b> {hotline}
                </p>
                <p className='px-[15px] py-[10px] text-[14px]'><b>Email:</b> {email}</p>
            </div>
        </div>
    </div>
  )
}
