import React, {useContext, useState, useEffect} from 'react'
import { GlobalState } from '../../../../GlobalState'
import Button from '../../../utils/Button'
import axios from 'axios'

export default function MediaVideo() {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [utils] = state.utilsAPI.utils
    const [categories] = state.categoryAPI.category
    const [isMobile] = state.responsive.isMobile
    const [idCate, setIdCate] = useState(0)
    const [video, setvideo] = useState(0)

    useEffect(()=>{
        const getData = ()=>{
            utils.forEach(el=>{
                if(el.id===1){
                    setIdCate(el.id)
                    setvideo(el.video)
                }
            })
        }
        getData()
    },[utils])

    const handleSubmit = async ()=>{
        try {
            const res = await axios.put(`/api/utils2/${idCate}/video`, {data: video}, {
                headers: {Authorization: token}
            })
            alert(res.data.msg)
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

  return (
    <div className='w-full h-screen bg-[#eee] p-[20px]'>
        <p className='font-bold text-center text-[20px] pb-[20px] mt-[30px]'>QUẢN LÝ MEDIA VIDEO</p>
        <div className={`${isMobile?'w-full':'w-[70%]'} h-auto mx-auto mb-[20px] text-[14px]`}>
            <label className='font-bold ml-[5px]'>Chọn danh mục Video:</label>
            <select value={video} name='' onChange={(e)=>setvideo(e.target.value)} className='w-full h-[50px] px-[15px] border-[1px] border-[#ccc] rounded-md shadow-md mt-[5px]'>
                {
                    categories.map(child=>{
                        if(child.id===parseInt(video)){
                            return <option hidden key={child.id}>{child.name}</option>
                        }
                    })
                }
                {
                    categories.map(child=>(
                        <option value={child.id} key={child.id}>{child.name}</option>
                    ))
                }
            </select>
        </div>
        <Button data={'Cập nhật'} func={handleSubmit} color={'bg-[#0099ff]'} />
    </div>
  )
}
