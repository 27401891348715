import React, {useState, useEffect} from 'react'
import axios from 'axios'

export default function FilesAPI() {
    const [files, setfiles] = useState([])
    const [callback, setCallback] = useState(false)

    useEffect(() =>{
        const getfiles = async () =>{
            const res = await axios.get('/api/file')
            setfiles(res.data)
        }
        getfiles()
    },[callback])

    return {
        files: [files, setfiles],
        callback: [callback, setCallback]
    }
}
