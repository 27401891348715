import React, {useRef, useContext, useState} from 'react'
import { GlobalState } from '../../../GlobalState';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {FaAngleRight, FaAngleLeft } from "react-icons/fa6";

export default function Section2() {
    const state = useContext(GlobalState)
    const [donors] = state.donorsAPI.donors
    const [img_slide] = state.Img_slideAPI.img_slide
    const [isMobile] = state.responsive.isMobile
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentIndex2, setCurrentIndex2] = useState(0);
    const sliderRef = useRef(null);
    const sliderRef2 = useRef(null);

    const [dataNews, setdataNews] = useState([])

    // Cấu hình slider
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => setCurrentIndex(next),
        ref: sliderRef,
        autoplay: true, // Tự động trượt
        autoplaySpeed: 3000
    }

    const nextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    }

    const prevSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    }

    const settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => setCurrentIndex2(next),
        ref: sliderRef2,
        autoplay: true, // Tự động trượt
        autoplaySpeed: 3000
    }

    const nextSlide2 = () => {
        if (sliderRef2.current) {
            sliderRef2.current.slickNext();
        }
    }

    const prevSlide2 = () => {
        if (sliderRef2.current) {
            sliderRef2.current.slickPrev();
        }
    }


  return (
    <>
        <div className={`${isMobile?'ml-[20px]':''} max-w-[1000px] h-auto mx-auto mt-[50px] relative`}>
            <Slider {...settings} className="flex w-full">
                {donors.reduce((acc, _, index) => {
                    if (index % (isMobile?1:5) === 0) {
                    acc.push(
                        <div key={index} className={`slide-group flex`}>
                            {donors.slice(index, index + (isMobile?1:5)).map((slide, subIndex) => (
                                <Link to={slide.link} target='_blank' key={subIndex}>
                                    <div className={`${isMobile?'inline':'inline-block'} slide w-[100px] h-[100px] transform transition-transform mx-[50px]`}>
                                        <img src={slide.image} alt="Ảnh đại diện" className={`${isMobile?'w-[150px] h-[150px] mx-auto':'w-full h-full'}`} />
                                    </div>
                                </Link>
                            ))}
                        </div>
                    );
                    }
                    return acc;
                }, [])}
            </Slider>
            <button className={`${isMobile?'top-[38%]':'top-[25%]'} absolute left-0 bg-[#f6f6f6] hover:bg-[#eee]`} onClick={prevSlide}><FaAngleLeft size={'30px'} color='#777'/></button>
            <button className={`${isMobile?'top-[38%]':'top-[25%]'} absolute right-0 bg-[#f6f6f6] hover:bg-[#eee]`} onClick={nextSlide}><FaAngleRight size={'30px'} color='#777'/></button>
        </div>

        <div className={`${isMobile?'ml-[20px]':''} max-w-[1000px] h-auto mx-auto mt-[30px] relative`}>
            <Slider {...settings2} className="flex w-full">
                {img_slide.reduce((acc, _, index) => {
                    if (index % (isMobile?1:4) === 0) {
                    acc.push(
                        <div key={index} className={`slide-group flex`}>
                            {img_slide.slice(index, index + (isMobile?1:4)).map((slide, subIndex) => (
                                <Link to={'/'} key={subIndex}>
                                    <div className={`${isMobile?'inline':'inline-block'} slide w-[230px] h-[150px] transform transition-transform mx-[10px]`}>
                                        <img src={slide.value} alt="Ảnh đại diện" className={`${isMobile?'w-[230px] h-[150px] object-cover mx-auto':'w-full h-full object-cover'}`} />
                                    </div>
                                </Link>
                            ))}
                        </div>
                    );
                    }
                    return acc;
                }, [])}
            </Slider>
            {/* <button className={`${isMobile?'top-[38%]':'top-[25%]'} absolute left-0 bg-[#f6f6f6] hover:bg-[#eee]`} onClick={prevSlide2}><FaAngleLeft size={'30px'} color='#777'/></button>
            <button className={`${isMobile?'top-[38%]':'top-[25%]'} absolute right-0 bg-[#f6f6f6] hover:bg-[#eee]`} onClick={nextSlide2}><FaAngleRight size={'30px'} color='#777'/></button> */}
        </div>
    </>
  )
}
