import React, {useContext, useEffect, useState} from 'react'
import { GlobalState } from '../../../GlobalState'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import ElementRight from '../../utils/ElementRight'
import { FaUser } from "react-icons/fa";

const dataDefault = {
    content: ''
}

export default function DetailNews() {
    const state = useContext(GlobalState)
    const [newsDetail, setnewsDetail] = useState([])
    const [callback, setCallback] = useState(false)
    const [category] = state.categoryAPI.category
    const [answers] = state.answersAPI.answers
    const [newsByCate, setNewsByCate] = useState([])
    const [data, setdata] = useState(dataDefault)
    const [comments, setComments] = useState([])
    const [isMobile] = state.responsive.isMobile
    const [valueVideo, setvalueVideo] = useState('')
    
    const [cateName, setCateName] = useState('')
    const [cateId, setCateId] = useState(null)
    const [id_file, setId_file] = useState(null)

    const params = useParams()

    useEffect(() =>{
        if(params.id){
            const getnewsDetail = async () =>{
                const res = await axios.get(`/api/get-news-detail-id/${params.id}`)
                setnewsDetail(res.data)
            }
            getnewsDetail()

            const getComent = async () =>{
                const res = await axios.get(`/api/comment/${params.id}`)
                setComments(res.data)
            }
            getComent()
        }
    },[params.id, callback])

    useEffect(()=>{
        const categoryName = ()=>{
            newsDetail.forEach(item=>{
                if(item.category!==null){
                    category.forEach(el=>{
                        if(item.category===el.id){
                            setCateName(el.name)
                            setCateId(el.id)
                        }
                    })
                } else {
                    answers.forEach(el=>{
                        if(item.answers===el.id){
                            setCateName(el.name)
                            setCateId(el.id)
                        }
                    })
                }

                if(item.id_file){
                    setId_file(item.id_file)
                }
            })
        }
        categoryName()
    },[newsDetail])

    useEffect(()=>{
        if(cateId){
            newsDetail.forEach(item=>{
                if(item.category!==null){
                    const getNewsCate = async ()=>{
                        const res = await axios.get(`/api/get-news-category/${cateId}/6`)
                        setNewsByCate(res.data)
                    }
                    getNewsCate()

                } else {
                    const getNewsAns = async ()=>{
                        const res = await axios.get(`/api/news-answers/${cateId}/6`)
                        setNewsByCate(res.data)
                    }
                    getNewsAns()
                }
            })
        }
    },[cateId])

    const handleChange = (e)=>{
        const {name, value} = e.target
        setdata({...data, [name]:value})
    }

    const handleSubmit = async ()=>{
        const today = new Date()
        const formattedDate = today.toISOString().split('T')[0];
        try {
            const res = await axios.post(`/api/comment`, {data, id_news: params.id, date_created: formattedDate})
            alert(res.data.msg)
            setCallback(!callback)
            setdata(dataDefault)

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const shareOnFacebook = () => {
        if (window.FB) {    // Kiểm tra xem Facebook SDK đã được tải hay chưa
            window.FB.ui({    // Chia sẻ bài viết
                method: 'share',
                href: window.location.href
            }, function(response){})
        }
    }

    useEffect(()=>{
        newsDetail.forEach(el=>{
            setvalueVideo(`${el.video.split(' ')[0]} width="360" height="250" ${el.video.split(' ').slice(3)}`)
        })
    },[newsDetail])

    const [link, setLink] = useState('')
    const handleDownload = async () => {
        try {
            const response = await axios.get(`/api/file/${id_file}`, {
                responseType: 'blob' // Đặt kiểu phản hồi là BLOB
            });
            setLink(response.data)

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1].replace(/"/g, ''));
            document.body.appendChild(link);
            // link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }

    useEffect(()=>{
        handleDownload()
    },[id_file])

    var blob = new Blob([link], {type: 'application/octet-stream'});
    const pdfBlob = new Blob([blob], { type: 'application/pdf' });
    var blobURL = URL.createObjectURL(pdfBlob);

    const handleDownloadMobile = async () => {
        try {
            const response = await axios.get(`/api/file/${id_file}`, {
                responseType: 'blob' // Đặt kiểu phản hồi là BLOB
            });
            setLink(response.data)

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1].replace(/"/g, ''));
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }

    // console.log(blobURL)

  return (
    <div className={`${isMobile?'flex-wrap mx-auto ml-[20px]':''} max-w-[1000px] h-auto mx-auto mt-[10px] flex justify-between`}>
        <div className={`${isMobile?'w-full':'w-[64%]'} h-auto`}>
            <div className='w-full h-auto flex items-center'>
                <Link to={`/`}>
                    <div className='bg-[#aaa] text-white font-bold w-[100px] h-[30px] px-[5px] border-l-[3px] border-[#ed0678] cursor-pointer'
                        >Trang chủ</div>
                </Link>
                <Link to={`/`}>
                    <div className='bg-[#aaa] w-[21px] h-[21px] rotate-[45deg] translate-x-[-50%] cursor-pointer'></div>
                </Link>
                <div className='ml-[10px] font-bold text-[20px]'>{cateName}</div>
            </div>

            <div className='w-full h-auto'>
                {
                    newsDetail.map(n=>{
                        return <p key={n.id} className='text-[#666] text-[14px] mt-[10px]'>Ngày đăng: {(n.date_created).split('-').reverse().join('/')}</p>
                    })
                }
            </div>

            <div className='w-full h-auto mt-[10px]'>
                {
                    newsDetail.map(n=>{
                        return <div key={n.id} className='w-full h-auto'>
                            <p className='font-bold text-[20px] text-[#ed0678] pb-[10px] text-justify'>{n.title}</p>
                            <p className='font-bold text-[15px] pb-[10px] text-[#666] text-justify' style={{display:id_file?'none':''}}>{n.description}</p>
                            <div dangerouslySetInnerHTML={{ __html: `${isMobile?(valueVideo.length<=26?'':valueVideo):n.video}`}} className={`${isMobile?'':''}w-full mx-auto`}></div>
                            <div dangerouslySetInnerHTML={{ __html: n.content}} style={{display:id_file?'none':''}} className='text-justify mt-[15px]'></div>
                            {
                                isMobile && id_file!==null ? 
                                <div className='w-full h-[200px] bg-gray-100 flex items-center justify-center'>
                                    <button onClick={handleDownloadMobile} className='text-white font-bold bg-pink-600 px-[15px] py-[5px] rounded-sm'>Tải về PDF</button>
                                </div>
                                :
                                <iframe src={blobURL}
                                    style={{width:'100%', height:'100vh', display:id_file?'':'none'}} >
                                </iframe>
                            }
                            <p className='font-bold text-[18px] flex justify-end mt-[15px]'>{n.author}</p>
                            <div className='flex justify-between items-center mt-[20px]'>
                                <p className='bg-[#1877F2] text-white font-bold text-[13px] px-[10px] py-[3px] rounded-sm cursor-pointer hover:bg-[#3668a9]'
                                    onClick={shareOnFacebook}>Chia sẻ</p>
                            </div>
                        </div>
                    })
                }
            </div>

            <div className='w-full h-auto mt-[20px]'>
                <div className='w-full h-auto'>
                    <p className='font-bold text-[14px] text-[#CB373B] border-b-[2px] border-[#CB373B]'>CÓ THỂ BẠN QUAN TÂM</p>
                </div>
                <div className={`${isMobile?'flex-wrap':''} w-full h-auto flex justify-between`}>
                    <div className={`${isMobile?'w-full':'w-[48%]'}`}>
                        {
                            newsByCate.map((n,i)=>{
                                if(i<=2){
                                    return <Link to={`/chi-tiet-bai-viet/${n.id}`} key={n.id}>
                                        <div className='flex w-full my-[10px]'>
                                            <img src={n.images} alt='ảnh đại diện' className={`${isMobile?'w-[23%]':'w-[100px]'} h-[60px] object-cover`} />
                                            <div className='ml-[10px]'>
                                                <p className='text-[14px] hover:text-[#ed0678]'>{n.title.slice(0,80)}....</p>
                                                <p className='text-[13px] text-[#888]'>{(n.date_created).split('-').reverse().join('/')}</p>
                                            </div>
                                        </div>
                                    </Link>
                                }
                            })
                        }
                    </div>
                    <div className={`${isMobile?'w-full':'w-[48%]'}`}>
                    {
                            newsByCate.map((n,i)=>{
                                if(i>2){
                                    return <Link to={`/chi-tiet-bai-viet/${n.id}`} key={n.id}>
                                        <div key={n.id} className='flex w-full my-[10px]'>
                                            <img src={n.images} alt='ảnh đại diện' className={`${isMobile?'w-[23%]':'w-[100px]'} h-[60px] object-cover`} />
                                            <div className='ml-[10px]'>
                                                <p className='text-[14px] hover:text-[#ed0678]'>{n.title.slice(0,80)}....</p>
                                                <p className='text-[13px] text-[#888]'>{(n.date_created).split('-').reverse().join('/')}</p>
                                            </div>
                                        </div>
                                    </Link>
                                }
                            })
                        }
                    </div>
                </div>
            </div>

            <div className='w-full h-auto mt-[20px]'>
                <p className='text-[20px] text-[#CB373B] font-bold border-b-[2px] border-[#CB373B]'>Ý kiến của bạn</p>
                <div className='mt-[10px] relative'>
                    <textarea name='content' placeholder='Nhập ý kiến của bạn...' value={data.content} onChange={handleChange}
                        className='border-[1px] border-[#ccc] w-full h-[80px] rounded-md shadow-md py-[15px] pl-[15px] pr-[60px] outline-none text-[13px]'/>
                    <p className='bg-[#ed0678] hover:bg-[#e35099] inline-block px-[8px] py-[3px] 
                        rounded-sm cursor-pointer text-white font-bold absolute top-[50%] translate-y-[-50%] right-[15px]'
                         onClick={handleSubmit}>Gửi</p>
                </div>
                <div className='w-full h-auto bg-[#eee] p-[15px] mt-[10px]'>
                    <p className='text-[#ed0678] text-[14px] font-bold pb-[5px]'>Ý kiến bạn đọc ({comments.length})</p>
                    <div className='w-full h-[200px] overflow-y-scroll'>
                        {
                            comments.map(c=>{
                                return <div key={c.id} className='w-full h-auto bg-[#fff] mb-[10px] border-[1px] border-[#ccc] rounded-sm p-[10px]'>
                                    <div className='flex items-center mb-[8px]'>
                                        <FaUser size={'25px'} className='bg-[#ccc] p-[4px] rounded-[50%]'/>
                                        <p className=''>
                                            <p className='text-[13px] text-[#666] ml-[10px]'>Đọc giả</p>
                                            <p className='text-[10px] text-[#666] ml-[10px]'>{(c.date_created).split('-').reverse().join('/')}</p>
                                        </p>
                                    </div>
                                    <p className='text-[13px]'>{c.content}</p>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>

        <div className={`${isMobile?'w-full':'w-[33%]'} h-auto`}>
            <ElementRight/>
        </div>
    </div>
  )
}
