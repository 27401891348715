import React, {useEffect, useState} from 'react'
import axios from 'axios'

export default function AccountAPI(token) {
    const [isLogged, setIsLogged] = useState(false)

    useEffect(() => {
        if(token){
            const getInfor = async ()=>{
                try {
                    const res = await axios.get('/api/infor', {
                        headers: {Authorization: token}
                    })
                    if(res) {setIsLogged(true)}

                } catch (err) {
                    alert(err.response.data.msg)
                }
            }
            getInfor()
        }
    },[token])

  return {
    isLogged: [isLogged, setIsLogged]
  }
}
