import React, {useContext, useState, useEffect} from 'react'
import { GlobalState } from '../../../../GlobalState'
import handleIncreaseQuality from '../../../utils/HandleIncrease'
import Button from '../../../utils/Button'
import axios from 'axios';
import ImageCompressor from 'image-compressor.js'

export default function Logo() {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [utils] = state.utilsAPI.utils
    const [callback, setCallback] = state.utilsAPI.callback
    const [isMobile] = state.responsive.isMobile
    const [idUtils, setIdUtils] = useState(0)

    const [noImage, setnoImage] = useState('')
    const [image_new, setImage_new] = useState(null)
    const [image, setImage] = useState('')

    useEffect(()=>{
        const getId = ()=>{
            utils.forEach(el=>{
                if(el.id===1){
                    setIdUtils(el.id)
                    setnoImage(el.logo)
                }
            })
        }
        getId()
    },[utils])

    useEffect(()=>{
        if(noImage){
            handle_increase()
        }
    },[noImage])

    const handleImage = (value) =>{
        // const file = e.target.files[0];
        const file = value
        const reader = new FileReader();
        reader.onloadend = () => {
            setImage(reader.result)
        }
        reader.readAsDataURL(file)
    }

    function CompressImage(e) {
        const file = e.target.files[0];
        
        // Kiểm tra kích thước của tệp trước khi nén
        const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB
        if (file.size > maxSizeInBytes) {
            alert('Kích thước file phải dưới 1MB.')
            return
        }

        var compressor = new ImageCompressor()
    
        compressor.compress(file, {
            quality: file.size < 50000 ? 0.8 : file.size >= 50000 && file.size < 400000 ? 0.4 : file.size >= 400000 && file.size < 800000 ? 0.3 : file.size >= 800000 ? 0.2: null,
            // maxWidth: 100, 
            // maxHeight: 100,
            mimeType: 'image/jpeg',
            success(result) {
                handleImage(result);
            },
            error(e) {
                console.log(e.message);
            },
        })
    }

    const handle_increase = async ()=>{
        try {
            const newImage = await handleIncreaseQuality(noImage)
            setImage_new(newImage)
            
        } catch (err) {
            console.error('Error:', err)
        }
    }

    const handlesubmit = async () => {
        try {
            const res = await axios.put(`/api/utils2/${idUtils}/logo`,{data: image}, {
                headers: {Authorization: token}
            })
            alert(res.data.msg)
            setCallback(!callback)
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

  return (
    <div>
        <div className={`${isMobile?'w-full':'w-[80%]'} h-auto bg-[#f6f6f6] mx-auto p-[20px] mt-[50px] rounded-sm`}>
            <p className='text-center font-bold text-[25px] pb-[20px] text-[#555]'>LOGO</p>
            <div className={`${isMobile?'flex-wrap':''} flex justify-around items-center`}>
                <div>
                    <p className='text-[15px] pb-[10px] font-bold'>Chọn ảnh:</p>
                    <input type="file" name="file" id="file_up" onChange={CompressImage} onClick={e => (e.target.value = null)}
                        className='h-[40px]' />
                </div>

                <div className={`${isMobile?'mt-[20px]':''} w-[200px] h-[200px] bg-slate-200`}>
                    <img src={image?image:image_new} alt="Ảnh đại diện" style={{ maxWidth: '300px' }}
                        className='w-full h-full object-cover bg-white p-0' />
                </div>
            </div>

            <Button data={'Cập nhật'} func={handlesubmit} color={'bg-[#3399FF]'} />
        </div>
    </div>
  )
}
