import React, {useState, useEffect, useContext} from 'react'
import axios from 'axios'

export default function NewsMenuRight() {
    const [menu_right, setMenu_right] = useState([])
    const [callback, setCallback] = useState(false)

    useEffect(() =>{
        const getMenu_right = async () =>{
            const res = await axios.get(`/api/news-menu-right`)
            setMenu_right(res.data)
        }
        getMenu_right()
    },[callback])

    return {
        menu_right: [menu_right, setMenu_right],
        callback: [callback, setCallback]
    }
}
