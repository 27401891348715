import React, {useEffect, useState} from 'react'
import axios from 'axios'

export default function ClusterAPI() {
    const [cluster, setCluster] = useState([])
    const [callback, setCallback] = useState(false)

    useEffect(() => {
        const getCluster = async ()=>{
            const res = await axios.get('/api/cluster')
            setCluster(res.data)
        }
        getCluster()
    },[callback])

  return {
    cluster: [cluster, setCluster],
    callback: [callback, setCallback]
  }
}
