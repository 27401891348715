import React, {useState, useEffect, useContext} from 'react'
import axios from 'axios'

export default function NewsFilterCate() {
    const [News_Filter, setNews_Filter] = useState([])
    const [callback, setCallback] = useState(false)

    useEffect(() =>{
        const getNews_Filter = async () =>{
            const res = await axios.get(`/api/filter-category-news`)
            setNews_Filter(res.data)
        }
        getNews_Filter()
    },[callback])

    return {
        news_filter: [News_Filter, setNews_Filter],
        callback: [callback, setCallback]
    }
}
