import React, {useState, useContext, useEffect} from 'react'
import { GlobalState } from '../../../GlobalState'
import Button from '../../utils/Button'
import axios from 'axios'
import { LuMenuSquare } from "react-icons/lu";

const CSS_input = {
    in: 'w-full h-[50px] block border-[1px] border-[#ccc] shadow-md rounded-md px-[15px] text-[14px]',
    label: 'inline-block mt-[20px] font-bold ml-[5px]'
}

const dataDefault = {
    hotline: '',
    email: '',
    address: '',
    manage: '',
    chairperson: ''
}

export default function InforContact({changeState}) {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [utils] = state.utilsAPI.utils
    const [id, setid] = useState(0)
    const [isMobile] = state.responsive.isMobile

    const [hotline, setHotline] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [manage, setManage] = useState('')
    const [chairperson, setchairperson] = useState('')

    useEffect(()=>{
        const getUtils = ()=>{
            utils.forEach(el=>{
                setHotline(el.hotline)
                setEmail(el.email)
                setAddress(el.address)
                setManage(el.manage)
                setchairperson(el.chairperson)
                setid(el.id)
            })
        }
        getUtils()
    },[utils])

    const handleSubmit = async ()=>{
        try {
            const res = await axios.put(`/api/utils1/${id}`, {hotline: hotline, email: email, address: address, manage: manage, chairperson: chairperson}, {
                headers: {Authorization: token}
            })
            alert(res.data.msg)
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleClick = ()=>{
        changeState(true)
    }

    // console.log(hotline)

  return (
    <div className='w-full h-auto'>
        <LuMenuSquare size={'35px'} color='#777' className={`${isMobile?'':'hidden'} absolute top-[10px] left-[10px]`}
          onClick={handleClick}/>
        <div className={`${isMobile?'w-full mt-[50px]':'w-[70%]'} h-auto bg-[#eee] p-[20px] mx-auto my-[40px]`}>
            <p className='text-center font-bold text-[#333] text-[20px]'>QUẢN LÝ THÔNG TIN LIÊN HỆ</p>

            <label className={CSS_input.label}>Chủ tịch hội:</label>
            <input type='text' name='chairperson' value={chairperson} onChange={(e)=>setchairperson(e.target.value)}className={CSS_input.in} />
            
            <label className={CSS_input.label}>Văn phòng:</label>
            <input type='text' name='manage' value={manage} onChange={(e)=>setManage(e.target.value)} className={CSS_input.in}/>
            
            <label className={CSS_input.label}>Hotline:</label>
            <input type='text' name='hotline' value={hotline} onChange={(e)=>setHotline(e.target.value)} className={CSS_input.in}/>
            
            <label className={CSS_input.label}>Email:</label>
            <input type='text' name='email' value={email} onChange={(e)=>setEmail(e.target.value)} className={CSS_input.in}/>
            
            <label className={CSS_input.label}>Địa chỉ:</label>
            <input type='text' name='address' value={address} onChange={(e)=>setAddress(e.target.value)} className={CSS_input.in}/>
            
            
            <Button data={'Cập nhật'} func={handleSubmit} color={'bg-[#0099ff]'} />
        </div>
    </div>
  )
}
