import React, {useState, useContext} from 'react'
import { GlobalState } from '../../../GlobalState'
import ElementRight from '../../utils/ElementRight'
import Button from '../../utils/Button'
import axios from 'axios'

const dataDefault = {
    name: '',
    email: '',
    phone: '',
    title: '',
    content: ''
}

const CSS_design = {
    input: 'border-[1px] border-[#ccc] w-[60%] h-[40px] px-[15px] mt-[5px] rounded-md shadow-md',
    area: 'border-[1px] border-[#ccc] w-full h-[200px] p-[15px] mt-[5px] rounded-md shadow-md',
    p: 'ml-[5px], text-[14px]'
}

export default function Contact() {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [utils] = state.utilsAPI.utils
    const [data, setData] = useState(dataDefault)
    const [isMobile] = state.responsive.isMobile

    const handlChange = (e)=>{
        const {name, value} = e.target
        setData({...data, [name]:value})
    }

    const handleSubmit = async ()=>{
        const today = new Date()
        const date = today.toISOString().split('T')[0];
        const hours = today.getHours().toString().padStart(2, '0');
        const minutes = today.getMinutes().toString().padStart(2, '0');
        const formattedDateTime = `${date} ${hours}:${minutes}`;
        try {
            const res = await axios.post(`/api/message`, {data, date_created: formattedDateTime}, {
                headers: {Authorization: token}
            })
            alert(res.data.msg)
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

  return (
    <div className={`${isMobile?'flex-wrap ml-[20px]':''} max-w-[1000px] h-auto mx-auto flex justify-between pt-[20px]`}>
        <div className={`${isMobile?'w-full':'w-[64%]'} h-auto`}>
            <p className='text-[20px] font-bold pb-[2px] border-b-[2px] border-[#ED0678] mb-[15px]'>Liên hệ</p>
            <div className='w-full h-auto mt-[10px]'>
                <p className={CSS_design.p}>Họ và tên:</p>
                <input type='text' name='name' value={data.name} onChange={handlChange} placeholder='...'
                    className={CSS_design.input} />
            </div>
            <div className='w-full h-auto mt-[10px]'>
                <p className={CSS_design.p}>Email:</p>
                <input type='text' name='email' value={data.email} onChange={handlChange} placeholder='...'
                    className={CSS_design.input} />
            </div>
            <div className='w-full h-auto mt-[10px]'>
                <p className={CSS_design.p}>Điện thoại:</p>
                <input type='text' name='phone' value={data.phone} onChange={handlChange} placeholder='...'
                    className={CSS_design.input} />
            </div>
            <div className='w-full h-auto mt-[10px]'>
                <p className={CSS_design.p}>Tiêu đề:</p>
                <input type='text' name='title' value={data.title} onChange={handlChange} placeholder='...'
                    className={CSS_design.input} />
            </div>
            <div className='w-full h-auto mt-[10px]'>
                <p className={CSS_design.p}>Nội dung:</p>
                <textarea type='text' name='content' value={data.content} onChange={handlChange} placeholder='Nhập nội dung...'
                    className={CSS_design.area} />
            </div>

            <button onClick={handleSubmit} className='px-[10px] py-[5px] bg-[#0099ff] hover:bg-[#4796ca] text-white font-bold rounded-sm shadow-sm mt-[10px]'>Gửi</button>

            {
                utils.map(u=>(
                    <div className='w-full h-auto mt-[20px]' key={u.id}>
                        <p className='py-[5px]'><b>Văn phòng:</b>&nbsp; {u.manage}</p>
                        <p className='py-[5px]'><b>Địa chỉ:</b>&nbsp; {u.address}</p>
                        <p className='py-[5px]'><b>Điện thoại:</b>&nbsp; {u.hotline}</p>
                        <p className='py-[5px]'><b>Email:</b>&nbsp; {u.email}</p>
                    </div>
                ))
            }
        </div>

        <div className={`${isMobile?'w-full':'w-[34%]'} h-auto`}>
            <ElementRight/>
        </div>
    </div>
  )
}
