import React, {useContext, useState, useEffect} from 'react'
import { GlobalState } from '../../../GlobalState'
// import Button from '../../utils/Button'
import axios from 'axios'
import Loading from '../../utils/Loading'
import { LuMenuSquare } from "react-icons/lu";

export default function Message({changeState}) {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [isMobile] = state.responsive.isMobile
    const [messages, setMessages] = useState([])
    const [callback, setCallback] = useState(false)
    // const [limit, setLimit] = useState(10)
    const [loading, setLoading] = useState(false)
    const [messDetail, setMessDetail] = useState(0)
    const [count, setCount] = useState([])

    useEffect(()=>{
        const getData = async ()=>{
            try {
                const res = await axios.get(`/api/message-all`, {
                    headers: {Authorization: token}
                })
                setMessages(res.data)
                setLoading(false)
                
            } catch (err) {
                alert(err.response.data.msg)
            }
        }
        getData()
    },[callback, messDetail])

    useEffect(()=>{
        let arr = []
        messages.forEach(el=>{
            if(el.note===0){
                arr.push(el)
            }
        })
        setCount(arr)
    },[messages])

    // const showMore = ()=>{
    //     setLoading(true)
    //     setLimit(limit + 5)
    // }

    const deleteMessage = async (id)=>{
        if(window.confirm('Lưu ý: Dữ liệu bị xóa sẽ không thể phục hồi.')){
            try {
                const res = await axios.delete(`/api/message/${id}`, {
                    headers: {Authorization: token}
                })
                alert(res.data.msg)
                setCallback(!callback)
                
            } catch (err) {
                alert(err.response.data.msg)
            }
        }
    }

    const deleteAll = async () =>{
        if(window.confirm('Lưu ý: Dữ liệu bị xóa sẽ không thể phục hồi.')){
            try {
                const res = await axios.delete(`/api/message`, {
                    headers: {Authorization: token}
                })
                alert(res.data.msg)
                setCallback(!callback)
                
            } catch (err) {
                alert(err.response.data.msg)
            }
        }
    }

    const showDetail = async (id) =>{
        setMessDetail(id)
        try {
            await axios.put(`/api/message/${id}`,{value:1},{
                headers: {Authorization: token}
            })
            setCallback(!callback)
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleClick = ()=>{
        changeState(true)
    }

  return (
    <div className={`${isMobile?'py-[30px]':'p-[30px]'} w-full h-screen relative bg-[#f6f6f6]`}>
        <LuMenuSquare size={'35px'} color='#777' className={`${isMobile?'top-[25px]':'hidden'} absolute top-[10px] left-[10px]`}
          onClick={handleClick}/>
        <p className={`${isMobile?'ml-[30px]':''} font-bold text-[20px] text-center text-[#333] mb-[20px]`}>QUẢN LÝ HÒM THƯ ĐẾN</p>
        {
            messages.length===0 ? <></> :
            <div className='flex items-center'>
                <button onClick={deleteAll} className='bg-[#990000] hover:bg-[#9d3838] text-[13px] text-white font-bold mt-[10px] px-[10px] py-[5px] rounded-sm shadow-sm'>Xóa tất cả</button>
                <p className='ml-[15px] text-[14px] italic font-bold'>Có {count.length} tin chưa đọc.</p>
            </div>
        }
        <div className={`${isMobile?'':'flex justify-between'} mt-[10px]`}>
            <div className={`${isMobile?'w-full h-[300px]':'w-[39%] h-[420px]'} overflow-y-scroll pr-[10px]`}>
                {
                    messages.length===0 ? <p className='text-center'>Không có thư đến!</p> :
                    messages.map(m=>(
                        <div key={m.id} onClick={()=>showDetail(m.id)}
                            className={`${m.note===0?'bg-[#cae6fe] animate-pulse':'bg-white'} w-full h-auto cursor-pointer flex items-center justify-between px-[15px] py-[10px] mt-[10px] border-[1px] border-[#bbb] rounded-md shadow-md`}>
                            <div>
                                <p className='text-[16px] font-bold'>{m.name}</p>
                                <p className='text-[13px] text-gray-500'>{(m.date_created).split(' ')[0].split('-').reverse().join('/') + ' ' + (m.date_created).split(' ')[1]}</p>
                                <p className='text-[13px] text-gray-500'> {m.content.slice(0,30) + '...'} </p>
                            </div>
                            <button onClick={()=>deleteMessage(m.id)} className='bg-red-600 hover:bg-red-400 px-[10px] py-[5px] rounded-sm shadow-md font-bold text-[14px] text-white'>Xóa</button>
                        </div>
                    ))
                }
            </div>

            {
                messages.map(m=>{
                    if(messDetail===m.id){
                        return <div key={m.id} className={`${isMobile?'w-full h-auto mt-[10px]':'w-[60%] h-[420px]'} overflow-y-scroll border-[1px] border-[#bbb] p-[10px] rounded-sm bg-white`}>
                            <p className='text-[17px] font-bold'>{m.title}</p>
                            <p className='text-[13px]'><b>Thời gian gửi:</b> {(m.date_created).split(' ')[0].split('-').reverse().join('/') + ' ' + (m.date_created).split(' ')[1]}</p><br/>
                            <p className='text-[14px]'><b>Tên:</b> {m.name}</p>
                            <p className='text-[14px]'><b>Điện thoại:</b> {m.phone}</p>
                            <p className='text-[14px]'><b>Email:</b> {m.email}</p><br/>
                            <p className='text-[14px] text-gray-500 text-justify'> {m.content}</p>
                        </div>
                    }
                })
            }
        </div>

        {/* <div className='w-full h-auto pb-[30px]'>
            {
                messages.length===0 ? <></> :
                <Button data={'Xem thêm'} func={showMore} color={'bg-[#0099ff]'} />
            }
        </div> */}
        {
            loading ? <Loading/> : <></>
        }
    </div>
  )
}
