import React, {useState, useEffect} from 'react'
import axios from 'axios'

export default function AnswersAPI() {
    const [answers, setanswers] = useState([])
    const [callback, setCallback] = useState(false)

    useEffect(() =>{
        const getanswers = async () =>{
            const res = await axios.get('/api/answers')
            setanswers(res.data)
        }
        getanswers()
    },[callback])

    return {
        answers: [answers, setanswers],
        callback: [callback, setCallback]
    }
}
