import React, {useContext, useEffect, useState} from 'react'
import { GlobalState } from '../../../../GlobalState'
import QRCode from 'qrcode.react'

export default function Section4() {
    const state = useContext(GlobalState)
    const [qr_code] = state.qr_codeAPI.qr_code

  return (
    <>
        <p className='text-center font-bold text-[20px] mt-[25px] text-[#444]'>QUÉT MÃ QR TRUY CẬP CÁC TRANG</p>
        <div className='w-full h-auto flex flex-wrap justify-center mt-[30px]'>
            {
                qr_code.map(qr=>{
                    return <div key={qr.id} className='w-[200px] px-[20px] '>
                        {
                            qr.check_image===2 ?
                            qr.image && <QRCode value={qr.image} size={77} className='mx-auto' />
                            :
                            <img src={qr.image} alt='QR_CODE' className='w-[80px] h-[80px] mx-auto rounded-md'/>
                        }
                        <img src={qr.img_avt} alt='avt' className='w-full h-[45px] object-cover mt-[10px]' />
                        <p className='text-[13px] text-[#444] text-center pt-[10px]'>{qr.title}</p>
                        <p className='text-[12.5px] text-[#444] text-center pb-[20px]'>{qr.title2}</p>
                    </div>
                })
            }
        </div>
    </>
  )
}
