import React, {useState, useEffect} from 'react'
import axios from 'axios'

export default function CategoryHomePage() {
    const [category, setcategory] = useState([])
    const [callback, setCallback] = useState(false)

    useEffect(() =>{
        const getcategory = async () =>{
            const res = await axios.get('/api/category-home-page')
            setcategory(res.data)
        }
        getcategory()
    },[callback])

    return {
        category: [category, setcategory],
        callback: [callback, setCallback]
    }
}
