import React, {useState, useContext, useEffect} from 'react'
import { GlobalState } from '../../../GlobalState'
import ListMenu from './ListMenu'
import UpdateMenu from './UpdateMenu'
import Button from '../../utils/Button'
import axios from 'axios'
import Answers from '../answers/Answers'
import { LuMenuSquare } from "react-icons/lu";

const dataDefault = {
    name: '',
    link: '',
    category: 0
}

export default function Menu({changeState}) {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [category] = state.categoryAPI.category
    const [answers] = state.answersAPI.answers
    const [callback, setCallback] = state.menuAPI.callback
    const [data, setData] = useState(dataDefault)
    const [isMobile] = state.responsive.isMobile

    const [linkCate, setLinkCate] = useState('')
    const [checkUpdate, setCheckUpdate] = useState(false)
    const [id, setId] = useState(null)
    const [idCate, setIdCate] = useState(null)
    const [valueLink, setvalueLink] = useState(0)
    const [linkHand, setLinkHand] = useState('')
    const [linkHandNews, setLinkHandNews] = useState('chi-tiet-bai-viet/<id>')

    const handleChange = (e)=>{
        const {name, value} = e.target
        setData({...data, [name]:value})
    }

    useEffect(()=>{
        const getLinkCate = ()=>{
            category.forEach(el=>{
                if(el.id===parseInt(data.category)){
                    setLinkCate(el.link)
                    setIdCate(el.id)
                }
            })
        }
        getLinkCate()
    },[data.category])

    const handleSubmit = async ()=>{
        try {
            if(valueLink==='1'){
                const res = await axios.post(`/api/menu`, {data, link: linkCate, category:''}, {
                    headers: {Authorization: token}
                })
                updateCheckmenu()
                alert(res.data.msg)
                setCallback(!callback)
            }
            else if(valueLink==='2') {
                const res = await axios.post(`/api/menu`, {data, link: linkHand, category: 1000000000}, {
                    headers: {Authorization: token}
                })
                alert(res.data.msg)
                setCallback(!callback)
            }
            else if(valueLink==='3') {
                const res = await axios.post(`/api/menu`, {data, link: linkHandNews, category:''}, {
                    headers: {Authorization: token}
                })
                alert(res.data.msg)
                setCallback(!callback)
            }

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const updateCheckmenu = async ()=>{
        try {
            await axios.put(`/api/category-menu/${idCate}`,{value: 1},{
                headers: {Authorization: token}
            })
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const checkChooseLink = (value)=>{
        setvalueLink(value)
    }

    const clickChooseLink = ()=>{
        setLinkCate('')
        setLinkHand('')
        setLinkHandNews('chi-tiet-bai-viet/<id>')
        setData(dataDefault)
    }

    const handleClick = ()=>{
        changeState(true)
    }

    // console.log(valueLink)

    return (
        <>
            <div className='w-full h-auto py-[30px] relative'>
                <div className={`${isMobile?'w-full mt-[20px]':'w-[60%]'} h-auto bg-[#eee] p-[20px] mx-auto`}>
                    <LuMenuSquare size={'35px'} color='#777' className={`${isMobile?'':'hidden'} absolute top-[10px] left-[10px]`}
                        onClick={handleClick}/>
                    <p className='text-center font-bold text-[20px] text-[#333] mb-[10px]'>QUẢN LÝ MENU</p>
                    
                    <div className='w-full h-auto'>
                        <select value={valueLink} onChange={(e)=>checkChooseLink(e.target.value)} onClick={clickChooseLink}
                            className='w-full h-[50px] border-[1px] border-[#ccc] rounded-md shadow-md mt-[20px] px-[14px]'>
                            <option hidden>Chọn liên kết</option>
                            <option value={1}>Liên kết với thể loại</option>
                            <option value={2}>Liên kết trang</option>
                            <option value={3}>Liên kết bài viết</option>
                        </select>

                        {
                            valueLink === '1' ?
                            <select name='category' value={data.category} onChange={handleChange} 
                                className='w-full h-[50px] border-[1px] border-[#ccc] rounded-md shadow-md mt-[20px] px-[14px]'>
                                <option hidden>Chọn thể loại</option>
                                {
                                    category.map(c=>{
                                        return <option value={c.id} key={c.id}>{c.name}</option>
                                    })
                                }
                            </select>
                            :
                            valueLink === '2' ?
                            <input type='text' name='link' placeholder='Nhập Link liên kết trang...' value={linkHand} onChange={(e)=>setLinkHand(e.target.value)}
                                className='w-full h-[50px] border-[1px] border-[#ccc] rounded-md shadow-md mt-[20px] px-[14px]' />
                            :
                            valueLink === '3' ?
                            <>
                                <p className='text-[14px] text-lime-600 font-bold mb-[-20px] mt-[20px] ml-[5px]'>Lưu ý: Chỉ thay đổi "{`<id>`}" thành mã bài viết bạn muốn liên kết. </p>
                                <input type='text' name='link' placeholder='Nhập Link' value={linkHandNews} onChange={(e)=>setLinkHandNews(e.target.value)}
                                    className='w-full h-[50px] border-[1px] border-[#ccc] rounded-md shadow-md mt-[20px] px-[14px]' />
                            </>
                            : <></>
                        }
                    </div>

                    <input type='text' name='name' placeholder='Nhập tên menu...' value={data.name} onChange={handleChange}
                        className='block w-full h-[50px] border-[1px] border-[#ccc] rounded-md shadow-md px-[15px] mt-[20px]' />

                    <Button data={'Thêm'} func={handleSubmit} color={'bg-[#0099FF]'} />
                </div>

                <ListMenu func={setCheckUpdate} setId={setId} />
            </div>
            {
                checkUpdate ? <UpdateMenu func={setCheckUpdate} id={id} checkUpdate={checkUpdate} /> : <></>
            }
        </>
    )
}
