import React,{useEffect, useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {DataProvider} from './GlobalState';
import MainPage from './component/Page';
import Header from './component/header/Header';
import Footer from './component/footer/Footer';
import Background from './component/background/Background';
import Logo_head from './component/utils/Logo_head';

function App() {
  const [ref, setHref] = useState('')

    useEffect(()=>{
        const handleHref = ()=>{
            const link = (window.location.href).split('/')
            setHref(link[link.length-1])
        }
        handleHref()
    },[])

  return (
    <DataProvider>
      <Router>
        <div className="w-full">
          <Logo_head/>
          <Header data={ref}/>
          <Background data={ref}/>
          <MainPage />
          <Footer data={ref}/>
        </div>
      </Router>
    </DataProvider>
  );
}

export default App;
