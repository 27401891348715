import React, {useContext, useState, useEffect} from 'react'
import { GlobalState } from '../../../GlobalState'
import Button from '../../utils/Button'
import axios from 'axios'
import img from '../../../img/no-image.jpg'
import { LuMenuSquare } from "react-icons/lu";

import handleIncreaseQuality from '../../utils/HandleIncrease';
import ImageCompressor from 'image-compressor.js'

import QRCode from 'qrcode.react';


const CSS_Input = {
    item: 'w-full h-[50px] border-[1px] border-[#ccc] rounded-md px-[20px] mt-[20px] shadow-md',
    table: 'border-[1px] border-[#bbb] py-[15px] px-[8px] text-center text-[14px]'
}

export default function QR_code({changeState}) {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [qr_code] = state.qr_codeAPI.qr_code
    const [callback, setCallback] = state.qr_codeAPI.callback
    const [isMobile] = state.responsive.isMobile

    const [check, setCheck] = useState(false)
    const [check2, setCheck2] = useState(false)
    const [id, setId] = useState(0)
    const [name, setName] = useState('')
    const [nameUpdate, setNameUpdate] = useState('')
    const [name2, setName2] = useState('')
    const [nameUpdate2, setNameUpdate2] = useState('')

    const [noImage, setnoImage] = useState('')
    const [image_new, setImage_new] = useState(null)
    const [image, setImage] = useState('')

    const [img_avt, setImg_avt] = useState('')
    const [img_avt_update, setImg_avt_update] = useState('')

    const [valueChooseQR, setvalueChooseQR] = useState(0)
    const [text, setText] = useState('');

    const handleSubmit = async () =>{
        try {
            const res = await axios.post(`/api/qr_code`, {title: name, image: parseInt(valueChooseQR)===1 ? image : text, check_image: parseInt(valueChooseQR), img_avt: img_avt, title2: name2 },{
                headers: {Authorization: token}
            })
            alert(res.data.msg)
            setCallback(!callback)
            setImg_avt('')
            setImg_avt_update('')
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleUpdate = async () =>{
        try {
            const res = await axios.put(`/api/qr_code/${id}`, {title: nameUpdate, image: parseInt(valueChooseQR)===1 ? (image?image:noImage) : text, check_image: parseInt(valueChooseQR), img_avt: img_avt, title2: nameUpdate2},{
                headers: {Authorization: token}
            })
            alert(res.data.msg)
            setCallback(!callback)
            setImg_avt('')
            setImg_avt_update('')
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    useEffect(()=>{
        const gedata = ()=>{
            qr_code.forEach(el=>{
                if(el.id===id){
                    setNameUpdate(el.title)
                    setNameUpdate2(el.title2)
                    setnoImage(el.image)
                    setImg_avt_update(el.img_avt)
                }
            })
        }
        gedata()
    },[id])

    const checkUpdate = (id) =>{
        setId(id)
        setCheck(true)
        setCheck2(true)
        setName('')
        setName2('')
        setImage('')
        setImage_new(null)
        setnoImage('')
        scroll()
        setvalueChooseQR(0)
        setText('')
    }

    const handleDelete = async (id) =>{
        if(window.confirm('Lưu ý: Dữ liệu bị xóa sẽ không thể phục hồi.')){
            try {
                const res = await axios.delete(`/api/qr_code/${id}`,{
                    headers: {Authorization: token}
                })
                alert(res.data.msg)
                setCallback(!callback)
                
            } catch (err) {
                alert(err.response.data.msg)
            }
        }
    }

    const goBack = ()=>{
        setCheck(false)
        setCheck2(false)
        setImage_new(null)
        setImage('')
        setnoImage('')
        setNameUpdate('')
        setNameUpdate2('')
        setId(0)
        setvalueChooseQR(0)
        setText('')
        setImg_avt('')
        setImg_avt_update('')
    }

    useEffect(()=>{
        if(noImage){
            handle_increase()
        }
    },[noImage])

    const handleImgAvt = (e) =>{
        const file = e.target.files[0];
        // const file = value
        const reader = new FileReader();
        reader.onloadend = () => {
            setImg_avt(reader.result)
        }
        reader.readAsDataURL(file)
    }

    const handleImage = (value) =>{
        // const file = e.target.files[0];
        const file = value
        const reader = new FileReader();
        reader.onloadend = () => {
            setImage(reader.result)
        }
        reader.readAsDataURL(file)
    }

    function CompressImage(e) {
        const file = e.target.files[0];
        
        // Kiểm tra kích thước của tệp trước khi nén
        const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB
        if (file.size > maxSizeInBytes) {
            alert('Kích thước file phải dưới 1MB.')
            return
        }

        var compressor = new ImageCompressor()
    
        compressor.compress(file, {
            quality: file.size < 50000 ? 0.6 : file.size >= 50000 && file.size < 400000 ? 0.4 : file.size >= 400000 && file.size < 800000 ? 0.3 : file.size >= 800000 ? 0.2: null,
            // maxWidth: 100, 
            // maxHeight: 100,
            mimeType: 'image/jpeg',
            success(result) {
                handleImage(result);
            },
            error(e) {
                console.log(e.message);
            },
        })
    }

    const handle_increase = async ()=>{
        try {
            const newImage = await handleIncreaseQuality(noImage)
            setImage_new(newImage)
            
        } catch (err) {
            console.error('Error:', err)
        }
    }

    const handleClick = ()=>{
        changeState(true)
    }

    const scroll = () =>{
        function topFunction() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
        topFunction()
    }

    // xử lý tạo mã qr code
    const handleChangeQR_code = (e) => {
        setText(e.target.value)
    }

    const resetValueQR_code = ()=>{
        setImage('')
        setName('')
        setName2('')
        setText('')
    }

  return (
    <div className={`${isMobile?'py-[30px]':'p-[30px]'} w-full h-auto`}>
        <LuMenuSquare size={'35px'} color='#777' className={`${isMobile?'top-[25px]':'hidden'} absolute top-[10px] left-[10px]`}
          onClick={handleClick}/>
        <p className={`${isMobile?'ml-[30px]':''} font-bold text-[20px] text-center text-[#333]`}>QUẢN LÝ QR CODE LIÊN KẾT MẠNG XÃ HỘI</p>

        <div className={`${isMobile?'w-full':'w-[60%]'} h-auto bg-[#eee] mx-auto mt-[20px] p-[30px]`}>
            <div className='flex'>
                <div>
                    <p className='text-[15px] pb-[10px] font-bold'>Chọn ảnh đại diện:</p>
                    <input type="file" name="file_avt" id="file_up_avt" onChange={handleImgAvt} onClick={e => (e.target.value = null)}
                        className='h-[40px]' />
                </div>

                <div className='w-full h-[60px]'>
                    <img src={img_avt_update?img_avt_update:img_avt} alt="Ảnh đại diện" style={{ maxWidth: '300px' }}
                        className='w-full h-full object-cover' />
                </div>
            </div>

            <input type='text' name='name' value={check?nameUpdate:name} placeholder='Nhập tiêu đề 1...' 
                onChange={check?(e)=>setNameUpdate(e.target.value):(e)=>setName(e.target.value)} className='w-full h-[40px] my-[20px] border-[1px] border-[#ccc] px-[15px] rounded-md shadow-md mx-auto'/>
            
            <input type='text' name='name2' value={check2?nameUpdate2:name2} placeholder='Nhập tiêu đề 2...' 
                onChange={check2?(e)=>setNameUpdate2(e.target.value):(e)=>setName2(e.target.value)} className='w-full h-[40px] mb-[20px] border-[1px] border-[#ccc] px-[15px] rounded-md shadow-md mx-auto'/>
            
            <select value={valueChooseQR} onChange={(e)=>setvalueChooseQR(e.target.value)} onClick={resetValueQR_code}
                className='w-full h-[40px] bg-white border-[1px] border-[#ccc] rounded-sm shadow-md px-[15px] mb-[20px] text-[14px] cursor-pointer'>
                <option hidden>{check ? 'Chọn hình thức cập nhật' : 'Chọn hình thức thêm mới'}</option>
                <option value={1}>Hình ảnh có sẵn</option>
                <option value={2}>Tạo mới mã QR</option>
            </select>

            <div className={`${isMobile?'flex-wrap':''} ${parseInt(valueChooseQR)===1?'':'hidden'} flex justify-around items-center`}>
                <div>
                    <p className='text-[15px] pb-[10px] font-bold'>Chọn ảnh:</p>
                    <input type="file" name="file" id="file_up" onChange={CompressImage} onClick={e => (e.target.value = null)}
                        className='h-[40px]' />
                </div>

                <div className='w-[200px] h-[200px] bg-slate-200'>
                    <img src={image?image:image_new?image_new:img} alt="Ảnh đại diện" style={{ maxWidth: '300px' }}
                        className='w-full h-full object-cover' />
                </div>
            </div>

            <div className={`${parseInt(valueChooseQR)===2?'':'hidden'}`}>
                <input type="text" value={text} onChange={handleChangeQR_code} placeholder='Nhập dữ liệu cần tạo mã'
                    className='w-full h-[40px] border-[1px] border-[#ccc] px-[15px] rounded-md shadow-md mx-auto' />
                <p className='text-[14px] mt-[10px] text-lime-800'><b>Lưu ý:</b> Nhập dữ liệu là <b>link website</b> hoặc dữ liệu khác <b>viết liền không dấu</b>. </p>
            </div>
            
            <div className='flex justify-center mt-[20px]'>
                {text && <QRCode value={text} />}
            </div>

            <Button data={check?'Cập nhật':'Thêm'} func={check?handleUpdate:handleSubmit} color={'bg-[#0099ff]'} />
            {check ? <Button data={'Hủy'} func={goBack} color={'bg-[#0099ff]'} /> : <></>}
        </div>

        <div className={`${isMobile?'overflow-x-scroll':'w-full'}`}>
            <table className='m-auto w-full mt-[20px]'>
                <thead>
                    <tr>
                        <th className={CSS_Input.table}>STT</th>
                        <th className={CSS_Input.table}>Tiêu đề 1</th>
                        <th className={CSS_Input.table}>Tiêu đề 2</th>
                        <th className={CSS_Input.table}>Mã QR</th>
                        <th className={CSS_Input.table}>Hình đại diện</th>
                        <th className={CSS_Input.table}>Tùy chỉnh</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        qr_code.map((c,i)=>(
                            <tr key={c.id}>
                                <td className={CSS_Input.table}>{i+1}</td>
                                <td className={CSS_Input.table}>{c.title}</td>
                                <td className={CSS_Input.table}>{c.title2}</td>
                                <td className={CSS_Input.table}>
                                    {
                                        c.check_image===2 ?
                                        c.image && <QRCode value={c.image} size={45} className='mx-auto' />
                                        :
                                        <img src={c.image} alt='QR CODE' className={`${isMobile?'h-auto':'h-[50px]'} w-[50px] object-cover mx-auto`} />
                                    }
                                </td>
                                <td className={CSS_Input.table}>
                                    <img src={c.img_avt} className='w-[100px] m-auto' />
                                </td>
                                <td className={CSS_Input.table}>
                                    <div className='w-[100px] flex justify-around mx-auto'>
                                        <button onClick={()=>checkUpdate(c.id)} className='bg-[#FFCC33] hover:bg-[#fde18d] py-[3px] px-[10px] rounded-md'>Sửa</button>
                                        <button onClick={()=>handleDelete(c.id)} className='bg-[#CC3300] hover:bg-[#fe8259] text-[#fff] py-[3px] px-[10px] rounded-md'>Xóa</button>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    </div>
  )
}
