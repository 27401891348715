import React, {useState, useEffect} from 'react'
import axios from 'axios'

export default function Category_childAPI() {
    const [category_child1, setcategory_child1] = useState([])
    const [callback, setCallback] = useState(false)

    useEffect(() =>{
        const getcategory_child = async () =>{
            const res = await axios.get('/api/category_child1')
            setcategory_child1(res.data)
        }
        getcategory_child()
    },[callback])

    return {
        category_child1: [category_child1, setcategory_child1],
        callback: [callback, setCallback]
    }
}
