import pica from 'pica';

const handleIncreaseQuality = async (noImage) => {
    if (!noImage) {
        throw new Error('No image provided');
    }
    
    const imageObj = new Image();
    imageObj.src = noImage;

    return new Promise((resolve, reject) => {
        imageObj.onload = async () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = imageObj.width;
            canvas.height = imageObj.height;
            ctx.drawImage(imageObj, 0, 0);

            const picaInstance = pica();
            const resultCanvas = await picaInstance.resize(canvas, canvas, {
                quality: 0.6,
                unsharpAmount: 80,
                unsharpRadius: 0.6,
                unsharpThreshold: 2,
                toDataURL: true
            });

            const newBase64String = resultCanvas.toDataURL('image/jpeg', 0.6); // chất lượng ảnh (từ 0 đến 1)
            resolve(newBase64String);
        };
        imageObj.onerror = (error) => {
            reject(error);
        };
    });
}

export default handleIncreaseQuality;
