import React, {useContext, useEffect, useState} from 'react';
import {GlobalState} from '../../GlobalState';
import { Link } from 'react-router-dom';
import { IoHome, IoSearch } from "react-icons/io5";
import { FaFacebookSquare } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { SiZalo } from "react-icons/si";
import handleIncreaseQuality from '../utils/HandleIncrease';
import { IoMenu } from "react-icons/io5";

export default function Header({data}) {
    const state = useContext(GlobalState)
    const [menu] = state.menuAPI.menu
    const [utils] = state.utilsAPI.utils
    const [isMobile] = state.responsive.isMobile

    const [logo, setLogo] = useState('')
    const [facebook, setFacebook] = useState('')
    const [zalo, setZalo] = useState('')
    const [youtube, setYoutube] = useState('')

    const [image_new, setImage_new] = useState(null);

    const [checkSearch, setCheckSearch] = useState(false)
    const [valueSearch, setValueSearch] = useState('')
    const [checkShowMenu, setCheckShowmenu] = useState(false)

    useEffect(()=>{
        const getData = ()=>{
            utils.forEach(el=>{
                if(el.id===1){
                    setFacebook(el.facebook)
                    setZalo(el.zalo)
                    setYoutube(el.youtube)
                    setLogo(el.logo)
                }
            })
        }
        getData()
    },[utils])

    useEffect(()=>{
        if(logo){
            handle_increase()
        }
    },[logo])

    const handle_increase = async ()=>{
        try {
            const newImage = await handleIncreaseQuality(logo)
            setImage_new(newImage)
            
        } catch (err) {
            console.error('Error:', err)
        }
    }

    const handleSearch = ()=>{
        sessionStorage.setItem('search', valueSearch)
    }

    const showMenu =()=>{
        setCheckShowmenu(!checkShowMenu)
    }
    
    // console.log(utils)
    
  return (
    <div className={data === 'admin' ? 'hidden' : 
        `${isMobile?'px-[10px] py-[10px]':''} w-full h-auto mx-auto flex justify-between fixed z-[100] bg-white shadow-md mt-[-50px]`}>
        <div className={`${isMobile?'flex-wrap h-auto mx-auto':'w-full h-[50px]'} flex items-center justify-center`}>
            <Link to='/'><img src={logo} alt='Logo' className='w-[40px] h-auto mr-[50px] rounded-[50%]'/></Link>
            <Link to='/' className={`${isMobile?'hidden':''}`}>
                <IoHome size={'25px'} className='mr-[20px]' />
            </Link>

            <ul className={`${isMobile?'hidden':'flex'}`}>
                {
                    menu.map(menu=>{
                        return <Link key={menu.id} className={`${isMobile?'block':''}`} to={menu.category===0 ? `/${menu.link}` : menu.category===1000000000 ? menu.link : `/danh-muc/${menu.link}/${menu.category}`} target={menu.category===1000000000?'_blank':''}><li
                            className={`font-bold text-[14px] hover:text-[#ED0678] px-[10px] cursor-pointer`}>
                                {menu.name.toUpperCase()}
                            </li></Link>
                    })
                }
            </ul>

            <div className='flex items-center ml-[20px] relative'>
                <IoSearch size={'20px'} className='mr-[15px] cursor-pointer' onClick={()=>setCheckSearch(!checkSearch)} />
                <Link to={facebook} target='_blank'><FaFacebookSquare size={'25px'} color='#3B5998' /></Link>
                <Link to={zalo} className='h-[22px] border-[1px] border-[#0099FF] bg-white rounded-md px-[5px] py-[-2px] mx-[10px] flex items-center' target='_blank'>
                    <SiZalo size={'22px'} color='#0099FF'/></Link>
                <Link to={youtube} target='_blank'><IoLogoYoutube size={'28px'} color='#FF0000'/></Link>
                
                <div className={`${checkSearch?'':'hidden'} absolute top-[140%] left-[-100%] w-[180%] h-auto bg-white flex`}>
                    <input type='text' placeholder='Nhập nội dung...' value={valueSearch} onChange={(e)=>setValueSearch(e.target.value)}
                        className='w-full h-[35px] text-[14px] border-[1px] border-[#bbb] px-[15px] rounded-sm shadow-sm' />
                    <a href={`/tim-kiem`} onClick={handleSearch}
                        className='flex items-center px-[10px] py-[5px] font-bold bg-[#666] hover:bg-[#777] text-[14px] text-white ml-[1px] rounded-sm'>
                        <IoSearch size={'20px'}/>
                    </a>
                </div>
            </div>
            <IoMenu size={'30px'} className={`${isMobile?'ml-[50px]':'hidden'}`} onClick={showMenu} />
            <ul className={`${isMobile?`w-full pt-[20px] ${checkShowMenu?'':'hidden'}`:'hidden'}`}>
                {
                    menu.map(menu=>{
                        return <Link key={menu.id} className={`${isMobile?'w-full block py-[10px] border-b-[1px] border-[#ccc]':''}`} to={menu.category===0 ? `/${menu.link}` : menu.category===1000000000 ? menu.link : `/danh-muc/${menu.link}/${menu.category}`} target={menu.category===1000000000?'_blank':''}>
                            <li onClick={()=>setCheckShowmenu(false)}
                            className={`font-bold text-[14px] hover:text-[#ED0678] px-[10px] cursor-pointer`}>
                                {menu.name.toUpperCase()}
                            </li></Link>
                    })
                }
            </ul>
        </div>
    </div>
  )
}
