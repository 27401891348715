import React, {useState, useContext} from 'react'
import { GlobalState } from '../../../GlobalState'
import axios from 'axios'
import Button from '../../utils/Button'

export default function CreateFile() {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [isMobile] = state.responsive.isMobile
    const [callback, setCallback] = state.FilesAPI.callback

    const [dataFile, setDataFile] = useState('')
    const [fileNamePDF, setFileNamePDF] = useState('')

    const handleFile = (e)=>{
        const file = e.target.files[0];
        if (file) {
            // Chuyển đổi tệp thành BLOB
            const reader = new FileReader();
            reader.onload = (event) => {
                const blob = new Blob([event.target.result], { type: file.type });
                setDataFile(blob);
                setFileNamePDF(file.name)
            };
            reader.readAsArrayBuffer(file);
        }
    }

    const handleSubmitFile = async (e) => {
        e.preventDefault();

        if (!dataFile) {
            alert('Chưa chọn file để thêm.');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('file', dataFile, `${fileNamePDF}`); // 'filename.pdf' là tên của tệp khi gửi

            const response = await axios.post('/api/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status !== 200) {
                throw new Error('Network response was not ok');
            }
            alert(response.data.msg)
            setCallback(!callback)

            console.log('File uploaded successfully:', response.data);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

  return (
    <div className='w-full h-auto'>
        <div className={`${isMobile?'w-full':'w-[80%]'} h-auto bg-[#f6f6f6] mx-auto p-[20px] mt-[50px] rounded-sm`}>
            <p className='text-center font-bold text-[25px] pb-[20px] text-[#555]'>THÊM FILE PDF</p>
            <div className={`${isMobile?'flex-wrap':''} flex justify-around items-center`}>
                <div>
                    <p className='text-[15px] pb-[10px] font-bold'>Chọn file:</p>
                    <input type="file" accept="application/pdf" onChange={handleFile} onClick={e => (e.target.value = null)}
                        className='h-[40px]' />
                </div>
            </div>

            <Button data={'Thêm'} func={handleSubmitFile} color={'bg-[#3399FF]'} />
        </div>
    </div>
  )
}
