import React, {useContext, useState} from 'react'
import { GlobalState } from '../../../GlobalState'
import axios from 'axios'
import { LuMenuSquare } from "react-icons/lu";
import Button from '../../utils/Button'
import CreateFile from './CreateFile';

const CSS_Input = {
    item: 'w-full h-[50px] border-[1px] border-[#ccc] rounded-md px-[20px] mt-[20px] shadow-md',
    table: 'border-[1px] border-[#bbb] py-[15px] px-[8px] text-center text-[15px]'
}

export default function FilePDF({changeState}) {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [files] = state.FilesAPI.files
    const [callback, setCallback] = state.FilesAPI.callback
    const [isMobile] = state.responsive.isMobile
    const [checkCreate, setCheckCreate] = useState(false)

    const handleDelete = async (id)=>{
        if(window.confirm('Lưu ý: Dữ liệu bị xóa sẽ không thể phục hồi.')){
            try {
                const res = await axios.delete(`/api/file/${id}`,{
                    headers: {Authorization: token}
                })
                alert(res.data.msg)
                setCallback(!callback)
                
            } catch (err) {
                alert(err.response.data.msg)
            }
        }
    }

    const handleClick = ()=>{
        changeState(true)
    }

  return (
    <div>
        <p className='font-bold text-[20px] text-center text-[#333] mt-[30px]'>QUẢN LÝ FILE</p>
        <LuMenuSquare size={'35px'} color='#777' className={`${isMobile?'top-[25px]':'hidden top-[10px]'} absolute left-[10px]`}
          onClick={handleClick}/>
        <Button data={!checkCreate ? 'Thêm file' : 'Danh sách file'} func={()=>setCheckCreate(!checkCreate)} color={'bg-[#0099FF]'} />

        {
            checkCreate ? <CreateFile/> :
            <div className={`${isMobile?'overflow-x-scroll':''} w-full h-auto pb-[30px]`}>
                <table className='m-auto w-full mt-[20px]'>
                    <thead>
                        <tr className=''>
                            {/* <th className={CSS_Input.table}>STT</th> */}
                            <th className={CSS_Input.table}>ID</th>
                            <th className={CSS_Input.table}>Tên file</th>
                            <th className={CSS_Input.table}>Tùy chỉnh</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            files.map((d,i)=>{
                                return <tr key={d.id} >
                                    {/* <td className={CSS_Input.table}>{i+1}</td> */}
                                    <td className={CSS_Input.table}>{d.id}</td>
                                    <td className={CSS_Input.table}>{d.name}</td>
                                    <td className={CSS_Input.table}>
                                        <div className='w-[100px] flex justify-around mx-auto'>
                                            <button onClick={()=>handleDelete(d.id)} className='bg-[#CC3300] hover:bg-[#fe8259] text-[#fff] py-[3px] px-[10px] rounded-md'>Xóa</button>
                                        </div>  
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        }
    </div>
  )
}
