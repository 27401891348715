import React, {useContext, useEffect, useState} from 'react'
import { GlobalState } from '../../GlobalState'
import { Link, useParams } from 'react-router-dom'
import img1 from '../../img/Asset1.jpg'
import img2 from '../../img/Asset2.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function ElementRight() {
    const state = useContext(GlobalState)
    const [news] = state.newsAPI.news
    const [news_menu_right] = state.newsMenuRight.menu_right
    const [utils] = state.utilsAPI.utils
    const [category] = state.categoryAPI.category
    const [news_videos] = state.newsVideosAPI.newsVideos
    const [isMobile] = state.responsive.isMobile
    const params = useParams()

    const [menu_right, setMenu_right] = useState([])
    const [new_menu_right, setNew_menu_right] = useState([])
    const [idCate, setIdCate] = useState(0)
    const [linkCate, setLinkCate] = useState('')
    const [linkPhotos, setLinkPhotos] = useState('')

    useEffect(()=>{
        const scroll = () =>{
            function topFunction() {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
            topFunction()
        }
        scroll()
    },[params.id])

    useEffect(()=>{
        const getUtils = ()=>{
            utils.forEach(el=>{
                setMenu_right(el.menu_right.split('-').map(item=>parseInt(item)))
            })
        }
        getUtils()

        const getLinkFb = ()=>{
            utils.forEach(el=>{
                if(el.id===1){
                    setLinkPhotos(el.photos)
                }
            })
        }
        getLinkFb()
    },[utils])

    useEffect(()=>{
        const handleSortNews = ()=>{
            const groupedData = news_menu_right.reduce((acc, curr) => {
                const { id, title, category } = curr;
                if (!acc[category]) {
                    acc[category] = [];
                }
                acc[category].push({ id, title, category });
                return acc;
            }, {});
            setNew_menu_right(groupedData)
        }
        handleSortNews()
    },[news_menu_right])

    useEffect(()=>{
        const getId = ()=>{
            news_videos.forEach(el=>{
                setIdCate(el.category)
                category.forEach(c=>{
                    if(c.id===el.category){
                        setLinkCate(c.link)
                    }
                })
            })
        }
        getId()
    },[news_videos])

    useEffect(() => {
        AOS.init();
    }, []);

  return (
    <div className={`${isMobile?'mt-[20px]':''} w-full h-auto`}>
        <div className='w-full h-auto'>
            <p className='text-[20px] font-bold pb-[2px] border-b-[2px] border-[#ED0678] mb-[15px]'>Tin nổi bật</p>
            {
                news.map((n,i)=>{
                    if(i<=2){
                        return <Link to={`/chi-tiet-bai-viet/${n.id}`} key={n.id} className='w-full'>
                            <div key={n.id} className={`${isMobile?'flex-wrap':''} flex mb-[15px] w-full`}>
                                <img src={n.images} alt='ảnh đại diện' className={`${isMobile?'w-full h-[200px]':'w-[35%] h-[65px]'} object-cover`} />
                                <p className='text-[15px] text-justify w-[65%] ml-[10px] hover:text-[#ed0678]'>{n.title.slice(0,80)}....</p>
                            </div>
                        </Link>
                    }
                })

            }
        </div>

        {
            menu_right.map((m,index)=>{
                return <div key={index} className='w-full h-[250px] mb-[10px] bg-[#eee] overflow-hidden overflow-y-scroll'>
                    {
                        category.map(cate=>{
                            if(cate.id===m){
                                return <Link to={`/danh-muc/${cate.link}/${cate.id}`} key={cate.id}>
                                    <p className='bg-[#ED0678] text-white text-center font-bold py-[5px]'>
                                        {cate.name}
                                    </p>
                                </Link>
                            }
                        })
                    }
                    <div className='aos-init' data-aos="slide-up" data-aos-duration="3000">
                        {
                            Object.keys(new_menu_right).map(k=>{
                                if(parseInt(k)===m){
                                    return new_menu_right[k].map((new_menu,i)=>{
                                        return <Link to={`/chi-tiet-bai-viet/${new_menu.id}`} key={i}>
                                            <p className='px-[15px] py-[10px] text-[14px] hover:text-[#ED0678] flex border-b-[1px] border-[#ccc]'>
                                                - {new_menu.title}
                                            </p>
                                        </Link>
                                    })
                                }
                            })
                        }
                    </div>
                </div>
            })
        }

        <div className='w-full h-auto mt-[20px]'>
            <Link to={`/danh-muc/${linkCate}/${idCate}`}>
                <img src={img1} alt='banner video' />
            </Link>
            <Link to={linkPhotos} target='_blank' className='mt-[10px] inline-block'>
                <img src={img2} alt='banner photos' />
            </Link>
        </div>
    </div>
  )
}
