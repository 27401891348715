import React from 'react'
import Section1 from './section/Section1'
import Section2 from './section/Section2'
import Section3 from './section/Section3'

export default function Footer({data}) {
  return (
    <div className={data === 'admin' ? 'hidden' : 'w-full h-auto'}>
      <Section1/>
      <Section2/>
      <Section3/>
    </div>
  )
}
