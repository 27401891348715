import React, {useState, useContext} from 'react'
import { GlobalState } from '../../../GlobalState'
import axios from 'axios'
import Button from '../../utils/Button'
import noImage from '../../../img/no-image.jpg'
import ImageCompressor from 'image-compressor.js'

const CSS_Input = {
    item: 'w-full h-[50px] border-[1px] border-[#ccc] rounded-md px-[20px] mt-[20px] shadow-md',
    table: 'border-[1px] border-[#bbb] py-[15px] px-[8px] text-center text-[15px]'
}

export default function Donors() {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [callback, setCallback] = state.donorsAPI.callback
    const [link, setLink] = useState('')
    const [image, setImage] = useState('')
    const [isMobile] = state.responsive.isMobile

    const handleImage = (value) =>{
        // const file = e.target.files[0]
        const file = value
        const reader = new FileReader();
        reader.onloadend = () => {
            setImage(reader.result)
        }
        reader.readAsDataURL(file)
    }

    function CompressImage(e) {
        const file = e.target.files[0];
        
        // Kiểm tra kích thước của tệp trước khi nén
        const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB
        if (file.size > maxSizeInBytes) {
            alert('Kích thước file phải dưới 1MB.')
            return
        }

        var compressor = new ImageCompressor()
    
        compressor.compress(file, {
            quality: file.size < 50000 ? 0.8 : file.size >= 50000 && file.size < 400000 ? 0.4 : file.size >= 400000 && file.size < 800000 ? 0.3 : file.size >= 800000 ? 0.2: null,
            // maxWidth: 100, 
            // maxHeight: 100,
            mimeType: 'image/jpeg',
            success(result) {
                handleImage(result);
            },
            error(e) {
                console.log(e.message);
            },
        })
    }

    const handlesubmit = async () => {
        const today = new Date()
        const formattedDate = today.toISOString().split('T')[0];
        
        try {
          const res = await axios.post(`/api/donors`, {link: link, image: image, date_created: formattedDate}, {
            headers: {Authorization: token}
          })
          if(res) {
              alert(res.data.msg)
              setCallback(!callback)
          }
          
        } catch (err) {
          alert(err.response.data.msg)
        }
    }

  return (
    <div className='w-full h-auto'>
        <div className={`${isMobile?'w-full':'w-[80%]'} h-auto bg-[#f6f6f6] mx-auto p-[20px] mt-[50px] rounded-sm`}>
            <p className='text-center font-bold text-[25px] pb-[20px] text-[#555]'>LOGO NHÀ TÀI TRỢ</p>
            <div className={`${isMobile?'flex-wrap':''} flex justify-around items-center`}>
                <div>
                    <p className='text-[15px] pb-[10px] font-bold'>Chọn ảnh logo:</p>
                    <input type="file" name="file" id="file_up" onChange={CompressImage} onClick={e => (e.target.value = null)}
                        className='h-[40px]' />
                </div>

                <div className='w-[300px] h-[194px] bg-slate-200'>
                    <img src={image?image:noImage} alt="Ảnh đại diện" style={{ maxWidth: '300px' }}
                        className='w-full aspect-[17/11] object-cover' />
                </div>
            </div>

            <input type='text' name='link' value={link} placeholder='Link website' onChange={(e)=>setLink(e.target.value)} 
                className={CSS_Input.item}/>

            <Button data={'Thêm'} func={handlesubmit} color={'bg-[#3399FF]'} />
        </div>
    </div>
  )
}
