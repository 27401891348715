import React, {useContext} from 'react'
import { GlobalState } from '../../../GlobalState'
import axios from 'axios'
import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";

const CSS_Input = {
    item: 'w-full h-[50px] border-[1px] border-[#ccc] rounded-md px-[20px] mt-[20px] shadow-md',
    table: 'border-[1px] border-[#bbb] py-[15px] px-[8px] text-center text-[15px]'
}

export default function ListMenu({func, setId}) {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [menu] = state.menuAPI.menu
    const [callback, setcallback] = state.menuAPI.callback
    const [category] = state.categoryAPI.category
    const [isMobile] = state.responsive.isMobile

    const handleUpdate = (id)=>{
        func(true)
        setId(id)
    }

    const handleDelete = async (id)=>{
        var idCate = 0
        menu.forEach(el=>{
            if(el.id===id){
                idCate = el.category
            }
        })
        if(window.confirm('Lưu ý: Dữ liệu bị xóa sẽ không thể phục hồi.')){
            try {
                const res = await axios.delete(`/api/menu/${id}`,{
                    headers: {Authorization: token}
                })
                updateCheckmenu(idCate)
                alert(res.data.msg)
                setcallback(!callback)
                
            } catch (err) {
                alert(err.response.data.msg)
            }
        }
    }

    const updateCheckmenu = async (id)=>{
        try {
            await axios.put(`/api/category-menu/${id}`,{value: 0},{
                headers: {Authorization: token}
            })
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const goUp = async (id)=>{
        try {
            const res = await axios.get(`/api/up-menu/${id}`, {
                headers: {Authorization: token}
            })
            setcallback(!callback)
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const goDown = async (id)=>{
        try {
            const res = await axios.get(`/api/down-menu/${id}`, {
                headers: {Authorization: token}
            })
            setcallback(!callback)
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

  return (
    <div className={`${isMobile?'overflow-x-scroll':''} w-full h-auto mt-[20px]`}>
        <table className='m-auto w-full'>
            <thead>
                <tr>
                    <th className={CSS_Input.table}>STT</th>
                    <th className={CSS_Input.table}>Tên</th>
                    <th className={CSS_Input.table}>Link</th>
                    <th className={CSS_Input.table}>Thể loại</th>
                    <th className={CSS_Input.table}>Tùy chỉnh</th>
                </tr>
            </thead>
            <tbody>
                    {
                        menu.map((m,i)=>{
                            return <tr key={m.id}>
                                <td className={CSS_Input.table}>{i+1}</td>
                                <td className={CSS_Input.table}>{m.name}</td>
                                <td className={CSS_Input.table}>{m.link}</td>
                                <td className={CSS_Input.table}>
                                    {
                                        category.map(c=>{
                                            if(c.id===m.category){
                                                return <p key={c.id}>{c.name}</p>
                                            }
                                        })
                                    }
                                </td>
                                <td className={CSS_Input.table}>
                                    <div className='w-[100px] mx-auto flex items-center justify-around'>
                                        {/* <button onClick={()=>handleUpdate(m.id)} className='bg-[#FFCC33] hover:bg-[#fde18d] py-[3px] px-[10px] rounded-md'>Sửa</button> */}
                                        <FaArrowUpLong size={'25px'} color='#444' onClick={()=>goUp(m.id)}
                                            className='cursor-pointer bg-slate-200 mr-[6px] p-[5px] rounded-sm hover:bg-slate-100'/>
                                        <FaArrowDownLong size={'25px'} color='#444' onClick={()=>goDown(m.id)}
                                            className='cursor-pointer bg-slate-200 mr-[6px] p-[5px] rounded-sm hover:bg-slate-100'/>
                                        <button onClick={()=>handleDelete(m.id)} className='bg-[#CC3300] hover:bg-[#fe8259] text-[#fff] py-[3px] px-[10px] rounded-md'>Xóa</button>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
            </tbody>
        </table>
    </div>
  )
}
