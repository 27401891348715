import React, {useContext, useEffect, useState} from 'react'
import { GlobalState } from '../../../GlobalState'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import ElementRight from '../../utils/ElementRight'
import Button from '../../utils/Button'
import Loading from '../../utils/Loading'
import Answers from '../../admin/answers/Answers'

export default function CategoryNews() {
    const state = useContext(GlobalState)
    const [categories] = state.categoryAPI.category
    const [category_child1] = state.category_childAPI.category_child1
    const [cluster] = state.clusterAPI.cluster
    const [answers] = state.answersAPI.answers
    const [isMobile] = state.responsive.isMobile
    const [data, setData] = useState([])

    const [limit, setLimit] = useState(7)
    const [categoryName, setCategoryName] = useState('')
    const [clusterName, setClusterName] = useState('')
    const [answersName, setanswersName] = useState('')
    const [category_childName, setcategory_childName] = useState('')
    const [check, setCheck] = useState([])
    const [loading, setLoading] = useState(false)

    const params = useParams()

    useEffect(()=>{
        const getcategory = async () =>{
            const id = params.id
            if(params.link!=='cum'){
                let cate = '', ans = '', danhmucchinh = '', cap2 = ''
                categories.forEach(el=>{
                    if(el.link===params.link){
                        cate=(el.id)
                    }
                })
                setCheck(cate)

                answers.forEach(el=>{
                    if(el.link===params.link){
                        ans=(el.id)
                    }
                })

                category_child1.forEach(el=>{
                    if(el.link===params.link){
                        danhmucchinh=(el.id)
                    }
                })

                cluster.forEach(el=>{
                    if(el.link===params.link){
                        cap2=(el.id)
                    }
                })

                if(parseInt(cate)===parseInt(params.id)){
                    const res = await axios.get(`/api/get-news-category/${id}/${limit}`)
                    setData(res.data)
                    setLoading(false)
                }

                if(parseInt(ans)===parseInt(params.id)){
                    const res = await axios.get(`/api/news-answers/${id}/${limit}`)
                    setData(res.data)
                    setLoading(false)
                }

                if(parseInt(danhmucchinh)===parseInt(params.id)){
                    const res = await axios.get(`/api/get-news-danhmucchinh/${id}/${limit}`)
                    setData(res.data)
                    setLoading(false)
                }

                if(parseInt(cap2)===parseInt(params.id)){
                    const res = await axios.get(`/api/get-news-cluster/${id}/${limit}`)
                    setData(res.data)
                    setLoading(false)
                }
            }
            else {
                const res = await axios.get(`/api/get-news-cluster/${id}/${limit}`)
                setData(res.data)
                setLoading(false)
            }
        }
        getcategory()
    },[params, limit])

    // lấy tên danh mục
    useEffect(() =>{
        const handleCategory = ()=>{
            categories.forEach(el=>{
                if(el.id===parseInt(params.id) && el.link===params.link){
                    setCategoryName(el.name)
                }
            })
        }
        handleCategory()

        const handlecluster = ()=>{
            cluster.forEach(el=>{
                if(el.id===parseInt(params.id) && el.link===params.link){
                    setClusterName(el.name)
                }
            })
        }
        handlecluster()

        const handleAnswers = ()=>{
            answers.forEach(el=>{
                if(el.id===parseInt(params.id) && el.link===params.link){
                    setanswersName(el.name)
                }
            })
        }
        handleAnswers()

        const handleChild = ()=>{
            category_child1.forEach(el=>{
                if(el.id===parseInt(params.id) && el.link===params.link){
                    setcategory_childName(el.name)
                }
            })
        }
        handleChild()
    },[params])

    const HandleLoad = ()=>{
        setLimit(limit+3)
        setLoading(true)
    }

    useEffect(()=>{
        const scroll = () =>{
            function topFunction() {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
            topFunction()
        }
        scroll()
    },[])
    
    return (
        <div className={`${isMobile?'flex-wrap ml-[20px]':''} max-w-[1000px] h-auto mx-auto flex justify-between mt-[10px] relative`}>
            <div className={`${isMobile?'w-full':'w-[64%]'} h-auto`}>
                {/* <p className='text-[20px] font-bold pb-[2px] border-b-[2px] border-[#ED0678] mb-[15px]'>{params.link==='cum'?clusterName:check.length!==0?categoryName:answersName}</p> */}
                <p className='text-[20px] font-bold pb-[2px] border-b-[2px] border-[#ED0678] mb-[15px]'>{categoryName?categoryName:clusterName?clusterName:answersName?answersName:category_childName}</p>
                <div className={`${isMobile?'flex-wrap h-auto':'h-auto'} w-full flex justify-between`}>
                    <div className={`${isMobile?'w-full':'w-[48%]'}`}>
                        {
                            data.map((data,i)=>{
                                if(i===0){
                                    return <div key={data.id}>
                                        <Link to={`/chi-tiet-bai-viet/${data.id}`}>
                                            <img src={data.images} alt='ảnh đại diện' className='w-[300px] h-[200px] object-cover mx-auto' />
                                            <p className='hover:text-[#ed0678] text-[15px] text-justify font-bold pt-[5px]'>{data.title}</p>
                                        </Link>
                                    </div>
                                }
                            })
                        }
                    </div>
                    <div className={`${isMobile?'w-full mt-[10px]':'w-[48%]'}`}>
                        {
                            data.map((data,i)=>{
                                if(i>0 && i<=3){
                                    return <Link to={`/chi-tiet-bai-viet/${data.id}`} key={data.id}>
                                        <div className={`flex`}>
                                            <img src={data.images} alt='ảnh đại diện' className='w-[150px] h-[65px] object-cover mx-auto mb-[15px]' />
                                            <p className='hover:text-[#ed0678] text-[14px] text-justify ml-[10px]'>{data.title}</p>
                                        </div>
                                    </Link>
                                }
                            })
                        }
                    </div>
                </div>

                <div className='w-full h-[8px] border-dotted border-y-[2px] border-[#ddd] my-[20px]'></div>

                <div className='w-full h-auto'>
                    {
                        data.map((data,i)=>{
                            if(i>3){
                                return <Link to={`/chi-tiet-bai-viet/${data.id}`} key={data.id}>
                                    <div className={`${isMobile?'flex-wrap':''} flex mb-[20px]`}>
                                        <img src={data.images} alt='ảnh đại diện' className={`${isMobile?'w-full h-[200px]':'w-[230px] h-[160px]'} object-cover`} />
                                        <div className={`${isMobile?'':'ml-[20px]'}`}>
                                            <p className='text-[18px] text-justify font-bold hover:text-[#ed0678]'>{data.title}</p>
                                            <p className='text-[13px] text-justify mt-[10px]'>{data.description}</p>
                                        </div>
                                    </div>
                                </Link>
                            }
                        })
                    }
                </div>

                <Button data={'Xem thêm'} func={HandleLoad} color={'bg-[#aaa]'} />
            </div>

            <div className={`${isMobile?'w-full':'w-[32%]'} h-auto`}>
                <ElementRight/>
            </div>
            {
                loading ? <Loading/> : <></>
            }
        </div>
    )
}
//      params.link!=='cum'?categoryName:clusterName