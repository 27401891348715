import React from 'react'

export default function Loading() {
    return (
        <div className='w-full h-screen fixed top-0 left-0 z-[101]'>
            <div className='w-full h-full bg-black opacity-[0.5]'>
            </div>
            <div className='absolute top-[45%] left-[50%] translate-x-[-50%] text-[30px] 
                text-[#fff] font-bold bg-[#666] px-[20px] py-[10px] rounded-md'>Loading...</div>
        </div>
    );
}
