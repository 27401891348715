import React, {useEffect, useState} from 'react'
import axios from 'axios'

export default function UtilsAPI() {
    const [utils, setutils] = useState([])
    const [callback, setCallback] = useState(false)

    useEffect(() => {
        const getutils = async ()=>{
            const res = await axios.get('/api/utils')
            setutils(res.data)
        }
        getutils()
    },[callback])

    return {
        utils: [utils, setutils],
        callback: [callback, setCallback]
    }
}
