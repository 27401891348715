import React, {useState, useEffect} from 'react'
import axios from 'axios'

export default function QR_codeAPI() {
    const [qr_code, setQr_code] = useState([])
    const [callback, setCallback] = useState(false)

    useEffect(() =>{
        const getqr_code = async () =>{
            const res = await axios.get('/api/qr_code')
            setQr_code(res.data)
        }
        getqr_code()
    },[callback])

    return {
        qr_code: [qr_code, setQr_code],
        callback: [callback, setCallback]
    }
}
