import React, {useEffect, useState, useContext} from 'react'
import { GlobalState } from '../../../GlobalState'
import axios from 'axios'
import bg from '../../../img/bg-admin.jpg'
import bg1 from '../../../img/bg-admin1.jpg'
import Button from '../../utils/Button'
import handleIncreaseQuality from '../../utils/HandleIncrease'
import { FaEye, FaEyeSlash } from "react-icons/fa";

const dataDefault = {
  userName: '',
  password: ''
}

export default function Login() {
  const state = useContext(GlobalState)
  const [utils] = state.utilsAPI.utils
  const [data, setData] = useState(dataDefault)
  const [isMobile] = state.responsive.isMobile
  const [showPass, setShowPass] = useState(false)

  const [noImage, setnoImage] = useState('')
  const [image_new, setImage_new] = useState(null)

  const handleOnchange = (e) =>{
        const {name, value} = e.target
        setData({...data, [name]:value})
  }

  const handlesubmit = async (event) =>{
    try {
        await axios.post('/api/login', data)
        localStorage.setItem('Login', true)
        window.location.href = "/admin"
      
    } catch (err) {
        alert(err.response.data.msg)
    }
  }

  useEffect(()=>{
    const getId = ()=>{
        utils.forEach(el=>{
            if(el.id===1){
                setnoImage(el.logo)
            }
        })
    }
    getId()
  },[utils])

  useEffect(()=>{
      if(noImage){
          handle_increase()
      }
  },[noImage])

  const handle_increase = async ()=>{
    try {
        const newImage = await handleIncreaseQuality(noImage)
        setImage_new(newImage)
        
    } catch (err) {
        console.error('Error:', err)
    }
  }


  return (
    <div className='w-full h-screen flex items-center bg-slate-200 relative'>
        <div className='w-[360px] relative border-[2px] border-[#fff] h-auto mx-auto p-[20px] rounded-lg shadow-xl z-10'>
            <img src={noImage} alt='Logo' className='w-[100px] h-auto mx-auto rounded-[50%]' />
            <input type='text' name='userName' placeholder='Tên đăng nhập' value={data.userName} onChange={handleOnchange}
                className='w-full h-[50px] border-[1px] border-[#ccc] rounded-md px-[20px] mt-[20px] shadow-md' />
            <div className='relative'>
                <FaEye size={'18px'} className={`${showPass?'hidden':''} absolute top-[50%] right-[10px] cursor-pointer`} onClick={()=>setShowPass(!showPass)} />
                <FaEyeSlash size={'18px'} className={`${showPass?'':'hidden'} absolute top-[50%] right-[10px] cursor-pointer`} onClick={()=>setShowPass(!showPass)} />
                <input type={`${showPass?'text':'password'}`} name='password' placeholder='Mật khẩu' value={data.password} onChange={handleOnchange}
                    className='w-full h-[50px] border-[1px] border-[#ccc] rounded-md px-[20px] mt-[20px] shadow-md' />
            </div>
            <br/>
            <Button data={'ĐĂNG NHẬP'} func={handlesubmit} color={'bg-[#3399FF]'} />
            {/* <p onClick={sendPass}>Quên mật khẩu</p> */}
            {/* <button onClick={handlesubmit}
                className='bg-[#3399FF] px-[15px] py-[5px] font-bold text-white mt-[20px] ml-[50%] translate-x-[-50%] rounded-sm text-[15px]'>ĐĂNG NHẬP</button> */}
            <div className='w-full h-full bg-black absolute top-0 left-0 -z-[1] opacity-[0.3] rounded-md'></div>
        </div>
        
        {
            isMobile ?
            <img src={bg1} alt='ảnh nền' className='w-full h-full absolute top-0 left-0 z-[1]' />
            :
            <img src={bg} alt='ảnh nền' className='w-full h-full absolute top-0 left-0 z-[1]' />
        }
    </div>
  )
}
