import React, {useContext, useState, useEffect} from 'react'
import { GlobalState } from '../../../../GlobalState'
import Button from '../../../utils/Button'
import axios from 'axios'

export default function Categgory() {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [utils] = state.utilsAPI.utils
    const [categories] = state.categoryAPI.category
    const [idCate, setIdCate] = useState(0)
    const [category, setCategory] = useState([])
    const [isMobile] = state.responsive.isMobile

    const [cate_new1, setCate_new1] = useState(0)
    const [cate_new2, setCate_new2] = useState(0)
    const [cate_new3, setCate_new3] = useState(0)
    const [cate_new4, setCate_new4] = useState(0)
    const [cate_new5, setCate_new5] = useState(0)

    useEffect(()=>{
        const getData = ()=>{
            utils.forEach(el=>{
                if(el.id===1){
                    let id = (el.category).split('-').map(i=>+i)
                    setIdCate(el.id)
                    setCategory(id)
                    setCate_new1(id[0])
                    setCate_new2(id[1])
                    setCate_new3(id[2])
                    setCate_new4(id[3])
                    setCate_new5(id[4])
                    console.log(id)
                }
            })
        }
        getData()
    },[utils])

    const handleSubmit = async ()=>{
        let cate = `${cate_new1}-${cate_new2}-${cate_new3}-${cate_new4}-${cate_new5}`
        try {
            const res = await axios.put(`/api/utils2/${idCate}/category`, {data: cate}, {
                headers: {Authorization: token}
            })
            alert(res.data.msg)
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    // console.log(cate_new1, cate_new2, cate_new3, cate_new4, cate_new5)

  return (
    <div className='w-full h-screen bg-[#eee] p-[20px]'>
        <p className={`${isMobile?'mx-[30px]':''} font-bold text-center text-[20px] pb-[20px]`}>QUẢN LÝ CÁC DANH MỤC HIỂN THỊ CHÍNH</p>
        <div className={`${isMobile?'w-full':'w-[70%]'} h-auto mx-auto mb-[20px] text-[14px]`}>
            <label className='font-bold ml-[5px]'>Danh mục 1:</label>
            <select value={cate_new1} name='' onChange={(e)=>setCate_new1(e.target.value)} className='w-full h-[50px] px-[15px] border-[1px] border-[#ccc] rounded-md shadow-md mt-[5px]'>
                {
                    category.map((cate,i)=>{
                        if(i===0){
                            return categories.map(child=>{
                                if(child.id===cate){
                                    return <option hidden key={child.id}>{child.name}</option>
                                }
                            })
                        }
                    })
                }
                {
                    categories.map(child=>(
                        <option value={child.id} key={child.id}>{child.name}</option>
                    ))
                }
            </select>
        </div>

        <div className={`${isMobile?'w-full':'w-[70%]'} h-auto mx-auto mb-[20px] text-[14px]`}>
            <label className='font-bold ml-[5px]'>Danh mục 2:</label>
            <select value={cate_new2} name='' onChange={(e)=>setCate_new2(e.target.value)} className='w-full h-[50px] px-[15px] border-[1px] border-[#ccc] rounded-md shadow-md mt-[5px]'>
                {
                    category.map((cate,i)=>{
                        if(i===1){
                            return categories.map(child=>{
                                if(child.id===cate){
                                    return <option hidden key={child.id}>{child.name}</option>
                                }
                            })
                        }
                    })
                }
                {
                    categories.map(child=>(
                        <option value={child.id} key={child.id}>{child.name}</option>
                    ))
                }
            </select>
        </div>

        <div className={`${isMobile?'w-full':'w-[70%]'} h-auto mx-auto mb-[20px] text-[14px]`}>
            <label className='font-bold ml-[5px]'>Danh mục 3:</label>
            <select value={cate_new3} name='' onChange={(e)=>setCate_new3(e.target.value)} className='w-full h-[50px] px-[15px] border-[1px] border-[#ccc] rounded-md shadow-md mt-[5px]'>
                {
                    category.map((cate,i)=>{
                        if(i===2){
                            return categories.map(child=>{
                                if(child.id===cate){
                                    return <option hidden key={child.id}>{child.name}</option>
                                }
                            })
                        }
                    })
                }
                {
                    categories.map(child=>(
                        <option value={child.id} key={child.id}>{child.name}</option>
                    ))
                }
            </select>
        </div>

        <div className={`${isMobile?'w-full':'w-[70%]'} h-auto mx-auto mb-[20px] text-[14px]`}>
            <label className='font-bold ml-[5px]'>Danh mục 4:</label>
            <select value={cate_new4} name='' onChange={(e)=>setCate_new4(e.target.value)} className='w-full h-[50px] px-[15px] border-[1px] border-[#ccc] rounded-md shadow-md mt-[5px]'>
                {
                    category.map((cate,i)=>{
                        if(i===3){
                            return categories.map(child=>{
                                if(child.id===cate){
                                    return <option hidden key={child.id}>{child.name}</option>
                                }
                            })
                        }
                    })
                }
                {
                    categories.map(child=>(
                        <option value={child.id} key={child.id}>{child.name}</option>
                    ))
                }
            </select>
        </div>

        <div className={`${isMobile?'w-full':'w-[70%]'} h-auto mx-auto text-[14px]`}>
            <label className='font-bold ml-[5px]'>Danh mục 5:</label>
            <select value={cate_new5} name='' onChange={(e)=>setCate_new5(e.target.value)} className='w-full h-[50px] px-[15px] border-[1px] border-[#ccc] rounded-md shadow-md mt-[5px]'>
                {
                    category.map((cate,i)=>{
                        if(i===4){
                            return categories.map(child=>{
                                if(child.id===cate){
                                    return <option hidden key={child.id}>{child.name}</option>
                                }
                            })
                        }
                    })
                }
                {
                    categories.map(child=>(
                        <option value={child.id} key={child.id}>{child.name}</option>
                    ))
                }
            </select>
        </div>

        <Button data={'Cập nhật'} func={handleSubmit} color={'bg-[#0099ff]'} />
    </div>
  )
}
