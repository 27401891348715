import React from 'react'

export default function Button({data, func, color}) {
  return (
    <button onClick={func}
        className={`${color} px-[15px] py-[5px] font-bold text-white mt-[20px] 
            ml-[50%] translate-x-[-50%] rounded-sm text-[15px] hover:bg-[#63abf3]`}>
        {data}
    </button>
  )
}
