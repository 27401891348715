import React, {useState, useContext, useEffect} from 'react'
import { GlobalState } from '../../../../GlobalState'
import Button from '../../../utils/Button'
import axios from 'axios';

import handleIncreaseQuality from '../../../utils/HandleIncrease';
import ImageCompressor from 'image-compressor.js'

export default function Background() {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [utils] = state.utilsAPI.utils
    const [idUtils, setIdUtils] = useState(0)
    const [isMobile] = state.responsive.isMobile

    const [noImage, setnoImage] = useState('')
    const [image_new, setImage_new] = useState(null)
    const [image, setImage] = useState('')

    useEffect(()=>{
        const getId = ()=>{
            utils.forEach(el=>{
                if(el.id===1){
                    setIdUtils(el.id)
                    setnoImage(el.background)
                }
            })
        }
        getId()
    },[utils])

    useEffect(()=>{
        if(noImage){
            handle_increase()
        }
    },[noImage])

    const handleImage = (value) =>{
        // const file = e.target.files[0];
        const file = value
        // setImage(file)
        const reader = new FileReader();
        reader.onloadend = () => {
            setImage(reader.result)
        }
        reader.readAsDataURL(file)
    }

    function CompressImage(e) {
        const file = e.target.files[0];
        
        // Kiểm tra kích thước của tệp trước khi nén
        const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB
        if (file.size > maxSizeInBytes) {
            alert('Kích thước file phải dưới 1MB.')
            return
        }

        var compressor = new ImageCompressor()
    
        compressor.compress(file, {
            quality: file.size < 50000 ? 0.8 : file.size >= 50000 && file.size < 400000 ? 0.4 : file.size >= 400000 && file.size < 800000 ? 0.3 : file.size >= 800000 ? 0.2: null,
            // maxWidth: 100, 
            // maxHeight: 100,
            mimeType: 'image/jpeg',
            success(result) {
                handleImage(result);
            },
            error(e) {
                console.log(e.message);
            },
        })
    }

    const handle_increase = async ()=>{
        try {
            const newImage = await handleIncreaseQuality(noImage)
            setImage_new(newImage)
            
        } catch (err) {
            console.error('Error:', err)
        }
    }

    const handlesubmit = async () => {
        try {
            const res = await axios.put(`/api/utils2/${idUtils}/background`,{data: image}, {
                headers: {Authorization: token}
            })
            alert(res.data.msg)
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }
    
    // console.log(noImage)
    // console.log(image_new)
    // console.log(image)

  return (
    <div>
        <div className={`${isMobile?'w-full':'w-[80%]'} h-auto bg-[#f6f6f6] mx-auto p-[20px] mt-[50px] rounded-sm`}>
            <p className='text-center font-bold text-[25px] pb-[20px] text-[#555]'>BACKGROUND</p>
            <div className={`${isMobile?'flex-wrap':''} flex justify-around items-center`}>
                <div>
                    <p className='text-[15px] pb-[10px] font-bold'>Chọn ảnh:</p>
                    <input type="file" name="file" id="file_up" onChange={CompressImage} onClick={e => (e.target.value = null)}
                        className='h-[40px]' />
                </div>

                <div className='w-[300px] h-[194px] bg-slate-200'>
                    <img src={image?image:image_new} alt="Ảnh đại diện" style={{ maxWidth: '300px' }}
                        className='w-full aspect-[17/11] object-cover' />
                </div>
            </div>

            <Button data={'Cập nhật'} func={handlesubmit} color={'bg-[#3399FF]'} />
        </div>
    </div>
  )
}
