import React, {useEffect, useState, useContext} from 'react';
import { GlobalState } from '../../../GlobalState';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ElementRight from '../../utils/ElementRight'

export default function Search() {
    const state = useContext(GlobalState)
    const [data, setData] = useState([])
    const [isMobile] = state.responsive.isMobile
    const [err, setErr] = useState(false)

    const valueSearch = sessionStorage.getItem('search')
    
    useEffect(()=>{
        if(valueSearch){
            const handleSubmitSearch = async ()=>{
                try {
                    const res = await axios.post(`/api/search-news`, {value: valueSearch})
                    setData(res.data)
                    
                } catch (err) {
                    console.log(err.response.data.msg)
                    setErr(true)
                }
            }
            handleSubmitSearch()
        }
    },[valueSearch])

  return (
    <div className={`${isMobile?'flex-wrap ml-[20px]':''} max-w-[1000px] h-auto mx-auto flex justify-between pt-[20px]`}>
        <div className={`${isMobile?'w-full':'w-[64%]'} h-auto`}>
        <p className='text-[20px] font-bold pb-[2px] border-b-[2px] border-[#ED0678] mb-[15px]'>Kết quả tìm kiếm ({data.length})</p>
            <div className='w-full h-auto'>
                {   data.length!==0 ?
                    data.map((data,i)=>{
                        return <Link to={`/chi-tiet-bai-viet/${data.id}`} key={data.id}>
                            <div className={`${isMobile?'flex-wrap':''} flex mb-[20px]`}>
                                <img src={data.images} alt='ảnh đại diện' className={`${isMobile?'w-full h-[200px]':'w-[230px] h-[160px]'} w-[230px] h-[160px] object-cover`} />
                                <div className={`${isMobile?'':'ml-[20px]'}`}>
                                    <p className='text-[18px] text-justify font-bold hover:text-[#ed0678]'>{data.title}</p>
                                    <p className='text-[13px] text-justify mt-[10px]'>{data.description}</p>
                                </div>
                            </div>
                        </Link>
                    })
                    :
                    <>  
                        <p className='text-center font-semibold text-[20px]'>Không tìm thấy kết quả</p>
                        <p className='text-center font-semibold text-[20px]'>(Gợi ý: Hãy nhập đầy đủ tên bài viết)</p>
                    </>
                }
            </div>
        </div>
        <div className={`${isMobile?'w-full':'w-[34%]'} h-auto`}>
            <ElementRight/>
        </div>
    </div>
  )
}
