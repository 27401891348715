import React, {createContext, useState, useEffect} from 'react';
import axios from 'axios';
import NewsAPI from './api/NewsAPI';
import AccountAPI from './api/AccountAPI';
import CategoryAPI from './api/CategoryAPI';
import MenuAPI from './api/MenuAPI';
import ClusterAPI from './api/ClusterAPI';
import UtilsAPI from './api/UtilsAPI';
import NewsFilterCate from './api/NewsFilterCate';
import NewsMenuRight from './api/NewsMenuRight';
import NewsVideosAPI from './api/NewsVideosAPI';
import QR_codeAPI from './api/QR_codeAPI';
import AnswersAPI from './api/AnswersAPI';
import NewsAnswersAPI from './api/NewsAnswersAPI';
import DonorsAPI from './api/DonorsAPI';
import CategoryHomePage from './api/CategoryHomePage';
import Responsive from './api/Responsive';
import Category_childAPI from './api/Category_childAPI';
import Img_slideAPI from './api/Img_slideAPI';
import FilesAPI from './api/FilesAPI';

export const GlobalState = createContext();

export const DataProvider = ({children}) =>{
    const [token, setToken] = useState(false)
    
    useEffect(() =>{
        const firstLogin = localStorage.getItem('Login')
        if(firstLogin){
            const refreshToken = async () =>{
                const res = await axios.get('/api/refresh_token')
                setToken(res.data.accessToken)
    
                setTimeout(() =>{
                    refreshToken()
                }, 10 * 60 * 1000) // 10 phút
            }
            refreshToken()
        }
    },[])

    const state = {
        token: [token, setToken],
        accountAPI: AccountAPI(token),
        newsAPI: NewsAPI(),
        categoryAPI: CategoryAPI(),
        menuAPI: MenuAPI(),
        clusterAPI: ClusterAPI(),
        utilsAPI: UtilsAPI(),
        newsFilterCate: NewsFilterCate(),
        newsMenuRight: NewsMenuRight(),
        newsVideosAPI: NewsVideosAPI(),
        qr_codeAPI: QR_codeAPI(),
        answersAPI: AnswersAPI(),
        newsAnswersAPI: NewsAnswersAPI(),
        donorsAPI: DonorsAPI(),
        categoryHomePage: CategoryHomePage(),
        responsive: Responsive(),
        category_childAPI: Category_childAPI(),
        Img_slideAPI: Img_slideAPI(),
        FilesAPI: FilesAPI()
    }

    return(
        <GlobalState.Provider value = {state}>
            {children}
        </GlobalState.Provider>
    )
}