import React, {useState, useContext, useEffect} from 'react'
import { GlobalState } from '../../../GlobalState'
import axios from 'axios'
import Button from '../../utils/Button'

const dataDefault = {
    name: '',
    id_cate: 0
}

export default function CreateCategory({create}) {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [callback, setCallback] = state.categoryAPI.callback
    const [category_child] = state.category_childAPI.category_child1

    const [data, setData] = useState(dataDefault)
    const [check_con, setCheck_con] = useState(false)
    const [id_baiviet, setId_baiviet] = useState(null)
    const [value_lienket, setValue_lienket] = useState('')

    useEffect(()=>{
        const reset_data = ()=>{
            setCheck_con(false)
            setId_baiviet(null)
            setValue_lienket('')
        }
        reset_data()
    },[create])

    function convert(str) {
        var convertTable = {
            'à': 'a', 'á': 'a', 'ả': 'a', 'ã': 'a', 'ạ': 'a',
            'ă': 'a', 'ằ': 'a', 'ắ': 'a', 'ẳ': 'a', 'ẵ': 'a', 'ạ': 'a',
            'â': 'a', 'ầ': 'a', 'ấ': 'a', 'ẩ': 'a', 'ẫ': 'a', 'ậ': 'a',
            'đ': 'd',
            'è': 'e', 'é': 'e', 'ẻ': 'e', 'ẽ': 'e', 'ẹ': 'e',
            'ê': 'e', 'ề': 'e', 'ế': 'e', 'ể': 'e', 'ễ': 'e', 'ệ': 'e',
            'ì': 'i', 'í': 'i', 'ỉ': 'i', 'ĩ': 'i', 'ị': 'i',
            'ò': 'o', 'ó': 'o', 'ỏ': 'o', 'õ': 'o', 'ọ': 'o',
            'ô': 'o', 'ồ': 'o', 'ố': 'o', 'ổ': 'o', 'ỗ': 'o', 'ộ': 'o',
            'ơ': 'o', 'ờ': 'o', 'ớ': 'o', 'ở': 'o', 'ỡ': 'o', 'ợ': 'o',
            'ù': 'u', 'ú': 'u', 'ủ': 'u', 'ũ': 'u', 'ụ': 'u',
            'ư': 'u', 'ừ': 'u', 'ứ': 'u', 'ử': 'u', 'ữ': 'u', 'ự': 'u',
            'ỳ': 'y', 'ý': 'y', 'ỷ': 'y', 'ỹ': 'y', 'ỵ': 'y',
            'À': 'A', 'Á': 'A', 'Ả': 'A', 'Ã': 'A', 'Ạ': 'A',
            'Ă': 'A', 'Ằ': 'A', 'Ắ': 'A', 'Ẳ': 'A', 'Ẵ': 'A', 'Ặ': 'A',
            'Â': 'A', 'Ầ': 'A', 'Ấ': 'A', 'Ẩ': 'A', 'Ẫ': 'A', 'Ậ': 'A',
            'Đ': 'D',
            'È': 'E', 'É': 'E', 'Ẻ': 'E', 'Ẽ': 'E', 'Ẹ': 'E',
            'Ê': 'E', 'Ề': 'E', 'Ế': 'E', 'Ể': 'E', 'Ễ': 'E', 'Ệ': 'E',
            'Ì': 'I', 'Í': 'I', 'Ỉ': 'I', 'Ĩ': 'I', 'Ị': 'I',
            'Ò': 'O', 'Ó': 'O', 'Ỏ': 'O', 'Õ': 'O', 'Ọ': 'O',
            'Ô': 'O', 'Ồ': 'O', 'Ố': 'O', 'Ổ': 'O', 'Ỗ': 'O', 'Ộ': 'O',
            'Ơ': 'O', 'Ờ': 'O', 'Ớ': 'O', 'Ở': 'O', 'Ỡ': 'O', 'Ợ': 'O',
            'Ù': 'U', 'Ú': 'U', 'Ủ': 'U', 'Ũ': 'U', 'Ụ': 'U',
            'Ư': 'U', 'Ừ': 'U', 'Ứ': 'U', 'Ử': 'U', 'Ữ': 'U', 'Ự': 'U',
            'Ỳ': 'Y', 'Ý': 'Y', 'Ỷ': 'Y', 'Ỹ': 'Y', 'Ỵ': 'Y'
        };
    
        return str.replace(/[àáảãạăằắẳẵặâầấẩẫậđèéẻẽẹêềếểễệìíỉĩịòóỏõọôồốổỗộơờớởỡợùúủũụưừứửữựỳýỷỹỵÀÁẢÃẠĂẰẮẲẴẶÂẦẤẨẪẬĐÈÉẺẼẸÊỀẾỂỄỆÌÍỈĨỊÒÓỎÕỌÔỒỐỔỖỘƠỜỚỞỠỢÙÚỦŨỤƯỪỨỬỮỰỲÝỶỸỴ]/g, function(match) {
            return convertTable[match];
        });
    }

    const handleOchange = (e)=>{
        const {name, value} = e.target
        setData({...data, [name]:value})
    }

    const handleSubmit = async ()=>{
        const today = new Date()
        const formattedDate = today.toISOString().split('T')[0];
        const link = convert(data.name.toLocaleLowerCase().split(' ').filter(function(item) {
            return item !== '-';
        }).join('-'))

        try {
            const res = await axios.post(`/api/category`, {data, date_created: formattedDate, link: link, check_con: check_con ? 1 : 0, id_baiviet: id_baiviet}, {
                headers: {Authorization: token}
            })

            if(res) {
                setCallback(!callback)
                setData(dataDefault)
                alert(res.data.msg)
                setCheck_con(false)
                setId_baiviet(null)
            }
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    console.log(value_lienket)

    return (
        <div className='w-full pb-[30px]'>
            <div className='w-[400px] h-auto mx-auto bg-white rounded-lg shadow-lg p-[20px] border-[1px] border-[#ddd]'>
                <p className='text-center pb-[5px] text-[15px] mb-[10px] font-bold'>Nhập thông tin thể loại</p>
                <div className='my-[10px]'>
                    <span className='text-[15px] pb-[10px] font-bold'>Không có danh mục con:</span>
                    <input type='checkbox' name='check_con' value={check_con} checked={check_con} onChange={(e)=>setCheck_con(!check_con)} 
                        className='w-[18px] h-[18px] ml-[10px] cursor-pointer'/>
                </div>

                <select name='id_cate' value={value_lienket} onChange={(e)=>setValue_lienket(e.target.value)} 
                    className={`${check_con?'':'hidden'} w-full h-[40px] border-[1px] border-[#ccc] rounded-md shadow-md px-[14px]`}>
                    <option hidden>Chọn loại liên kết</option>
                    <option value={'1'}>Liên kết danh mục</option>
                    <option value={'2'}>Liên kết bài viết</option>
                </select>
                
                <input type="text" name="name" placeholder='Tên danh mục' value={data.name} onChange={handleOchange}
                    className='w-full h-[40px] border-[1px] border-[#ccc] mt-[20px] px-[15px] rounded-md shadow-md mx-auto' /> <br/>
                
                <p className='mt-[20px] text-[13px] font-bold'>Thuộc danh mục:</p>
                <select name='id_cate' value={data.id_cate} onChange={handleOchange} 
                    className='w-full h-[50px] border-[1px] border-[#ccc] rounded-md shadow-md px-[14px]'>
                    <option hidden>Chọn danh mục con</option>
                    {
                        category_child.map(c=>{
                            return <option value={c.id} key={c.id}>{c.name}</option>
                        })
                    }
                </select>

                <input type="text" name="" placeholder='Nhập ID bài viết' value={id_baiviet} onChange={(e)=>setId_baiviet(e.target.value)}
                    className={`${value_lienket==='2'?'':'hidden'} w-full h-[40px] border-[1px] border-[#ccc] mt-[20px] px-[15px] rounded-md shadow-md mx-auto`} />
                
                <Button data={'Thêm'} func={handleSubmit} color={'bg-[#3399FF]'} />
            </div>
        </div>
    )
}
