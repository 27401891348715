import React, {useContext} from 'react'
import { GlobalState } from '../../../GlobalState';
import { LuMenuSquare } from "react-icons/lu";
import bg from '../../../img/bg-admin.jpg'
import bg1 from '../../../img/bg-admin1.jpg'

export default function Background({changeState}) {
  const state = useContext(GlobalState)
  const [isMobile] = state.responsive.isMobile

  const handleClick = ()=>{
    changeState(true)
  }
  
  return (
    <>
      <div className='w-full h-screen flex items-center justify-center relative'>
        <LuMenuSquare size={'35px'} color='#777' className={`${isMobile?'':'hidden'} absolute top-[10px] left-[10px]`}
          onClick={handleClick}/>
          {
            isMobile ? 
            <img src={bg1} alt='ảnh nền' className={`w-full h-full object-cover mx-auto`} />
            :
            <img src={bg} alt='ảnh nền' className={`w-full h-full object-cover mx-auto`} />
          }
          <p className={`${isMobile?'left-[20px]':'left-[150px]'} text-[40px] text-[#777] absolute top-[150px]`}><i><b>Chào mừng bạn !</b></i></p>
      </div>
    </>
  )
}
