import React, {useContext, useState, useEffect} from 'react'
import { GlobalState } from '../../../GlobalState'
import { Link } from 'react-router-dom'
import handleIncreaseQuality from '../../utils/HandleIncrease'
import QRCode from 'qrcode.react';

const CSS_design = {
    span1: 'text-[15px] font-bold ml-[20px]',
    span2: 'text-[13px]'
}

export default function Section3() {
    const state = useContext(GlobalState)
    const [utils] = state.utilsAPI.utils
    const [qr_code] = state.qr_codeAPI.qr_code
    const [cluster] = state.clusterAPI.cluster
    const [isMobile] = state.responsive.isMobile

    const [hotline, setHotline] = useState(null)
    const [email, setEmail] = useState(null)
    const [address, setAddress] = useState(null)
    const [manage, setManage] = useState(null)
    const [chairperson, setchairperson] = useState(null)

    const [noImage, setnoImage] = useState('')
    const [image_new, setImage_new] = useState(null)

    useEffect(()=>{
        const getUtiils = ()=>{
            utils.forEach(el=>{
                if(el.id===1){
                    setHotline(el.hotline)
                    setEmail(el.email)
                    setAddress(el.address)
                    setManage(el.manage)
                    setchairperson(el.chairperson)
                    setnoImage(el.logo)
                }
            })
        }
        getUtiils()
    },[utils])

    useEffect(()=>{
        if(noImage){
            handle_increase()
        }
    },[noImage])

    const handle_increase = async ()=>{
        try {
            const newImage = await handleIncreaseQuality(noImage)
            setImage_new(newImage)
            
        } catch (err) {
            console.error('Error:', err)
        }
    }

  return (
    <div className={`${isMobile?'mr-[-10px]':''} w-full h-auto mx-auto py-[20px] mt-[30px] flex items-center bg-[#58585A] text-white border-b-[8px] border-[#1976D2]`}>
        <div className={`${isMobile?'flex-wrap':''} max-w-[1200px] mx-auto flex items-center`}>
            <div className={`${isMobile?'w-full flex-wrap':'w-[40%]'} h-full flex items-center`}>
                <img src={image_new} alt='logo' className={`${isMobile?'mx-auto mb-[10px]':''} w-[100px] bg-white rounded-[50%]`}/>
                <div>
                    <p className={CSS_design.span1}>{manage}</p>
                    <p><span className={CSS_design.span1}>Email:</span> <span className={CSS_design.span2}>{email}</span></p>
                    <p><span className={CSS_design.span1}>Điện thoại liên hệ:</span> <span className={CSS_design.span2}>{hotline}</span></p>
                </div>
            </div>

            <div className={`${isMobile?'w- mt-[10px]':'w-[40%]'} h-auto mx-[20px]`}>
                <div className='w-full h-auto text-center'>
                    <p><span className={`${CSS_design.span1} ml-0`}>Chủ tịch Hội:</span> <span className={CSS_design.span2}>{chairperson}</span></p>
                    <p><span className={`${CSS_design.span1} ml-0`}>Địa chỉ:</span> <span className={CSS_design.span2}>{address}</span></p>
                </div>
                <p className='text-center py-[10px]'>Quét mã liên kết trang</p>
                <div className='flex flex-wrap justify-center w-full mx-auto overflow-x-hidden'>
                    {
                        qr_code.map(qr=>{
                            return <div className='w-[100px] mt-[5px]' key={qr.id}>
                                {
                                    qr.check_image===2 ?
                                    qr.image && <QRCode value={qr.image} size={40} className='mx-auto border-[1px] border-white rounded-sm' />
                                    :
                                    <img src={qr.image} alt='hình ảnh QR' className='w-[40px] h-[40px] mx-auto rounded-sm' />
                                }
                                <p className='text-[9px] text-center'>{qr.title}</p>
                            </div>
                        })
                    }
                </div>
            </div>

            <div className={`${isMobile?'w-full mt-[10px]':'w-[20%]'} h-auto text-center px-[20px]`}>
                <p className='font-bold'>BÀI VIẾT THEO CỤM</p>
                {
                    cluster.map((c,i)=>{
                        if((c.id!==0)&&(i<=3)){
                            return <Link to={`/danh-muc/${c.link}/${c.id}`} key={c.id}>
                                <p className='hover:text-[#ed0678] border-b-[1px] border-[#ccc] py-[5px]'>{c.name}</p>
                            </Link>
                        }
                    })
                }
            </div>
        </div>
    </div>
  )
}
