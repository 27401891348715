import React, {useState, useEffect} from 'react'
import axios from 'axios'

export default function NewsVideosAPI() {
    const [newsVideos, setNewsVideos] = useState([])
    const [callback, setCallback] = useState(false)

    useEffect(() =>{
        const getNewsVideos = async () =>{
            const res = await axios.get('/api/news-videos')
            setNewsVideos(res.data)
        }
        getNewsVideos()
    },[callback])

    return {
        newsVideos: [newsVideos, setNewsVideos],
        callback: [callback, setCallback]
    }
}
