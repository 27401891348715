import React, {useContext} from 'react';
import { Routes, Route } from 'react-router-dom';
import {GlobalState} from '../GlobalState';

import Home from './mainPage/home/Home';
import Login from './admin/login/Login';
import HomePageAdmin from './admin/homePageAdmin/HomePageAdmin';
import DetailNews from './mainPage/detailNews/DetailNews';
import CategoryNews from './mainPage/category/CategoryNews';
import Contact from './mainPage/contact/Contact';
import Search from './mainPage/search/Search';

export default function Page() {
  const state = useContext(GlobalState)
  const [isLogged] = state.accountAPI.isLogged

  return (
    <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/danh-muc/:link/:id" element={<CategoryNews/>} />
        <Route path="/chi-tiet-bai-viet/:id" element={<DetailNews/>} />
        <Route path="/lien-he" element={<Contact/>} />
        <Route path="/tim-kiem" element={<Search/>} />

        <Route path="/admin" element={isLogged?<HomePageAdmin/>:<Login/>} />

        <Route path="*" element={<></>} />
    </Routes>
  )
}
