import React, {useState, useEffect} from 'react'
import axios from 'axios'

export default function NewsAPI() {
    const [News, setNews] = useState([])
    const [News_Filter, setNews_Filter] = useState([])
    const [callback, setCallback] = useState(false)

    useEffect(() =>{
        const getNews = async () =>{
            const res = await axios.get('/api/news')
            setNews(res.data)
        }
        getNews()
    },[callback])

    return {
        news: [News, setNews],
        news_filter: [News_Filter, setNews_Filter],
        callback: [callback, setCallback]
    }
}
