import React, {useContext, useState} from 'react'
import { GlobalState } from '../../../GlobalState'
import axios from 'axios'
import Button from '../../utils/Button'
import { LuMenuSquare } from "react-icons/lu";

const CSS_Input = {
    item: 'w-full h-[50px] border-[1px] border-[#ccc] rounded-md px-[20px] mt-[20px] shadow-md'
}

export default function ChangePass({changeState}) {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [pass_old, setpass_old] = useState('')
    const [pass_new, setpass_new] = useState('')
    const [re_pass_new, setre_pass_new] = useState('')
    const [isMobile] = state.responsive.isMobile

    const handleSubmit = async ()=>{
        try {
            const res = await axios.put(`/api/change_pass`, {pass_old: pass_old, pass_new: pass_new, re_pass_new: re_pass_new}, {
                headers: {Authorization: token}
            })
            alert(res.data.msg)
            Logout()
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const Logout = async ()=>{
        await axios.get('/api/logout')
        localStorage.removeItem('Login')
        window.location.href = "/admin";
    }

    const handleClick = ()=>{
        changeState(true)
    }

  return (
    <div className={`w-full h-screen bg-[#f6f6f6] `}>
        <LuMenuSquare size={'35px'} color='#777' className={`${isMobile?'':'hidden'} absolute top-[10px] left-[10px]`}
          onClick={handleClick}/>
        <p className='text-center text-[20px] font-bold text-[#444] pt-[50px]'>ĐỔI MẬT KHẨU</p>
        <div className={`${isMobile?'w-full':'w-[50%]'} h-auto mx-auto`}>
            <input type='password' name='pass-old' placeholder='Nhập mật khẩu hiện tại...' value={pass_old} onChange={(e)=>setpass_old(e.target.value)}
                className={CSS_Input.item} />
            <input type='password' name='pass-new' placeholder='Nhập mật khẩu mới...' value={pass_new} onChange={(e)=>setpass_new(e.target.value)}
                className={CSS_Input.item} />
            <input type='password' name='re-pass-new' placeholder='Nhập lại mật khẩu mới...' value={re_pass_new} onChange={(e)=>setre_pass_new(e.target.value)}
                className={CSS_Input.item} />
        </div>
        <Button data={'Cập nhật'} func={handleSubmit} color={'bg-[#0099ff]'} />
    </div>
  )
}
