import React, {useState, useContext, useEffect} from 'react'
import { GlobalState } from '../../../GlobalState'
import Button from '../../utils/Button'
import axios from 'axios'

const dataDefault = {
    name: '',
    link: '',
    category: null
}

export default function UpdateMenu({func, id, checkUpdate}) {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [category] = state.categoryAPI.category
    const [menu] = state.menuAPI.menu
    const [callback, setCallback] = state.menuAPI.callback

    const [linkCate, setLinkCate] = useState('')
    const [name, setName] = useState('')
    const [cateId, setCateId] = useState(0)
    const [cateId_checkmenu, setCateId_checkmenu] = useState(null)
    const [checkExitsMenu, setCheckExitsMenu] = useState(false)
    const [menu_cate, setMenu_cate] = useState([])

    useEffect(()=>{
        const getMenu = ()=>{
            let menu_cate = []
            menu.forEach(el=>{
                if(el.id===id){
                    setName(el.name)
                    setCateId(el.category)
                    setLinkCate(el.link)
                    setCateId_checkmenu(el.category)
                }
                menu_cate.push(el.category)
            })
            setMenu_cate(menu_cate)
        }
        getMenu()
    },[checkUpdate])

    useEffect(()=>{
        const getMenu = ()=>{
            category.forEach(el=>{
                if(el.id===parseInt(cateId)){
                    setLinkCate(el.link)
                }
            })
        }
        getMenu()
    },[cateId])

    useEffect(()=>{
        const checkMenu = ()=>{
            let arr = []
            menu_cate.forEach(el=>{
                if(el===cateId_checkmenu){
                    arr.push(el)
                }
            })

            if(arr.length>1){
                setCheckExitsMenu(true)
            }
        }
        checkMenu()
    },[menu_cate])

    const handleSubmit = async ()=>{
        try {
            const res = await axios.put(`/api/menu/${id}`, {name: name, link: linkCate, category: cateId}, {
                headers: {Authorization: token}
            })
            updateCheckmenu()
            if(!checkExitsMenu){
                updateCheckmenu2()
            }
            alert(res.data.msg)
            setCallback(!callback)

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const updateCheckmenu = async ()=>{
        try {
            if(cateId_checkmenu!==parseInt(cateId)){
                await axios.put(`/api/category-menu/${cateId}`,{value: 1},{
                    headers: {Authorization: token}
                })
            }
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const updateCheckmenu2 = async ()=>{
        try {
            if(cateId_checkmenu!==parseInt(cateId) && !checkExitsMenu){
                await axios.put(`/api/category-menu/${cateId_checkmenu}`,{value: 0},{
                    headers: {Authorization: token}
                })
            }
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleBack = ()=>{
        func(false)
    }

    useEffect(()=>{
        const scroll = () =>{
            function topFunction() {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
            topFunction()
        }
        scroll()
    },[id])

    // console.log(cateId_checkmenu, cateId)

  return (
    <>
        <div className='w-full h-auto absolute z-[100] top-[25%] translate-y-[-25%]'>
            <div className='w-[60%] h-auto bg-[#eee] p-[20px] mx-auto'>
                <p className='text-center font-bold text-[20px] text-[#333] mb-[10px]'>QUẢN LÝ CẬP NHẬT MENU</p>
                <input type='text' name='name' placeholder='Nhập tên' value={name} onChange={(e)=>setName(e.target.value)}
                    className='block w-full h-[50px] border-[1px] border-[#ccc] rounded-md shadow-md px-[15px]' />
                <select name='category' value={cateId} onChange={(e)=>setCateId(e.target.value)} 
                    className='w-full h-[50px] border-[1px] border-[#ccc] rounded-md shadow-md mt-[20px] px-[15px]'>
                    <option value={0}>Không</option>
                    {
                        category.map(c=>{
                            if(c.id===cateId){
                                return <option hidden value={c.id} key={c.id}>{c.name}</option>
                            }
                        })
                    }
                    {
                        category.map(c=>{
                            return <option value={c.id} key={c.id}>{c.name}</option>
                        })
                    }
                </select>
                <div className='w-full'>
                    <Button data={'Cập nhật'} func={handleSubmit} color={'bg-[#0099FF]'} />
                    <Button data={'Quay lại'} func={handleBack} color={'bg-[#bbb]'} />
                </div>
            </div>
        </div>

        <div className='w-full h-screen bg-black opacity-[0.4] fixed top-0'></div>
    </>
  )
}
