import React, {useEffect, useState, useContext, useRef} from 'react'
import { GlobalState } from '../../../GlobalState'
import axios from 'axios'
import Button from '../../utils/Button'
import UpadteNews from './UpadteNews'
import noImage from '../../../img/no-image.jpg'
import Loading from '../../utils/Loading'
import { IoRefresh } from "react-icons/io5";
import { FaAngleDown, FaAngleRight } from "react-icons/fa6";
import { LuMenuSquare } from "react-icons/lu";

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import ImageCompressor from 'image-compressor.js'

import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { pdfjs } from 'pdfjs-dist';

const initialState = {
  title:'',
  description:'',
  images:'',
  author:'',
  date_created:'',
  cluster: null,
  video:'',
  id_file: null
}

const CSS_Input = {
    item: 'w-full h-[50px] border-[1px] border-[#ccc] rounded-md px-[20px] shadow-md',
    table: 'border-[1px] border-[#bbb] py-[15px] px-[8px] text-center text-[14px]',
    area: 'w-full h-[100px] border-[1px] border-[#ccc] rounded-md px-[20px] py-[15px] mt-[20px] shadow-md'
}

export default function News({changeState}) {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [data, setData] = useState(initialState)
    const [news, setNews] = useState([])
    const [callback, setCallback] = useState(false)
    const [category] = state.categoryAPI.category
    const [cluster] = state.clusterAPI.cluster
    const [category_child1] = state.category_childAPI.category_child1
    const [answers] = state.answersAPI.answers
    const [isMobile] = state.responsive.isMobile

    const editorRef = useRef(null);

    const [selectedFile, setSelectedFile] = useState(null)
    const [isCreate, setIsCreate] = useState(false)
    const [cate, setCate] = useState(0)
    const [checkUpdate, setCheckUpdate] = useState(false)
    const [idNews, setIdNews] = useState(0)
    const [content, setContent] = useState('')
    const [dataCluster, setDataCluster] = useState(0)
    const [dataAnswers, setDataAnswers] = useState(0)
    const [check_Ans_Cate, setCheck_Ans_Cate] = useState(false)
    const [limit, setLimit] = useState(10)
    const [loading, setLoading] = useState(false)
    const [danhmuc_chinh, setdanhmuc_chinh] = useState(0)

    const [valueSearch, setValueSearch] = useState('')
    const [dataSearch, setdataSearch] = useState([])
    const [filterData, setFilterData] = useState('')
    const [checkFilter, setCheckFilter] = useState(false)
    const [filterAns, setFilterAns] = useState('')
    const [filterCluster, setFilterCluster] = useState(0)

    const [check_news_file, setCheck_news_file] = useState(false)

    useEffect(()=>{
        getDataNews()
    },[limit, callback])

    useEffect(()=>{
        if(dataSearch){
            setLoading(false)
        }
    },[dataSearch])

    const getDataNews = async ()=>{
        try {
            const res = await axios.get(`/api/get-news-list-admin/${limit}`,{
                headers: {Authorization: token}
            })
            setNews(res.data)
            setLoading(false)
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleUpload = (e) =>{
      const {name, value} = e.target
      setData({...data,[name]:value})
    }

    const handleImage = (value) =>{
    //   const file = e.target.files[0]
      const file = value
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFile(reader.result)
        data.images=(reader.result)
      }
      reader.readAsDataURL(file)
    }

    function CompressImage(e) {
        const file = e.target.files[0];
        
        // Kiểm tra kích thước của tệp trước khi nén
        const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB
        if (file.size > maxSizeInBytes) {
            alert('Kích thước file phải dưới 1MB.')
            return
        }

        var compressor = new ImageCompressor()
    
        compressor.compress(file, {
            quality: file.size < 50000 ? 0.8 : file.size >= 50000 && file.size < 400000 ? 0.4 : file.size >= 400000 && file.size < 800000 ? 0.3 : file.size >= 800000 ? 0.2: null,
            // maxWidth: 100, 
            // maxHeight: 100,
            mimeType: 'image/jpeg',
            success(result) {
                handleImage(result);
            },
            error(e) {
                console.log(e.message);
            },
        })
    }

    const handlesubmit = async () => {
      const today = new Date()
      const formattedDate = today.toISOString().split('T')[0];
      let safeContent = content.replace(/'/g, "\\'");
      
      try {
        const res = await axios.post(`/api/news`, {data, category: cate, date_created: formattedDate, content: safeContent, cluster: dataCluster, answers: dataAnswers, danhmuc_chinh: danhmuc_chinh}, {
          headers: {Authorization: token}
        })
        if(res) {
            alert(res.data.msg)
            setCallback(!callback)
        }
        
      } catch (err) {
        alert(err.response.data.msg)
      }
    }

    const handleCreateNews = ()=>{
        setIsCreate(!isCreate)
    }

    const handleUpdate = (id)=>{
        setCheckUpdate(!checkUpdate)
        setIdNews(id)
    }

    const handleDelete = async (id)=>{
        if(window.confirm("Lưu ý: bài viết bị xóa sẽ không thể phục hồi.")) {
            try {
                const res = await axios.delete(`/api/news/${id}`,{
                    headers: {Authorization: token}
                })
                if(res) {
                    setCallback(!callback)
                    return alert(res.data.msg)
                }
                
            } catch (err) {
                alert(err.response.data.msg)
            }
        }
    }

    const handleEditorChange = (content)=>{
        setContent(content)
    }

    const handleAnsCate = ()=>{
        setCheck_Ans_Cate(!check_Ans_Cate)
        setCate(null)
    }

    const handleNewsFile = ()=>{
        setCheck_news_file(!check_news_file)
    }

    const handleLoadMore = ()=>{
        setLimit(limit + 5)
        setLoading(true)
    }

    const search = async ()=>{
        setLoading(true)
        try {
            const res = await axios.post(`/api/search-news`, {value: valueSearch})
            setdataSearch(res.data)
            
        } catch (err) {
            alert(err.response.data.msg)
            if(err){
                setLoading(false)
            }
        }
    }

    const cancelSearch = ()=>{
        setdataSearch([])
        setValueSearch('')
        setLimit(10)
        setCallback(!callback)
        setFilterData('')
        setCheckFilter(false)
        setFilterAns('')
    }

    const filterNews = async (value)=>{
        setLoading(true)
        setFilterData(value)
        try {
            const res = await axios.post(`/api/filter-news`, {value: value}, {
                headers: {Authorization: token}
            })
            setdataSearch(res.data)
            setCheckFilter(false)
            
        } catch (err) {
            alert(err.response.data.msg)
            if(err){
                setLoading(false)
            }
        }
        // console.log(value)
    }

    const filterNewsAns = async (value)=>{
        setLoading(true)
        setFilterAns(value)
        try {
            const res = await axios.post(`/api/filter-news-by-answers`, {value: value}, {
                headers: {Authorization: token}
            })
            setdataSearch(res.data)
            setCheckFilter(false)
            
        } catch (err) {
            alert(err.response.data.msg)
            if(err){
                setLoading(false)
            }
        }
        // console.log(value)
    }

    const filterNewsCluster = async (value)=>{
        setLoading(true)
        setFilterAns(value)
        try {
            const res = await axios.post(`/api/filter-news-by-cluster`, {value: value}, {
                headers: {Authorization: token}
            })
            setdataSearch(res.data)
            setCheckFilter(false)
            
        } catch (err) {
            alert(err.response.data.msg)
            if(err){
                setLoading(false)
            }
        }
        // console.log(value)
    }

    const filter_danhmucchinh = async (value)=>{
        setLoading(true)
        setFilterAns(value)
        try {
            const res = await axios.post(`/api/filter-news-by-danhmucchinh`, {value: value}, {
                headers: {Authorization: token}
            })
            setdataSearch(res.data)
            setCheckFilter(false)
            
        } catch (err) {
            alert(err.response.data.msg)
            if(err){
                setLoading(false)
            }
        }
        // console.log(value)
    }

    const deleteAll = async ()=>{
        if(window.confirm('Lưu ý: Dữ liệu bị xóa sẽ không thể phục hồi.')){
            try {
                const res = await axios.delete(`/api/news`,{
                    headers: {Authorization: token}
                })
                alert(res.data.msg)
                
            } catch (err) {
                alert(err.response.data.msg)
            }
        }
    }

    const handleImageUploadBefore = (files, info, uploadHandler) => {
        if (!files || !files[0]) {
            console.error('Không có hình ảnh được chọn.');
            return;
        }

        const file = files[0]

        const maxSizeInBytes = 1 * 1024 * 1024  // Giới hạn kích thước file, ví dụ 1MB
        if (file.size > maxSizeInBytes) {
            alert('Kích thước file quá lớn. Vui lòng chọn file nhỏ hơn.');
            return false;
        }

        uploadHandler(file)
        return true;
    }

    const handleClick = ()=>{
        changeState(true)
    }

    // console.log(blobURL)

  return (
    <div className={`${isMobile?``:'w-full h-screen relative'} bg-white`}>
        <LuMenuSquare size={'35px'} color='#777' className={`${isMobile?'':'hidden'} absolute top-[10px] left-[10px]`}
          onClick={handleClick}/>
        <p className='font-bold text-[20px] text-center text-[#333] mt-[30px]'>QUẢN LÝ BÀI VIẾT</p>
        <div className={`${isMobile?'flex-wrap':''} w-full flex mb-[20px] ${checkUpdate ? 'hidden' : ''}`}>
            <div className='w-auto h-auto'>
                <button onClick={deleteAll} className={`${isCreate?'hidden':''} bg-[#990000] hover:bg-[#9d3838] text-[13px] text-white font-bold mt-[25px] px-[10px] py-[6px] rounded-sm shadow-sm`}>Xóa tất cả</button>
            </div>
            
            <div className='w-auto h-auto ml-[20px]'>
                {/* <Button data={!isCreate ? 'Thêm bài viết' : 'Danh sách bài viết'} func={handleCreateNews} color={'bg-[#3399FF]'} /> */}
                <button onClick={handleCreateNews} className='bg-[#3399FF] hover:bg-[#54a3f3] text-[13px] text-white font-bold mt-[25px] px-[10px] py-[6px] rounded-sm shadow-sm'>
                    {!isCreate ? 'Thêm bài viết' : 'Danh sách bài viết'}
                </button>
            </div>

            <div className={`${isCreate?'hidden':''} ${isMobile?'w-full flex-wrap':'w-[60%]'} h-auto flex items-center justify-center mt-[20px]`}>
                <IoRefresh size={'33px'} className='cursor-pointer border-[1px] border-[#ccc] shadow-sm hover:bg-[#eee] rounded-sm px-[5px]' onClick={cancelSearch} />
                <div className='w-auto h-auto ml-[20px]'>
                    <input type='text' value={valueSearch} placeholder='Nhập ID hoặc tên bài viết...' onChange={(e)=>setValueSearch(e.target.value)}
                        className='w-auto h-[35px] text-[14px] border-[1px] border-[#ccc] shadow-md rounded-sm px-[15px]' />
                    <button onClick={search} className='bg-[#777] hover:bg-[#888] text-[#fff] ml-[1px] shadow-md px-[10px] py-[5.8px] rounded-sm'>Tìm kiếm</button>
                </div>

                <div className='w-auto h-auto relative'>
                    <button onClick={()=>setCheckFilter(!checkFilter)}
                        className={`${isMobile?'ml-[-100px] mt-[10px]':'ml-[30px]'} border-[1px] border-[#ccc] px-[10px] py-[5px] rounded-sm text-[14px] flex items-center shadow-md`}><span>Lọc theo thể loại</span> <span className='ml-[15px] mt-[3px]'>{checkFilter?<FaAngleDown size={'14px'}/>:<FaAngleRight size={'14px'}/>}</span></button>
                    {
                        checkFilter ?
                        <>
                            <select value={filterData} onChange={(e)=>filterNews(e.target.value)}
                                className={`${isMobile?'ml-[-100px]':'ml-[30px]'} absolute top-[110%] w-[155px] text-[14px] block border-[1px] border-[#ccc] h-[35px] rounded-sm shadow-md px-[10px] cursor-pointer`}>
                                <option hidden>Danh mục cấp 1</option>
                                {
                                    category.map(c=>{
                                        if(c.id!==0){
                                            return <option key={c.id} value={c.id}>{c.name}</option>
                                        }
                                    })
                                }
                            </select>

                            <select value={filterCluster} onChange={(e)=>filterNewsCluster(e.target.value)}
                                className={`${isMobile?'ml-[-100px] top-[205%]':'ml-[30px] top-[230%]'} absolute w-[155px] text-[14px] block border-[1px] border-[#ccc] h-[35px] rounded-sm shadow-md px-[10px] cursor-pointer`}>
                                <option hidden>Danh mục cấp 2</option>
                                {
                                    cluster.map(c=>{
                                        if(c.id!==0){
                                            return <option key={c.id} value={c.id}>{c.name}</option>
                                        }
                                    })
                                }
                            </select>

                            <select value={filterAns} onChange={(e)=>filterNewsAns(e.target.value)}
                                className={`${isMobile?'ml-[-100px] top-[300%]':'ml-[30px] top-[350%]'} absolute w-[155px] text-[14px] block border-[1px] border-[#ccc] h-[35px] rounded-sm shadow-md px-[10px] cursor-pointer`}>
                                <option hidden>Thể loại giải đáp</option>
                                {
                                    answers.map(c=>(
                                        <option key={c.id} value={c.id}>{c.name}</option>
                                    ))
                                }
                            </select>

                            <select value={filterAns} onChange={(e)=>filter_danhmucchinh(e.target.value)}
                                className={`${isMobile?'ml-[-100px] top-[397%]':'ml-[30px] top-[470%]'} absolute w-[155px] text-[14px] block border-[1px] border-[#ccc] h-[35px] rounded-sm shadow-md px-[10px] cursor-pointer`}>
                                <option hidden>Danh mục chính</option>
                                {
                                    category_child1.map(c=>{
                                        if(c.id!==0){
                                            return <option key={c.id} value={c.id}>{c.name}</option>
                                        }
                                    })
                                }
                            </select>
                        </> : <></>
                    }
                </div>
            </div>
        </div>

        <div className={`w-full flex justify-start mb-[20px] ${!checkUpdate ? 'hidden' : ''}`}>
            <Button data={'Danh sách bài viết'} func={()=>handleUpdate(0)} color={'bg-[#3399FF]'} />
        </div>

        <div className={`w-full pb-[30px] ${!isCreate || checkUpdate ? 'hidden' : ''}`}>
            <div className={`${isMobile?'w-full flex-wrap':'w-[80%]'} mx-auto flex justify-around items-center`}>
                <div>
                    <p className='text-[15px] pb-[10px] font-bold'>Chọn ảnh đại diện:</p>
                    <input type="file" name="file" id="file_up" onChange={CompressImage} onClick={e => (e.target.value = null)}
                        className='h-[40px]' />

                    <div className='my-[30px]'>
                        <span className='text-[15px] pb-[10px] font-bold'>Loại bài viết giải đáp:</span>
                        <input type='checkbox' name='answers' value={check_Ans_Cate} onChange={(e)=>handleAnsCate(e.target.value)} 
                            className='w-[18px] h-[18px] ml-[10px]'/>
                    </div>

                    <div className='my-[30px]'>
                        <span className='text-[15px] pb-[10px] font-bold'>Loại bài viết File:</span>
                        <input type='checkbox' name='news_file' value={check_news_file} onChange={(e)=>handleNewsFile(e.target.value)} 
                            className='w-[18px] h-[18px] ml-[10px]'/>
                    </div>
                </div>
                <div className='w-[300px] h-[194px] bg-slate-200'>
                    <img src={selectedFile?selectedFile:noImage} alt="Ảnh đại diện" style={{ maxWidth: '300px' }}
                        className='w-full aspect-[17/11] object-cover' />
                </div>
            </div>

            <div className={`${isMobile?'w-full':'w-[80%]'} h-auto mx-auto`}>
                <p className='mb-[5px] font-bold'>Tiêu đề:</p>
                <input type="text" name="title" id="title" placeholder='Tiêu đề' onChange={handleUpload}
                    className={CSS_Input.item} /> <br/>

                <p className={!check_news_file ? `mb-[5px] mt-[20px] font-bold` : 'hidden'}>Mô tả:</p>
                <input type="text" name="description" id="description" placeholder='Mô tả' onChange={handleUpload}
                    className={CSS_Input.item} style={{display:check_news_file?'none':''}} /> <br/>

                <p className={!check_Ans_Cate ? `mb-[5px] mt-[20px] font-bold` : 'hidden'}>Danh mục chính:</p>
                <select className={!check_Ans_Cate ? `${CSS_Input.item}` : 'hidden'} value={danhmuc_chinh} onChange={(e)=>setdanhmuc_chinh(e.target.value)}> 
                    <option hidden>Chọn danh mục chính</option>
                    {
                        category_child1.map(cate=>{
                            if(cate.id!==0){
                                return <option key={cate.id} value={cate.id}>{cate.name}</option>
                            }
                        })
                    }
                </select>

                <p className={!check_Ans_Cate ? `mb-[5px] mt-[20px] font-bold` : 'hidden'}>Danh mục cấp 1:</p>
                <select className={!check_Ans_Cate ? `${CSS_Input.item}` : 'hidden'} value={cate} onChange={(e)=>setCate(e.target.value)}> 
                    <option hidden>Chọn danh mục cấp 1</option>
                    {
                        category.map(cate=>{
                            return <option key={cate.id} value={cate.id}>{cate.name}</option>
                        })
                    }
                </select>

                {/* loại bài giải đáp */}
                <p className={check_Ans_Cate ? `mb-[5px] mt-[20px] font-bold` : 'hidden'}>Thể loại giải đáp:</p>
                <select className={check_Ans_Cate ? `${CSS_Input.item}` : 'hidden'} value={dataAnswers} onChange={(e)=>setDataAnswers(e.target.value)} style={{display:check_news_file?'none':''}}> 
                    <option hidden>Chọn thể loại giải đáp</option>
                    {
                        answers.map(cate=>{
                            return <option key={cate.id} value={cate.id}>{cate.name}</option>
                        })
                    }
                </select>

                <p className={!check_Ans_Cate ? `mb-[5px] mt-[20px] font-bold` : 'hidden'}>Danh mục cấp 2:</p>
                <select className={!check_Ans_Cate ? `${CSS_Input.item}` : 'hidden'} value={dataCluster} onChange={(e)=>setDataCluster(e.target.value)}> 
                    <option hidden>Chọn danh mục cấp 2</option>
                    {
                        cluster.map(clu=>{
                            return <option key={clu.id} value={clu.id}>{clu.name}</option>
                        })
                    }
                </select>
                
                <p className='mb-[5px] mt-[20px] font-bold'>Tác giả:</p>
                <input type="text" name="author" id="author" placeholder='Tác giả' onChange={handleUpload}
                    className={CSS_Input.item} /> <br/>

                <p className={check_news_file ? `mb-[5px] mt-[20px] font-bold` : 'hidden'}>ID file:</p>
                <input type="text" name="id_file" id="id_file" placeholder='Nhập ID File' onChange={handleUpload}
                    className={CSS_Input.item} style={{display:check_news_file?'':'none'}} /> <br/>

                <p className={!check_news_file ? `mb-[5px] mt-[20px] font-bold` : 'hidden'}>Link video:</p>
                <textarea type='text' name='video' placeholder='Nhập link video' onChange={handleUpload}
                    className={`${CSS_Input.area}`} style={{display:check_news_file?'none':''}} />

                <p className={!check_news_file ? `mb-[5px] mt-[20px] font-bold` : 'hidden'}>Nội dung:</p>
                <div className='w-full h-auto mt-[20px]' style={{display:check_news_file?'none':''}}>
                    <SunEditor
                        placeholder='Nhập nội dung...'
                        ref={editorRef}
                        setOptions={{
                            buttonList: [
                            ['undo', 'redo'],
                            ['font', 'fontSize', 'formatBlock'],
                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                            ['fontColor', 'hiliteColor', 'textStyle'],
                            ['removeFormat'],
                            '/', // Line break
                            ['outdent', 'indent'],
                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                            ['table', 'link', 'image'],
                            ['fullScreen', 'showBlocks', 'codeView'],
                            ['preview']
                            ],
                            callBackSave: (contents) => {
                                console.log(contents);
                            },
                        }}
                        value={content}
                        onChange={handleEditorChange}
                        onImageUploadBefore={handleImageUploadBefore}
                    />
                </div>
                <Button data={'Đăng'} func={handlesubmit} color={'bg-[#3399FF]'} />
            </div>
        </div>

        <div className={isCreate || checkUpdate ? 'hidden' : `${isMobile?'overflow-x-scroll':''} pb-[30px]`}>
            <table className={`m-auto w-full`}>
                <thead>
                    <tr className=''>
                        <th className={CSS_Input.table}>STT</th>
                        <th className={CSS_Input.table}>ID</th>
                        <th className={CSS_Input.table}>Tiêu đề</th>
                        <th className={CSS_Input.table}>Hình ảnh</th>
                        <th className={CSS_Input.table}>Danh mục chính</th>
                        <th className={CSS_Input.table}>Danh mục cấp 1</th>
                        <th className={CSS_Input.table}>Danh mục cấp 2</th>
                        <th className={CSS_Input.table}>Tác giả</th>
                        <th className={CSS_Input.table}>Ngày đăng</th>
                        <th className={CSS_Input.table}>Tùy chỉnh</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dataSearch.length!==0 ?
                        dataSearch.map((n, index)=>{
                            return <tr key={n.id}>
                                <td className={CSS_Input.table}>{index+1}</td>
                                <td className={CSS_Input.table}>{n.id}</td>
                                <td className={`${CSS_Input.table} ${isMobile?'text-[10px]':''} w-[300px]`}>{n.title.toUpperCase()}</td>
                                <td className={CSS_Input.table}><img src={n.images} alt='Ảnh đại diện' 
                                    className='w-[80px] h-auto mx-auto' /></td>
                                <td className={CSS_Input.table}>
                                    {
                                        category_child1.map(c=>{
                                            if(c.id===n.danhmuc_chinh){
                                                return <p key={c.id}>{c.name}</p>
                                            }
                                        })
                                    }
                                </td>
                                <td className={CSS_Input.table}>
                                    {
                                        n.category!==null ?
                                        category.map(c=>{
                                            if(n.category===c.id) {
                                                return <p key={c.id}>{c.name}</p>
                                            }
                                        })
                                        :
                                        answers.map(c=>{
                                            if(n.answers===c.id) {
                                                return <p key={c.id}>{c.name}</p>
                                            }
                                        })
                                    }
                                </td>
                                <td className={CSS_Input.table}>
                                    {
                                        cluster.map(c=>{
                                            if(c.id===n.cluster){
                                                return <p key={c.id}>{c.name}</p>
                                            }
                                        })
                                    }
                                </td>
                                <td className={CSS_Input.table}>{n.author}</td>
                                <td className={CSS_Input.table}>{n.date_created.slice(0,10).split('-').reverse().join('/')}</td>
                                <td className={CSS_Input.table}>
                                    <div className='w-[100px] mx-auto flex justify-around'>
                                        <button onClick={()=>handleUpdate(n.id)} className='bg-[#FFCC33] hover:bg-[#fde18d] py-[3px] px-[10px] rounded-md'>Sửa</button>
                                        <button onClick={()=>handleDelete(n.id)} className='bg-[#CC3300] hover:bg-[#fe8259] text-[#fff] py-[3px] px-[10px] rounded-md'>Xóa</button>
                                    </div>
                                </td>
                            </tr>
                        })
                        :
                        news.map((n, index)=>{
                            return <tr key={n.id}>
                                <td className={CSS_Input.table}>{index+1}</td>
                                <td className={CSS_Input.table}>{n.id}</td>
                                <td className={`${CSS_Input.table} w-[300px]`}>{n.title.toUpperCase()}</td>
                                <td className={CSS_Input.table}><img src={n.images} alt='Ảnh đại diện' 
                                    className='w-[80px] h-auto mx-auto' /></td>
                                <td className={CSS_Input.table}>
                                    {
                                        category_child1.map(c=>{
                                            if(c.id===n.danhmuc_chinh){
                                                return <p key={c.id}>{c.name}</p>
                                            }
                                        })
                                    }
                                </td>
                                <td className={CSS_Input.table}>
                                    {
                                        n.category!==null ?
                                        category.map(c=>{
                                            if(n.category===c.id) {
                                                return <p key={c.id}>{c.name}</p>
                                            }
                                        })
                                        :
                                        answers.map(c=>{
                                            if(n.answers===c.id) {
                                                return <p key={c.id}>{c.name}</p>
                                            }
                                        })
                                    }
                                </td>
                                <td className={CSS_Input.table}>
                                    {
                                        cluster.map(c=>{
                                            if(c.id===n.cluster){
                                                return <p key={c.id}>{c.name}</p>
                                            }
                                        })
                                    }
                                </td>
                                <td className={CSS_Input.table}>{n.author}</td>
                                <td className={CSS_Input.table}>{n.date_created.slice(0,10).split('-').reverse().join('/')}</td>
                                <td className={CSS_Input.table}>
                                    <div className='w-[100px] mx-auto flex justify-around'>
                                        <button onClick={()=>handleUpdate(n.id)} className='bg-[#FFCC33] hover:bg-[#fde18d] py-[3px] px-[10px] rounded-md'>Sửa</button>
                                        <button onClick={()=>handleDelete(n.id)} className='bg-[#CC3300] hover:bg-[#fe8259] text-[#fff] py-[3px] px-[10px] rounded-md'>Xóa</button>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        <div className={isCreate || checkUpdate ? 'hidden' :'w-full pb-[30px]'}>
            <Button data={'Xem thêm'} func={handleLoadMore} color={'bg-[#0099ff]'}/>
        </div>

        {
            checkUpdate ? <UpadteNews id={idNews}/> : <></>
        }
        {
            loading ? <Loading/> : <></>
        }
    </div>
  )
}
