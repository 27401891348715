import React, {useContext, useState, useEffect} from 'react'
import { GlobalState } from '../../../GlobalState'
import {Link} from 'react-router-dom'
import Question from '../../../img/question.jpg'
import Loading from '../../utils/Loading'

export default function Section1() {
  const state = useContext(GlobalState)
  const [answers] = state.answersAPI.answers
  const [news] = state.newsAnswersAPI.newsAnswers
  const [id_Answers, setId_Answers] = state.newsAnswersAPI.id
  const [isMobile] = state.responsive.isMobile

  const [chekcClick, setCheckClick] = useState(false)
  const [id, setId] = useState('')
  const [link, setLink] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    const getDataAns = ()=>{
        answers.forEach((el,i)=>{
            if(i===0){
                setLink(el.link)
                setId(el.id)
            }
        })
    }
    getDataAns()
  },[answers])

  const click = (id,i,link)=>{
    setCheckClick(true)
    setId(id)
    setId_Answers(id)
    setLoading(true)
    setLink(link)
  }

  useEffect(()=>{
    setTimeout(()=>{
        setLoading(false)
    },[500])
  },[loading])

  return (
    <div className={`${isMobile?'ml-[20px]':''} max-w-[1000px] h-auto mx-auto mt-[20px] relative`}>
        <p className='text-[30px] font-bold text-[#0080BB] italic'>{window.location.hostname}</p>
        <div className={`w-full h-auto border-[1px] border-[#bbb] rounded-sm`}>
            <ul className={`${isMobile?'flex-wrap':''} flex bg-[#F6F6F6]`}>
                {
                    answers.map((a,i)=>{
                        return <li key={a.id}
                            className={`${id===a.id?'bg-white text-[#ED0678]':''} ${i===0&&!chekcClick?'bg-white text-[#ED0678]':''} 
                            text-[#0080BB] bg-[#F6F6F6] font-bold px-[20px] py-[10px] mx-[1px] cursor-pointer hover:text-[#ED0678] shadow-inner`}
                            onClick={()=>click(a.id,i,a.link)}>
                            {a.name.toUpperCase()}
                        </li>
                    })
                }
            </ul>

            <div className={`${isMobile?'flex-wrap':''} w-full h-auto py-[20px] px-[10px] flex justify-between overflow-hidden`}>
                <ul className={`${isMobile?'w-full':'w-[33%]'} list-disc ml-[20px] h-full`}>
                    {
                        news.map((n,i)=>{
                            if(i<=2){
                                return <Link to={`/chi-tiet-bai-viet/${n.id}`} key={n.id}>
                                    <li className='text-[14px] py-[5px] hover:text-[#ED0678]'>{n.title}</li>
                                </Link>
                            }
                        })
                    }
                    <Link to={`/danh-muc/${link}/${id}`} className={`${isMobile?'hidden':''}`}>
                        <p className='text-[12px] mt-[15px] hover:text-[#ED0678] cursor-pointer'>
                            Xem thêm...</p>
                    </Link>
                </ul>

                <div className={`${isMobile?'mx-auto':''} w-[150px] h-full`}>
                    <img src={Question} alt='image' className='w-full mx-auto' />
                </div>

                <ul className={`${isMobile?'w-full':'w-[33%]'} list-disc ml-[20px] h-full`}>
                    {
                        news.map((n,i)=>{
                            if(i>2){
                                return <Link to={`/chi-tiet-bai-viet/${n.id}`} key={n.id}>
                                    <li className='text-[14px] py-[5px] hover:text-[#ED0678]'>{n.title}</li>
                                </Link>
                            }
                        })
                    }
                    <Link to={`/danh-muc/${link}/${id}`} className={`${isMobile?'':'hidden'}`}>
                        <p className='text-[12px] mt-[15px] hover:text-[#ED0678] cursor-pointer'>
                            Xem thêm...</p>
                    </Link>
                </ul>
            </div>
        </div>
        {
            loading ? <Loading/> : <></>
        }
    </div>
  )
}
