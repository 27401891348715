import React, {useContext, useEffect, useState} from 'react'
import { GlobalState } from '../../../../GlobalState'
import { Link } from 'react-router-dom'

const CSS_design = {
    li: 'font-bold px-[15px] text-[14px] cursor-pointer hover:text-[#ED0678]'
}

export default function Section3() {
    const state = useContext(GlobalState)
    const [news_videos] = state.newsVideosAPI.newsVideos
    const [category] = state.categoryAPI.category
    const [isMobile] = state.responsive.isMobile
    const [utils] = state.utilsAPI.utils
    const [idCate, setIdCate] = useState(0)
    const [linkCate, setLinkCate] = useState('')
    const [linkPhotos, setLinkPhotos] = useState('')

    useEffect(()=>{
        const getId = ()=>{
            news_videos.forEach(el=>{
                setIdCate(el.category)
                category.forEach(c=>{
                    if(c.id===el.category){
                        setLinkCate(c.link)
                    }
                })
            })
        }
        getId()
    },[news_videos])

    useEffect(()=>{
        const getLinkFb = ()=>{
            utils.forEach(el=>{
                if(el.id===1){
                    setLinkPhotos(el.photos)
                }
            })
        }
        getLinkFb()
    },[utils])
    
    
  return (
    <div className='w-full h-auto bg-[#F6F6F6] px-[20px]'>
        <ul className='w-full h-[40px] flex items-center border-b-[2px] border-[#ED0678]'>
            <li className='font-bold text-[18px] mt-[-5px] pr-[20px]'><span className='text-[#DD0000] text-[22px]'>/</span> MULTIMEDIA</li>
            <Link to={`/danh-muc/${linkCate}/${idCate}`}><li className={CSS_design.li}>VIDEO</li></Link>
            <Link to={linkPhotos} target='_blank'><li className={CSS_design.li}>PHOTOS</li></Link>
        </ul>
        <div className={`${isMobile?'h-auto flex-wrap':'h-[380px]'} w-full mt-[20px] flex justify-between overflow-hidden`}>
            <div className={`${isMobile?'w-full':'w-[48%]'} h-auto`}>
                {
                    news_videos.map((news,i)=>{
                        if(i===0){
                            return <div key={news.id} className='w-full h-auto'>
                                <Link to={`/chi-tiet-bai-viet/${news.id}`}>
                                    <img src={news.images} alt='ảnh đại diện' className='w-full h-[300px] object-cover' />
                                    <p className='font-bold py-[10px]'>{news.title.slice(0,110)}....</p>
                                </Link>
                            </div>
                        }
                    })
                }
            </div>

            <div className={`${isMobile?'w-full flex-wrap':'w-[48%]'} h-auto flex justify-between flex-wrap overflow-y-scroll`}>
            {
                    news_videos.map((news,i)=>{
                        if(i!==0){
                            return <div key={news.id} className='w-[48%] h-auto mb-[10px]'>
                                <Link to={`/chi-tiet-bai-viet/${news.id}`}>
                                    <img src={news.images} alt='ảnh đại diện' className='w-full h-[150px] object-cover' />
                                    <p className='font-bold py-[10px] text-justify text-[13px]'>{news.title.slice(0,55)}....</p>
                                </Link>
                            </div>
                        }
                    })
                }
            </div>
        </div>
    </div>
  )
}
