import React, {useEffect, useState, useContext} from 'react'
import { GlobalState } from '../../../GlobalState'
import axios from 'axios'
import Button from '../../utils/Button'

import CreateCategory from './CreateCategory'
import UpdateCategory from './UpdateCategory'
import { LuMenuSquare } from "react-icons/lu";

const CSS_Input = {
    item: 'w-full h-[50px] border-[1px] border-[#ccc] rounded-md px-[20px] mt-[20px] shadow-md',
    table: 'border-[1px] border-[#bbb] py-[15px] px-[8px] text-center text-[14px]'
}

export default function ({changeState}) {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [category] = state.categoryAPI.category
    const [cate_child] = state.category_childAPI.category_child1
    const [callback, setCallback] = state.categoryAPI.callback
    const [isMobile] = state.responsive.isMobile
    
    const [create, setCreate] = useState(false)
    const [id, setId] = useState(0)
    const [checkUpdate, setCheckUpdate] = useState(false)

    const handleCreate = ()=>{
        setCreate(!create)
    }

    const handleUpdate = (id)=>{
        setId(id)
        setCheckUpdate(!checkUpdate)
    }

    const handleDelete = async (id)=>{
        if(window.confirm('Lưu ý: Dữ liệu bị xóa sẽ không thể phục hồi.')){
            try {
                const res = await axios.delete(`/api/category/${id}`, {
                    headers: {Authorization: token}
                })

                if(res) {
                    setCallback(!callback)
                    alert(res.data.msg)
                }
                
            } catch (err) {
                alert(err.response.data.msg)
            }
        }
    }

    const handleClick = ()=>{
        changeState(true)
      }

  return (
    <>
        <div className={`fixed left-[50%] translate-x-[-50%] z-[100]
            ${create ? 'top-[100px] transition-transform ease-in-out duration-500' : 'translate-y-[-1000px] transition-transform ease-in-out duration-500'}`}>
            <span className='px-[7px] py-[0px] text-[15px] border-[1px] border-[#bbb] absolute top-[-8px] 
                right-[-8px] rounded-[50%] font-bold bg-white text-[#FF3333] cursor-pointer hover:bg-[#eee]'
                onClick={handleCreate}>X</span>
            <CreateCategory create={create}/>
        </div>

        <div className={`fixed left-[50%] translate-x-[-50%] z-[100]
            ${checkUpdate ? 'top-[100px] transition-transform ease-in-out duration-500' : 'translate-y-[-1000px] transition-transform ease-in-out duration-500'}`}>
            <span className='px-[7px] py-[0px] text-[15px] border-[1px] border-[#bbb] absolute top-[-8px] 
                right-[-8px] rounded-[50%] font-bold bg-white text-[#FF3333] cursor-pointer hover:bg-[#eee]'
                onClick={()=>handleUpdate(0)}>X</span>
            <UpdateCategory id={id} checkUpdate={checkUpdate} />
        </div>
        
        <div className='w-full pb-[30px]'>
            <div className='mb-[20px]'>
                <LuMenuSquare size={'35px'} color='#777' className={`${isMobile?'':'hidden'} absolute top-[10px] left-[10px]`}
                    onClick={handleClick}/>
                <p className={`${isMobile?'mt-[50px]':'mt-[30px]'} font-bold text-[20px] text-center text-[#333]`}>QUẢN LÝ DANH MỤC CẤP 1</p>
                <Button data={'Thêm thể loại'} func={handleCreate} color={'bg-[#3399FF]'} />
            </div>

            <div className={`${isMobile?'overflow-x-scroll':''} w-full`}>
                <table className='m-auto w-full'>
                    <thead>
                        <tr className=''>
                            <th className={CSS_Input.table}>STT</th>
                            <th className={CSS_Input.table}>Tên thể loại</th>
                            <th className={CSS_Input.table}>Link</th>
                            <th className={CSS_Input.table}>Danh mục chính</th>
                            <th className={CSS_Input.table}>Danh mục con</th>
                            <th className={CSS_Input.table}>ID bài viết</th>
                            <th className={CSS_Input.table}>Ngày tạo</th>
                            <th className={CSS_Input.table}>Tùy chỉnh</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            category.map((n, index)=>{
                                return <tr key={n.id}>
                                    <td className={CSS_Input.table}>{index+1}</td>
                                    <td className={`${CSS_Input.table} w-[300px]`}>{n.name.toUpperCase()}</td>
                                    <td className={CSS_Input.table}><u>{n.link}</u></td>
                                    <td className={CSS_Input.table}>
                                        {
                                            cate_child.map(c=>{
                                                if(c.id===n.id_cate){
                                                    return <p key={c.id} className=''>
                                                        {c.name}
                                                    </p>
                                                }
                                            })
                                        }
                                    </td>
                                    <td className={CSS_Input.table}>
                                        {
                                            n.check_con===0 ? 'có' : 'không'
                                        }
                                    </td>
                                    <td className={CSS_Input.table}>{n.id_baiviet}</td>
                                    <td className={CSS_Input.table}>{n.date_created.slice(0,10).split('-').reverse().join('/')}</td>
                                    <td className={CSS_Input.table}>
                                        <div className='w-[100px] flex justify-around mx-auto'>
                                            <button onClick={()=>handleUpdate(n.id)} className={`${n.id===0?'hidden':''} bg-[#FFCC33] hover:bg-[#fde18d] py-[3px] px-[10px] rounded-md`}>Sửa</button>
                                            <button onClick={()=>handleDelete(n.id)} className={`${n.id===0?'hidden':''} bg-[#CC3300] hover:bg-[#fe8259] text-[#fff] py-[3px] px-[10px] rounded-md`}>Xóa</button>
                                        </div>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>

        <div className={`${create || checkUpdate ? '' : 'hidden'} w-full h-screen bg-black fixed top-0 left-0 opacity-[0.2]`}></div>
    </>
  )
}
