import React, {useState, useContext} from 'react'
import { GlobalState } from '../../../GlobalState'
import axios from 'axios'
import Button from '../../utils/Button'

export default function Create_img() {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [callback, setCallback] = state.Img_slideAPI.callback
    const [isMobile] = state.responsive.isMobile
    const [value, setValue] = useState('')

    const handleImage = (e) =>{
        const file = e.target.files[0]
        // const file = value
        const reader = new FileReader();
        reader.onloadend = () => {
            setValue(reader.result)
        }
        reader.readAsDataURL(file)
    }

    const handlesubmit = async () => {
        try {
          const res = await axios.post(`/api/img_slide`, {value: value}, {
            headers: {Authorization: token}
          })
          if(res) {
              alert(res.data.msg)
              setCallback(!callback)
          }
          
        } catch (err) {
          alert(err.response.data.msg)
        }
    }

  return (
    <div className='w-full h-auto'>
        <div className={`${isMobile?'w-full':'w-[80%]'} h-auto bg-[#f6f6f6] mx-auto p-[20px] mt-[50px] rounded-sm`}>
            <p className='text-center font-bold text-[25px] pb-[20px] text-[#555]'>HÌNH ẢNH SLIDE</p>
            <div className={`${isMobile?'flex-wrap':''} flex justify-around items-center`}>
                <div>
                    <p className='text-[15px] pb-[10px] font-bold'>Chọn ảnh:</p>
                    <input type="file" name="file" id="file_up" onChange={handleImage} onClick={e => (e.target.value = null)}
                        className='h-[40px]' />
                </div>

                <div className='w-[300px] h-[194px] bg-slate-200'>
                    <img src={value} alt="Ảnh" style={{ maxWidth: '300px' }}
                        className='w-full aspect-[17/11] object-cover' />
                </div>
            </div>

            <Button data={'Thêm'} func={handlesubmit} color={'bg-[#3399FF]'} />
        </div>
    </div>
  )
}
