import React, {useContext, useState, useEffect} from 'react'
import { GlobalState } from '../../../GlobalState'
import Button from '../../utils/Button'
import axios from 'axios'
import { LuMenuSquare } from "react-icons/lu";

const CSS_Input = {
    item: 'w-full h-[50px] border-[1px] border-[#ccc] rounded-md px-[20px] mt-[20px] shadow-md',
    table: 'border-[1px] border-[#bbb] py-[15px] px-[8px] text-center text-[14px]'
}

export default function Cluster({changeState}) {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [cluster] = state.clusterAPI.cluster
    const [category] = state.categoryAPI.category
    const [callback, setCallback] = state.clusterAPI.callback
    const [isMobile] = state.responsive.isMobile
    const [category_child, setCategory_child] = useState([])
    const [id_cate, setId_cate] = useState('')
    const [id_baiviet, setId_baiviet] = useState(null)

    const [name, setName] = useState('')
    const [nameUpdate, setNameUpdate] = useState('')
    const [Id_cateUpdate, setId_cateUpdate] = useState('')
    const [name_cateUpdate, setName_cateUpdate] = useState('')
    const [id_baivietUpdate, setid_baivietUpdate] = useState(null)
    const [id, setId] = useState(0)
    const [check, setCheck] = useState(false)
    const [value_lienket, setValue_lienket] = useState('')

    useEffect(()=>{
        const handleCate = ()=>{
            let arr1 = []
            category.forEach(el=>{
                if(el.check_con===0){
                    arr1.push(el)
                }
            })
            setCategory_child(arr1)
        }
        handleCate()
    },[category, cluster])

    useEffect(()=>{
        if(id){
            const getName = ()=>{
                cluster.forEach(el=>{
                    if(el.id===id){
                        setNameUpdate(el.name)
                        setId_cateUpdate(el.id_cate)
                        setid_baivietUpdate(el.id_baiviet)
                    }
                })
            }
            getName()
        }
    },[id])

    useEffect(()=>{
        const getNameCate = ()=>{
            category.map(el=>{
                if(Id_cateUpdate===el.id){
                    setName_cateUpdate(el.name)
                }
            })
        }
        getNameCate()
    },[Id_cateUpdate])

    function convert(str) {
        var convertTable = {
            'à': 'a', 'á': 'a', 'ả': 'a', 'ã': 'a', 'ạ': 'a',
            'ă': 'a', 'ằ': 'a', 'ắ': 'a', 'ẳ': 'a', 'ẵ': 'a', 'ạ': 'a',
            'â': 'a', 'ầ': 'a', 'ấ': 'a', 'ẩ': 'a', 'ẫ': 'a', 'ậ': 'a',
            'đ': 'd',
            'è': 'e', 'é': 'e', 'ẻ': 'e', 'ẽ': 'e', 'ẹ': 'e',
            'ê': 'e', 'ề': 'e', 'ế': 'e', 'ể': 'e', 'ễ': 'e', 'ệ': 'e',
            'ì': 'i', 'í': 'i', 'ỉ': 'i', 'ĩ': 'i', 'ị': 'i',
            'ò': 'o', 'ó': 'o', 'ỏ': 'o', 'õ': 'o', 'ọ': 'o',
            'ô': 'o', 'ồ': 'o', 'ố': 'o', 'ổ': 'o', 'ỗ': 'o', 'ộ': 'o',
            'ơ': 'o', 'ờ': 'o', 'ớ': 'o', 'ở': 'o', 'ỡ': 'o', 'ợ': 'o',
            'ù': 'u', 'ú': 'u', 'ủ': 'u', 'ũ': 'u', 'ụ': 'u',
            'ư': 'u', 'ừ': 'u', 'ứ': 'u', 'ử': 'u', 'ữ': 'u', 'ự': 'u',
            'ỳ': 'y', 'ý': 'y', 'ỷ': 'y', 'ỹ': 'y', 'ỵ': 'y',
            'À': 'A', 'Á': 'A', 'Ả': 'A', 'Ã': 'A', 'Ạ': 'A',
            'Ă': 'A', 'Ằ': 'A', 'Ắ': 'A', 'Ẳ': 'A', 'Ẵ': 'A', 'Ặ': 'A',
            'Â': 'A', 'Ầ': 'A', 'Ấ': 'A', 'Ẩ': 'A', 'Ẫ': 'A', 'Ậ': 'A',
            'Đ': 'D',
            'È': 'E', 'É': 'E', 'Ẻ': 'E', 'Ẽ': 'E', 'Ẹ': 'E',
            'Ê': 'E', 'Ề': 'E', 'Ế': 'E', 'Ể': 'E', 'Ễ': 'E', 'Ệ': 'E',
            'Ì': 'I', 'Í': 'I', 'Ỉ': 'I', 'Ĩ': 'I', 'Ị': 'I',
            'Ò': 'O', 'Ó': 'O', 'Ỏ': 'O', 'Õ': 'O', 'Ọ': 'O',
            'Ô': 'O', 'Ồ': 'O', 'Ố': 'O', 'Ổ': 'O', 'Ỗ': 'O', 'Ộ': 'O',
            'Ơ': 'O', 'Ờ': 'O', 'Ớ': 'O', 'Ở': 'O', 'Ỡ': 'O', 'Ợ': 'O',
            'Ù': 'U', 'Ú': 'U', 'Ủ': 'U', 'Ũ': 'U', 'Ụ': 'U',
            'Ư': 'U', 'Ừ': 'U', 'Ứ': 'U', 'Ử': 'U', 'Ữ': 'U', 'Ự': 'U',
            'Ỳ': 'Y', 'Ý': 'Y', 'Ỷ': 'Y', 'Ỹ': 'Y', 'Ỵ': 'Y'
        };
    
        return str.replace(/[àáảãạăằắẳẵặâầấẩẫậđèéẻẽẹêềếểễệìíỉĩịòóỏõọôồốổỗộơờớởỡợùúủũụưừứửữựỳýỷỹỵÀÁẢÃẠĂẰẮẲẴẶÂẦẤẨẪẬĐÈÉẺẼẸÊỀẾỂỄỆÌÍỈĨỊÒÓỎÕỌÔỒỐỔỖỘƠỜỚỞỠỢÙÚỦŨỤƯỪỨỬỮỰỲÝỶỸỴ]/g, function(match) {
            return convertTable[match];
        });
    }

    const handleSubmit = async ()=>{
        const today = new Date()
        const formattedDate = today.toISOString().split('T')[0];
        const link = convert(name.toLocaleLowerCase().split(' ').filter(function(item) {
            return item !== '-';
        }).join('-'))

        try {
            const res = await axios.post(`/api/cluster`, {name: name, date_created: formattedDate, id_cate:id_cate, link:link, id_baiviet:id_baiviet}, {
                headers: {Authorization: token}
            })
            alert(res.data.msg)
            setCallback(!callback)
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleUpdate = async ()=>{
        const today = new Date()
        const formattedDate = today.toISOString().split('T')[0];
        const link = convert(nameUpdate.toLocaleLowerCase().split(' ').filter(function(item) {
            return item !== '-';
        }).join('-'))

        try {
            const res = await axios.put(`/api/cluster/${id}`, {name: nameUpdate, date_created: formattedDate, id_cate:id_cate===''?Id_cateUpdate:id_cate, link:link, id_baiviet:id_baiviet!==null?id_baiviet:id_baivietUpdate}, {
                headers: {Authorization: token}
            })
            alert(res.data.msg)
            setCallback(!callback)
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleDelete = async (id)=>{
        if(window.confirm('Lưu ý: Dữ liệu bị xóa sẽ không thể phục hồi.')){
            try {
                const res = await axios.delete(`/api/cluster/${id}`, {
                    headers: {Authorization: token}
                })
                alert(res.data.msg)
                setCallback(!callback)
                
            } catch (err) {
                alert(err.response.data.msg)
            }
        }
    }

    const checkUpdate = (id)=>{
        setId(id)
        setCheck(true)
    }

    const goBack = ()=>{
        setCheck(false)
        setId_baiviet(null)
        setid_baivietUpdate(null)
        setValue_lienket('')
    }

    const handleClick = ()=>{
        changeState(true)
    }

    console.log(id_cate)

  return (
    <div className={`${isMobile?'py-[30px]':'p-[30px]'} w-full h-screen`}>
        <LuMenuSquare size={'35px'} color='#777' className={`${isMobile?'':'hidden'} absolute top-[10px] left-[10px]`}
          onClick={handleClick}/>
        <p className='font-bold text-[20px] text-center text-[#333]'>QUẢN LÝ DANH MỤC CẤP 2</p>
        <div className={`${isMobile?'w-full':'w-[60%]'} h-auto bg-[#eee] mx-auto mt-[20px] p-[30px]`}>
            <p className='text-[13px] ml-[5px]'>Loại liên kết:</p>
            <select name='id_cate' value={value_lienket} onChange={(e)=>setValue_lienket(e.target.value)} 
                className={`w-full h-[40px] border-[1px] border-[#ccc] rounded-md shadow-md px-[14px] mb-[20px]`}>
                <option hidden>Chọn loại liên kết</option>
                <option value={'1'}>Liên kết danh mục</option>
                <option value={'2'}>Liên kết bài viết</option>
            </select>

            <p className='text-[13px] ml-[5px]'>Tên danh mục:</p>
            <input type='text' name='name' value={check?nameUpdate:name} placeholder='Nhập tên danh mục cấp 2...' 
                onChange={check?(e)=>setNameUpdate(e.target.value):(e)=>setName(e.target.value)} className='w-full h-[40px] border-[1px] border-[#ccc] px-[15px] rounded-md shadow-md mx-auto'/>
            
            <p className='text-[13px] ml-[5px] mt-[20px]'>Thuộc danh mục cấp 1:</p>
            <select name='id_cate' value={id_cate} onChange={(e)=>setId_cate(e.target.value)} 
                className='w-full h-[40px] border-[1px] border-[#ccc] rounded-md shadow-md px-[14px]'>
                <option hidden>{check?name_cateUpdate:'Chọn danh mục cấp 1'}</option>
                {
                    category_child.map(c=>{
                        return <option value={c.id} key={c.id}>{c.name}</option>
                    })
                }
            </select>

            <p className={`${value_lienket==='2'||id_baivietUpdate!==null?'':'hidden'} text-[13px] ml-[5px] mt-[20px]`}>ID bài viết:</p>
            <input type="text" name="" placeholder='Nhập ID bài viết' value={check?id_baivietUpdate:id_baiviet} 
                onChange={check?(e)=>setid_baivietUpdate(e.target.value):(e)=>setId_baiviet(e.target.value)}
                className={`${value_lienket==='2'||id_baivietUpdate!==null?'':'hidden'} w-full h-[40px] border-[1px] border-[#ccc] px-[15px] rounded-md shadow-md mx-auto`} />
            
            <Button data={check?'Cập nhật':'Thêm'} func={check?handleUpdate:handleSubmit} color={'bg-[#0099ff]'} />
            {check ? <Button data={'Hủy'} func={goBack} color={'bg-[#0099ff]'} /> : <></>}
        </div>

        <div className={`${isMobile?'overflow-x-scroll':'w-full pb-[50px]'}`}>
            <table className='m-auto w-full mt-[20px]'>
                <thead>
                    <tr>
                        <th className={CSS_Input.table}>STT</th>
                        <th className={CSS_Input.table}>Tên</th>
                        <th className={CSS_Input.table}>Link</th>
                        <th className={CSS_Input.table}>Danh mục cấp 1</th>
                        <th className={CSS_Input.table}>ID bài viết</th>
                        <th className={CSS_Input.table}>Ngày tạo/cập nhật</th>
                        <th className={CSS_Input.table}>Tùy chỉnh</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        cluster.map((c,i)=>(
                            <tr key={c.id}>
                                <td className={CSS_Input.table}>{i+1}</td>
                                <td className={CSS_Input.table}>{c.name}</td>
                                <td className={CSS_Input.table}>{c.link}</td>
                                <td className={CSS_Input.table}>
                                    {
                                        category.map(cate=>{
                                            if(cate.id===c.id_cate){
                                                return <p>{cate.name}</p>
                                            }
                                        })
                                    }
                                </td>
                                <td className={CSS_Input.table}>{c.id_baiviet}</td>
                                <td className={CSS_Input.table}>{(c.date_created).split('-').reverse().join('/')}</td>
                                <td className={CSS_Input.table}>
                                    <div className='w-[100px] flex justify-around mx-auto'>
                                        <button onClick={()=>checkUpdate(c.id)} className={`${c.id===0?'hidden':''} bg-[#FFCC33] hover:bg-[#fde18d] py-[3px] px-[10px] rounded-md`}>Sửa</button>
                                        <button onClick={()=>handleDelete(c.id)} className={`${c.id===0?'hidden':''} bg-[#CC3300] hover:bg-[#fe8259] text-[#fff] py-[3px] px-[10px] rounded-md`}>Xóa</button>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    </div>
  )
}
