import React, {useContext, useState, useEffect} from 'react'
import { GlobalState } from '../../../GlobalState'
import Button from '../../utils/Button'
import axios from 'axios'
import { LuMenuSquare } from "react-icons/lu";

const CSS_Input = {
    item: 'w-full h-[50px] border-[1px] border-[#ccc] rounded-md px-[20px] mt-[20px] shadow-md',
    table: 'border-[1px] border-[#bbb] py-[15px] px-[8px] text-center text-[14px]'
}

export default function DanhMuc({changeState}) {
  const state = useContext(GlobalState)
    const [token] = state.token
    const [cate_child] = state.category_childAPI.category_child1
    const [callback, setCallback] = state.category_childAPI.callback
    const [isMobile] = state.responsive.isMobile

    const [name, setName] = useState('')
    const [nameUpdate, setNameUpdate] = useState('')
    const [id_baivietUpdate, setid_baivietUpdate] = useState(null)
    const [id, setId] = useState(0)
    const [check, setCheck] = useState(false)

    const [check_con, setCheck_con] = useState(false)
    const [value_lienket, setValue_lienket] = useState('')
    const [id_baiviet, setId_baiviet] = useState(null)
    const [check_conUpdate, setcheck_conUpdate] = useState(null)

    useEffect(()=>{
      if(id){
          const getName = ()=>{
            cate_child.forEach(el=>{
                  if(el.id===id){
                      setNameUpdate(el.name)
                      setid_baivietUpdate(el.id_baiviet)
                      setcheck_conUpdate(el.check_con)
                  }
              })
          }
          getName()
      }
  },[id])

  function convert(str) {
    var convertTable = {
        'à': 'a', 'á': 'a', 'ả': 'a', 'ã': 'a', 'ạ': 'a',
        'ă': 'a', 'ằ': 'a', 'ắ': 'a', 'ẳ': 'a', 'ẵ': 'a', 'ạ': 'a',
        'â': 'a', 'ầ': 'a', 'ấ': 'a', 'ẩ': 'a', 'ẫ': 'a', 'ậ': 'a',
        'đ': 'd',
        'è': 'e', 'é': 'e', 'ẻ': 'e', 'ẽ': 'e', 'ẹ': 'e',
        'ê': 'e', 'ề': 'e', 'ế': 'e', 'ể': 'e', 'ễ': 'e', 'ệ': 'e',
        'ì': 'i', 'í': 'i', 'ỉ': 'i', 'ĩ': 'i', 'ị': 'i',
        'ò': 'o', 'ó': 'o', 'ỏ': 'o', 'õ': 'o', 'ọ': 'o',
        'ô': 'o', 'ồ': 'o', 'ố': 'o', 'ổ': 'o', 'ỗ': 'o', 'ộ': 'o',
        'ơ': 'o', 'ờ': 'o', 'ớ': 'o', 'ở': 'o', 'ỡ': 'o', 'ợ': 'o',
        'ù': 'u', 'ú': 'u', 'ủ': 'u', 'ũ': 'u', 'ụ': 'u',
        'ư': 'u', 'ừ': 'u', 'ứ': 'u', 'ử': 'u', 'ữ': 'u', 'ự': 'u',
        'ỳ': 'y', 'ý': 'y', 'ỷ': 'y', 'ỹ': 'y', 'ỵ': 'y',
        'À': 'A', 'Á': 'A', 'Ả': 'A', 'Ã': 'A', 'Ạ': 'A',
        'Ă': 'A', 'Ằ': 'A', 'Ắ': 'A', 'Ẳ': 'A', 'Ẵ': 'A', 'Ặ': 'A',
        'Â': 'A', 'Ầ': 'A', 'Ấ': 'A', 'Ẩ': 'A', 'Ẫ': 'A', 'Ậ': 'A',
        'Đ': 'D',
        'È': 'E', 'É': 'E', 'Ẻ': 'E', 'Ẽ': 'E', 'Ẹ': 'E',
        'Ê': 'E', 'Ề': 'E', 'Ế': 'E', 'Ể': 'E', 'Ễ': 'E', 'Ệ': 'E',
        'Ì': 'I', 'Í': 'I', 'Ỉ': 'I', 'Ĩ': 'I', 'Ị': 'I',
        'Ò': 'O', 'Ó': 'O', 'Ỏ': 'O', 'Õ': 'O', 'Ọ': 'O',
        'Ô': 'O', 'Ồ': 'O', 'Ố': 'O', 'Ổ': 'O', 'Ỗ': 'O', 'Ộ': 'O',
        'Ơ': 'O', 'Ờ': 'O', 'Ớ': 'O', 'Ở': 'O', 'Ỡ': 'O', 'Ợ': 'O',
        'Ù': 'U', 'Ú': 'U', 'Ủ': 'U', 'Ũ': 'U', 'Ụ': 'U',
        'Ư': 'U', 'Ừ': 'U', 'Ứ': 'U', 'Ử': 'U', 'Ữ': 'U', 'Ự': 'U',
        'Ỳ': 'Y', 'Ý': 'Y', 'Ỷ': 'Y', 'Ỹ': 'Y', 'Ỵ': 'Y'
    };

    return str.replace(/[àáảãạăằắẳẵặâầấẩẫậđèéẻẽẹêềếểễệìíỉĩịòóỏõọôồốổỗộơờớởỡợùúủũụưừứửữựỳýỷỹỵÀÁẢÃẠĂẰẮẲẴẶÂẦẤẨẪẬĐÈÉẺẼẸÊỀẾỂỄỆÌÍỈĨỊÒÓỎÕỌÔỒỐỔỖỘƠỜỚỞỠỢÙÚỦŨỤƯỪỨỬỮỰỲÝỶỸỴ]/g, function(match) {
        return convertTable[match];
    });
}

const handleSubmit = async ()=>{
    const today = new Date()
    const formattedDate = today.toISOString().split('T')[0];
    const link = convert(name.toLocaleLowerCase().split(' ').filter(function(item) {
        return item !== '-';
    }).join('-'))

    try {
        const res = await axios.post(`/api/category_child1`, {name: name, link: link, date_created: formattedDate, check_con:check_con?1:0, id_baiviet:id_baiviet}, {
            headers: {Authorization: token}
        })
        alert(res.data.msg)
        setCallback(!callback)
        setCheck_con(false)
        setId_baiviet(null)
        setValue_lienket('')
        setName('')
        
    } catch (err) {
        alert(err.response.data.msg)
    }
}

const handleUpdate = async ()=>{
    const today = new Date()
    const formattedDate = today.toISOString().split('T')[0];
    const link = convert(nameUpdate.toLocaleLowerCase().split(' ').filter(function(item) {
        return item !== '-';
    }).join('-'))

    try {
        const res = await axios.put(`/api/category_child1/${id}`, {name: nameUpdate, link: link, date_created: formattedDate, check_con: check_conUpdate===1 ? check_con ? 0 : 1 : check_con ? 1 : 0, id_baiviet:id_baiviet?id_baiviet:id_baivietUpdate}, {
            headers: {Authorization: token}
        })
        alert(res.data.msg)
        setCallback(!callback)
        setCheck_con(false)
        setcheck_conUpdate(false)
        setId_baiviet(null)
        setid_baivietUpdate(null)
        setValue_lienket('')
        setName('')
        setcheck_conUpdate(null)
        setId(0)
        
    } catch (err) {
        alert(err.response.data.msg)
    }
}

const handleDelete = async (id)=>{
    if(window.confirm('Lưu ý: Dữ liệu bị xóa sẽ không thể phục hồi.')){
        try {
            const res = await axios.delete(`/api/category_child1/${id}`, {
                headers: {Authorization: token}
            })
            alert(res.data.msg)
            setCallback(!callback)
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }
    // console.log(id)
}

const checkUpdate = (id)=>{
    setId(id)
    setCheck(true)
}

const goBack = ()=>{
    setCheck(false)
    setCheck_con(false)
    setcheck_conUpdate(false)
    setId_baiviet(null)
    setid_baivietUpdate(null)
    setValue_lienket('')
    setName('')
    setcheck_conUpdate(null)
    setId(0)
}

const handleClick = ()=>{
    changeState(true)
  }

//   console.log(id_baiviet)

  return (
    <div className={`${isMobile?'py-[30px]':'p-[30px]'} w-full h-screen`}>
        <LuMenuSquare size={'35px'} color='#777' className={`${isMobile?'':'hidden'} absolute top-[25px] left-[10px]`}
          onClick={handleClick}/>
        <p className={`${isMobile?'ml-[30px]':''} font-bold text-[20px] text-center text-[#333]`}>QUẢN LÝ DANH MỤC CHÍNH</p>
        <div className={`${isMobile?'w-full':'w-[60%]'} w-[60%] h-auto bg-[#eee] mx-auto mt-[20px] p-[30px]`}>
            <div className='mt-[10px] mb-[20px]'>
                <span className='text-[15px] pb-[10px] font-bold'>Không có danh mục con:</span>
                <input type='checkbox' name='check_con' value={check_con} checked={check_conUpdate===1?!check_con:check_con} onChange={(e)=>setCheck_con(!check_con)} 
                    className='w-[18px] h-[18px] ml-[10px] cursor-pointer'/>
            </div>

            <select name='id_cate' value={value_lienket} onChange={(e)=>setValue_lienket(e.target.value)} 
                className={`${check_con||check_conUpdate?'':'hidden'} w-full h-[40px] border-[1px] border-[#ccc] rounded-md shadow-md px-[14px] mb-[20px]`}>
                <option hidden>Chọn loại liên kết</option>
                <option value={'1'}>Liên kết danh mục</option>
                <option value={'2'}>Liên kết bài viết</option>
            </select>

            <input type='text' name='name' value={check?nameUpdate:name} placeholder='Nhập tên thể loại...' 
                onChange={check?(e)=>setNameUpdate(e.target.value):(e)=>setName(e.target.value)} className='w-full h-[40px] border-[1px] border-[#ccc] px-[15px] rounded-md shadow-md mx-auto'/>

            <input type="text" name="" placeholder='Nhập ID bài viết' value={check?id_baivietUpdate:id_baiviet} 
                onChange={check?(e)=>setid_baivietUpdate(e.target.value):(e)=>setId_baiviet(e.target.value)}
                className={`${value_lienket==='2'||id_baivietUpdate!==null?'':'hidden'} w-full h-[40px] border-[1px] border-[#ccc] mt-[20px] px-[15px] rounded-md shadow-md mx-auto`} />

            <Button data={check?'Cập nhật':'Thêm'} func={check?handleUpdate:handleSubmit} color={'bg-[#0099ff]'} />
            {check ? <Button data={'Hủy'} func={goBack} color={'bg-[#0099ff]'} /> : <></>}
        </div>

        <div className={`${isMobile?'overflow-x-scroll':''} w-full`}>
            <table className={`m-auto w-full mt-[20px]`}>
                <thead>
                    <tr>
                        <th className={CSS_Input.table}>STT</th>
                        <th className={CSS_Input.table}>Tên</th>
                        <th className={CSS_Input.table}>Link</th>
                        <th className={CSS_Input.table}>Danh mục con</th>
                        <th className={CSS_Input.table}>ID bài viết</th>
                        <th className={CSS_Input.table}>Ngày tạo</th>
                        <th className={CSS_Input.table}>Tùy chỉnh</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        cate_child.map((c,i)=>(
                            <tr key={c.id}>
                                <td className={CSS_Input.table}>{i+1}</td>
                                <td className={CSS_Input.table}>{c.name}</td>
                                <td className={CSS_Input.table}>{c.link}</td>
                                <td className={CSS_Input.table}>
                                    {c.check_con===0?'có':'không'}
                                </td>
                                <td className={CSS_Input.table}>{c.id_baiviet}</td>
                                <td className={CSS_Input.table}>{(c.date_created).split('-').reverse().join('/')}</td>
                                <td className={CSS_Input.table}>
                                    <div className='w-[100px] flex justify-around mx-auto'>
                                        {
                                            c.id===0 ? <></> :
                                            <>
                                                <button onClick={()=>checkUpdate(c.id)} className='bg-[#FFCC33] hover:bg-[#fde18d] py-[3px] px-[10px] rounded-md'>Sửa</button>
                                                <button onClick={()=>handleDelete(c.id)} className='bg-[#CC3300] hover:bg-[#fe8259] text-[#fff] py-[3px] px-[10px] rounded-md'>Xóa</button>
                                            </>
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    </div>
  )
}
