import React, {useEffect, useState} from 'react'

export default function Responsive() {
    // độ dài , rộng màn hình
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isTablet, setIsTablet] = useState(window.innerWidth >= 576 && window.innerWidth < 768);
    // const [isTabletBig, setIsTabletBig] = useState(window.innerWidth >= 768 && window.innerWidth <= 1024);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    },[]);

    useEffect(() => {
        setIsMobile(windowSize.width < 768);
        setIsTablet(window.innerWidth >= 576 && window.innerWidth < 768);
        // setIsTabletBig(windowSize.width >= 800 && windowSize.width <= 1024);
    }, [windowSize]);

    return {
        isMobile: [isMobile, setIsMobile],
        isTablet: [isTablet, setIsTablet],
        // isTabletBig: [isTabletBig, setIsTabletBig]
    }
}
