import React, {useContext, useState, useEffect} from 'react'
import { GlobalState } from '../../GlobalState'
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Logo_head() {
    const state = useContext(GlobalState)
    const [utils] = state.utilsAPI.utils
    const [logo, setLogo] = useState('')

    useEffect(()=>{
        const getData = ()=>{
            utils.forEach(el=>{
                if(el.id===1){
                    setLogo(el.logo)
                }
            })
        }
        getData()
    },[utils])

  return (
    <HelmetProvider>
        <div>
            <Helmet>
                <link rel="icon" type="image/png" href={logo} />
                <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0&appId=YOUR_APP_ID&autoLogAppEvents=1" nonce="YOUR_NONCE_VALUE"></script>
            </Helmet>
        </div>
    </HelmetProvider>
  )
}
