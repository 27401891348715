import React, {useEffect, useState, useContext} from 'react'
import Section1 from './section/Section1'
import Section2 from './section/Section2'
import Section3 from './section/Section3'
import Section4 from './section/Section4'

export default function Home() {
  return (
    <div className='max-w-[1000px] h-auto mx-auto mt-[20px]'>
        <Section1/>
        <Section2/>
        <Section3/>
        <Section4/>
    </div>
  )
}
