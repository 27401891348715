import React, {useContext, useState, useEffect} from 'react'
import { GlobalState } from '../../../GlobalState'
import Button from '../../utils/Button'
import axios from 'axios'
import Loading from '../../utils/Loading'
import { IoReturnUpBack } from "react-icons/io5";
import { LuMenuSquare } from "react-icons/lu";

const CSS_Input = {
    item: 'w-full h-[50px] border-[1px] border-[#ccc] rounded-md px-[20px] mt-[20px] shadow-md',
    table: 'border-[1px] border-[#bbb] py-[15px] px-[8px] text-center text-[14px]'
}

export default function Comment({changeState}) {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [news] = state.newsAPI.news
    const [isMobile] = state.responsive.isMobile
    const [comments, setComments] = useState([])
    const [callback, setCallback] = useState(false)
    const [limit, setLimit] = useState(10)
    const [loading, setLoading] = useState(false)

    const [valueSearch, setValueSearch] = useState('')
    const [valueSearchNews, setValueSearchNews] = useState('')
    const [commentSearch, setCommentSearch] = useState([])

    useEffect(()=>{
        const getData = async ()=>{
            const res = await axios.get(`/api/comment-all/${limit}`, {
                headers: {Authorization: token}
            })
            setComments(res.data)
            setLoading(false)
        }
        getData()
    },[callback, loading])

    const handleDelete = async (id)=>{
        if(window.confirm('Lưu ý: Dữ liệu bị xóa sẽ không thể phục hồi.')){
            try {
                const res = await axios.delete(`/api/comment/${id}`,{
                    headers: {Authorization: token}
                })
                alert(res.data.msg)
                setCallback(!callback)
                
            } catch (err) {
                alert(err.response.data.msg)
            }
        }
    }

    const showMore = ()=>{
        setLoading(true)
        setLimit(limit + 5)
    }

    const searchComment = async ()=>{
        if(!valueSearch){
            alert('Chưa nhập nội dung tìm kiếm.')
        }
        else {
            try {
                setValueSearchNews('')
                const res = await axios.post(`/api/search-comment`, {value: valueSearch}, {
                    headers: {Authorization: token}
                })
                if(res.data.length===0){
                    alert('Nội dung không tìm thấy')
                }
                setCommentSearch(res.data)
                
            } catch (err) {
                alert(err.response.data.msg)
            }
        }
    }

    const searchTitleNews = async ()=>{
        if(!valueSearchNews){
            alert('Chưa nhập nội dung tìm kiếm.')
        }
        else {
            try {
                const res = await axios.post(`/api/search-title-news`, {value: valueSearchNews}, {
                    headers: {Authorization: token}
                })
                if(res.data.length===0){
                    alert('Nội dung không tìm thấy')
                }
                // alert(res.data.msg)
                setCommentSearch(res.data)

            } catch (err) {
                alert(err.response.data.msg)
            }
        }
    }

    const cancelSearch = ()=>{
        setCommentSearch([])
        setValueSearch('')
        setValueSearchNews('')
        setCallback(!callback)
    }

    const deleteAll = async ()=>{
        if(window.confirm('Lưu ý: Dữ liệu bị xóa sẽ không thể phục hồi.')){
            try {
                const res = await axios.delete(`/api/comment`,{
                    headers: {Authorization: token}
                })
                alert(res.data.msg)
                setCallback(!callback)
                
            } catch (err) {
                alert(err.response.data.msg)
            }
        }
    }

    const handleClick = ()=>{
        changeState(true)
    }

  return (
    <div className={`${isMobile?'py-[30px]':'p-[30px]'} w-full h-screen relative`}>
        <LuMenuSquare size={'35px'} color='#777' className={`${isMobile?'top-[25px]':'hidden'} absolute top-[10px] left-[10px]`}
          onClick={handleClick}/>
        <p className={`${isMobile?'ml-[30px]':''} font-bold text-[20px] text-center text-[#333]`}>QUẢN LÝ BÌNH LUẬN BÀI VIẾT</p>
        <div className={`${isMobile?'flex-wrap':''} w-full h-auto flex items-center mt-[20px]`}>
            <IoReturnUpBack size={'33px'} className='cursor-pointer border-[1px] border-[#ccc] shadow-sm hover:bg-[#eee] rounded-sm px-[5px]' onClick={cancelSearch} />
            <div className={`${isMobile?'ml-[0px]':'ml-[30px]'} w-auto h-auto`}>
                <input type='text' value={valueSearch} placeholder='Tìm nội dung bình luận...' onChange={(e)=>setValueSearch(e.target.value)}
                    className={`${isMobile?'mt-[10px]':''} w-auto h-[35px] text-[14px] border-[1px] border-[#ccc] shadow-md rounded-md px-[15px]`} />
                <button onClick={searchComment} className='bg-[#777] text-[#fff] ml-[10px] shadow-sm px-[10px] py-[5px] rounded-sm'>Tìm kiếm</button>

                <input type='text' value={valueSearchNews} placeholder='Tìm theo tên bài viết...' onChange={(e)=>setValueSearchNews(e.target.value)}
                    className={`${isMobile?'ml-[0px] mt-[10px]':'ml-[80px]'} w-auto h-[35px] text-[14px] border-[1px] border-[#ccc] shadow-md rounded-md px-[15px]`} />
                <button onClick={searchTitleNews} className='bg-[#777] text-[#fff] ml-[10px] shadow-sm px-[10px] py-[5px] rounded-sm'>Tìm kiếm</button>
            </div>
        </div>
        <button onClick={deleteAll} className='bg-[#990000] hover:bg-[#9d3838] text-[13px] text-white font-bold mt-[10px] px-[10px] py-[5px] rounded-sm shadow-sm'>Xóa tất cả</button>
        
        <div className={`${isMobile?'overflow-x-scroll':'w-full'}`}>
            <table className='m-auto w-full mt-[20px]'>
                <thead>
                    <tr>
                        <th className={CSS_Input.table}>STT</th>
                        <th className={CSS_Input.table}>Nội dung</th>
                        <th className={CSS_Input.table}>Mã bài viết</th>
                        <th className={CSS_Input.table}>Ngày tạo</th>
                        <th className={CSS_Input.table}>Tùy chỉnh</th>
                    </tr>
                </thead>
                <tbody>
                    {   commentSearch.length !==0 ?
                        commentSearch.map((c,i)=>(
                            <tr key={c.id}>
                                <td className={CSS_Input.table}>{i+1}</td>
                                <td className={CSS_Input.table}>{c.content}</td>
                                <td className={CSS_Input.table}>{c.id_news}</td>
                                <td className={CSS_Input.table}>{(c.date_created).split('-').reverse().join('/')}</td>
                                <td className={CSS_Input.table}>
                                    <div className='w-[100px] flex justify-around mx-auto'>
                                        <button onClick={()=>handleDelete(c.id)} className='bg-[#CC3300] hover:bg-[#fe8259] text-[#fff] py-[3px] px-[10px] rounded-md'>Xóa</button>
                                    </div>
                                </td>
                            </tr>
                        ))
                        :
                        comments.map((c,i)=>(
                            <tr key={c.id}>
                                <td className={CSS_Input.table}>{i+1}</td>
                                <td className={CSS_Input.table}>{c.content}</td>
                                <td className={CSS_Input.table}>{c.id_news}</td>
                                <td className={CSS_Input.table}>{(c.date_created).split('-').reverse().join('/')}</td>
                                <td className={CSS_Input.table}>
                                    <div className='w-[100px] flex justify-around mx-auto'>
                                        <button onClick={()=>handleDelete(c.id)} className='bg-[#CC3300] hover:bg-[#fe8259] text-[#fff] py-[3px] px-[10px] rounded-md'>Xóa</button>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
        
        <div className='w-full h-auto pb-[30px]'>
            <Button data={'Xem thêm'} func={showMore} color={'bg-[#0099ff]'} />
        </div>
        {
            loading ? <Loading/> : <></>
        }
    </div>
  )
}
