import React, {useState, useEffect} from 'react'
import axios from 'axios'

export default function MenuAPI() {
    const [menu, setMenu] = useState([])
    const [callback, setCallback] = useState(false)

    useEffect(() =>{
        const getMenu = async () =>{
            const res = await axios.get('/api/menu')
            setMenu(res.data)
        }
        getMenu()
    },[callback])

    return {
        menu: [menu, setMenu],
        callback: [callback, setCallback]
    }
}
