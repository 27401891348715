import React, {useContext, useState, useEffect} from 'react'
import { GlobalState } from '../../../../GlobalState'
import Button from '../../../utils/Button'
import axios from 'axios'

export default function LinkZalo() {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [utils] = state.utilsAPI.utils
    const [isMobile] = state.responsive.isMobile
    const [idCate, setIdCate] = useState(0)
    const [zalo, setzalo] = useState(0)

    useEffect(()=>{
        const getData = ()=>{
            utils.forEach(el=>{
                if(el.id===1){
                    setIdCate(el.id)
                    setzalo(el.zalo)
                }
            })
        }
        getData()
    },[utils])

    const handleSubmit = async ()=>{
        try {
            const res = await axios.put(`/api/utils2/${idCate}/zalo`, {data: zalo}, {
                headers: {Authorization: token}
            })
            alert(res.data.msg)
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

  return (
    <div className='w-full h-screen bg-[#eee] p-[20px]'>
        <p className='font-bold text-center text-[20px] pb-[20px] mt-[30px]'>QUẢN LÝ LINK LIÊN KẾT ZALO</p>
        <div className={`${isMobile?'w-full':'w-[70%]'} h-auto mx-auto mb-[20px] text-[14px]`}>
            <label className='font-bold ml-[5px]'>Link liên kết đến zalo:</label>
            <textarea type='text' name='zalo' value={zalo} placeholder='Nhập link liên kết...' 
                onChange={(e)=>setzalo(e.target.value)} className='w-full h-[100px] p-[15px] border-[1px] border-[#ccc] rounded-md shadow-md mt-[5px]' />
        </div>
        <Button data={'Cập nhật'} func={handleSubmit} color={'bg-[#0099ff]'} />
    </div>
  )
}
