import React, {useEffect, useState, useContext} from 'react'
import { GlobalState } from '../../../GlobalState'
import axios from 'axios'
import handleIncreaseQuality from '../../utils/HandleIncrease'

import { IoNewspaperSharp, IoMenu, IoHome, IoLogOutOutline, IoQrCodeOutline } from "react-icons/io5";
import { FaHandshake, FaComments, FaLeaf, FaSliders } from "react-icons/fa6";
import { BiSolidCategory } from "react-icons/bi";
import { MdContactMail, MdEmail } from "react-icons/md";
import { AiOutlineCluster } from "react-icons/ai";
import { PiPasswordFill } from "react-icons/pi";
import { IoIosListBox } from "react-icons/io";
import { FaFileAlt } from "react-icons/fa";

import News from '../news/News'
import Category from '../category/Category'
import Donors from '../donors/ListDonors'
import Menu from '../menu/menu'
import InforContact from '../inforContact/InforContact'
import TrangChu from '../trangchu/TrangChu'
import Cluster from '../cluster/Cluster'
import Answers from '../answers/Answers'
import Comment from '../comment/Comment'
import QR_code from '../QR_code/QR_code'
import Background from '../background/Background'
import ChangePass from '../changePass/ChangePass'
import Message from '../message/Message';
import DanhMuc from '../danhmuc/DanhMuc';
import Img_slide from '../img_slide/Img_slide';
import FilePDF from '../filePDF/FilePDF';

import { LuMenuSquare } from "react-icons/lu";

const design_css = {
    p_tabar: 'flex items-center tracking-wide text-[14px] text-[#001666] font-bold pl-[20px] py-[10px] cursor-pointer hover:text-[#fff] hover:bg-[#ED0678]'
}

export default function HomePageAdmin() {
    const state = useContext(GlobalState)
    const [utils] = state.utilsAPI.utils
    const [check_background, setBackground] = useState(true)
    const [news, setNews] = useState(false)
    const [category, setCategory] = useState(false)
    const [donors, setDonors] = useState(false)
    const [menu, setMenu] = useState(false)
    const [inforContect, setInforContect] = useState(false)
    const [trangchu, setTrangchu] = useState(false)
    const [cluster, setcluster] = useState(false)
    const [answers, setAnswers] = useState(false)
    const [comment, setComment] = useState(false)
    const [qr_code, setQr_code] = useState(false)
    const [changePass, setchangePass] = useState(false)
    const [message, setmessage] = useState(false)
    const [danhmuc, setdanhmuc] = useState(false)
    const [img_slide, setImg_slide] = useState(false)
    const [filePDF, setFilePDF] = useState(false)

    const [isMobile] = state.responsive.isMobile
    const [checkShowMenu, setCheckShowMenu] = useState(false)

    const [noImage, setnoImage] = useState('')
    const [image_new, setImage_new] = useState(null)

    const handleClickPage = (value)=>{
        setCheckShowMenu(false)
        if(value===1){
            setNews(true)
            setBackground(false)
            setCategory(false)
            setDonors(false)
            setMenu(false)
            setInforContect(false)
            setTrangchu(false)
            setcluster(false)
            setAnswers(false)
            setComment(false)
            setQr_code(false)
            setchangePass(false)
            setmessage(false)
            setdanhmuc(false)
            setImg_slide(false)
            setFilePDF(false)
        }
        else if(value===2){
            setCategory(true)
            setNews(false)
            setBackground(false)
            setDonors(false)
            setMenu(false)
            setInforContect(false)
            setTrangchu(false)
            setcluster(false)
            setAnswers(false)
            setComment(false)
            setQr_code(false)
            setchangePass(false)
            setmessage(false)
            setdanhmuc(false)
            setImg_slide(false)
            setFilePDF(false)
        }
        else if(value===3){
            setDonors(true)
            setCategory(false)
            setNews(false)
            setBackground(false)
            setMenu(false)
            setInforContect(false)
            setTrangchu(false)
            setcluster(false)
            setAnswers(false)
            setComment(false)
            setQr_code(false)
            setchangePass(false)
            setmessage(false)
            setdanhmuc(false)
            setImg_slide(false)
            setFilePDF(false)
        }
        else if(value===4){
            setMenu(true)
            setDonors(false)
            setCategory(false)
            setNews(false)
            setBackground(false)
            setInforContect(false)
            setTrangchu(false)
            setcluster(false)
            setAnswers(false)
            setComment(false)
            setQr_code(false)
            setchangePass(false)
            setmessage(false)
            setdanhmuc(false)
            setImg_slide(false)
            setFilePDF(false)
        }
        else if(value===5){
            setInforContect(true)
            setMenu(false)
            setDonors(false)
            setCategory(false)
            setNews(false)
            setBackground(false)
            setTrangchu(false)
            setcluster(false)
            setAnswers(false)
            setComment(false)
            setQr_code(false)
            setchangePass(false)
            setmessage(false)
            setdanhmuc(false)
            setImg_slide(false)
            setFilePDF(false)
        }
        else if(value===6){
            setTrangchu(true)
            setInforContect(false)
            setMenu(false)
            setDonors(false)
            setCategory(false)
            setNews(false)
            setBackground(false)
            setcluster(false)
            setAnswers(false)
            setComment(false)
            setQr_code(false)
            setchangePass(false)
            setmessage(false)
            setdanhmuc(false)
            setImg_slide(false)
            setFilePDF(false)
        }
        else if(value===7){
            setcluster(true)
            setTrangchu(false)
            setInforContect(false)
            setMenu(false)
            setDonors(false)
            setCategory(false)
            setNews(false)
            setBackground(false)
            setAnswers(false)
            setComment(false)
            setQr_code(false)
            setchangePass(false)
            setmessage(false)
            setdanhmuc(false)
            setImg_slide(false)
            setFilePDF(false)
        }
        else if(value===8){
            setAnswers(true)
            setcluster(false)
            setTrangchu(false)
            setInforContect(false)
            setMenu(false)
            setDonors(false)
            setCategory(false)
            setNews(false)
            setBackground(false)
            setComment(false)
            setQr_code(false)
            setchangePass(false)
            setmessage(false)
            setdanhmuc(false)
            setImg_slide(false)
            setFilePDF(false)
        }
        else if(value===9){
            setComment(true)
            setAnswers(false)
            setcluster(false)
            setTrangchu(false)
            setInforContect(false)
            setMenu(false)
            setDonors(false)
            setCategory(false)
            setNews(false)
            setBackground(false)
            setQr_code(false)
            setchangePass(false)
            setmessage(false)
            setdanhmuc(false)
            setImg_slide(false)
            setFilePDF(false)
        }
        else if(value===10){
            setQr_code(true)
            setComment(false)
            setAnswers(false)
            setcluster(false)
            setTrangchu(false)
            setInforContect(false)
            setMenu(false)
            setDonors(false)
            setCategory(false)
            setNews(false)
            setBackground(false)
            setchangePass(false)
            setmessage(false)
            setdanhmuc(false)
            setImg_slide(false)
            setFilePDF(false)
        }
        else if(value===11){
            setchangePass(true)
            setQr_code(false)
            setComment(false)
            setAnswers(false)
            setcluster(false)
            setTrangchu(false)
            setInforContect(false)
            setMenu(false)
            setDonors(false)
            setCategory(false)
            setNews(false)
            setBackground(false)
            setmessage(false)
            setdanhmuc(false)
            setImg_slide(false)
            setFilePDF(false)
        }
        else if(value===12){
            setmessage(true)
            setchangePass(false)
            setQr_code(false)
            setComment(false)
            setAnswers(false)
            setcluster(false)
            setTrangchu(false)
            setInforContect(false)
            setMenu(false)
            setDonors(false)
            setCategory(false)
            setNews(false)
            setBackground(false)
            setdanhmuc(false)
            setImg_slide(false)
            setFilePDF(false)
        }
        else if(value===13){
            setdanhmuc(true)
            setmessage(false)
            setchangePass(false)
            setQr_code(false)
            setComment(false)
            setAnswers(false)
            setcluster(false)
            setTrangchu(false)
            setInforContect(false)
            setMenu(false)
            setDonors(false)
            setCategory(false)
            setNews(false)
            setBackground(false)
            setImg_slide(false)
            setFilePDF(false)
        }
        else if(value===14){
            setImg_slide(true)
            setdanhmuc(false)
            setmessage(false)
            setchangePass(false)
            setQr_code(false)
            setComment(false)
            setAnswers(false)
            setcluster(false)
            setTrangchu(false)
            setInforContect(false)
            setMenu(false)
            setDonors(false)
            setCategory(false)
            setNews(false)
            setBackground(false)
            setFilePDF(false)
        }
        else if(value===15){
            setFilePDF(true)
            setImg_slide(false)
            setdanhmuc(false)
            setmessage(false)
            setchangePass(false)
            setQr_code(false)
            setComment(false)
            setAnswers(false)
            setcluster(false)
            setTrangchu(false)
            setInforContect(false)
            setMenu(false)
            setDonors(false)
            setCategory(false)
            setNews(false)
            setBackground(false)
        }
    }

    const Logout = async ()=>{
        await axios.get('/api/logout')
        localStorage.removeItem('Login')
        window.location.href = "/admin";
    }

    useEffect(()=>{
        const getId = ()=>{
            utils.forEach(el=>{
                if(el.id===1){
                    setnoImage(el.logo)
                }
            })
        }
        getId()
    },[utils])

    useEffect(()=>{
        if(noImage){
            handle_increase()
        }
    },[noImage])

    const handle_increase = async ()=>{
        try {
            const newImage = await handleIncreaseQuality(noImage)
            setImage_new(newImage)
            
        } catch (err) {
            console.error('Error:', err)
        }
    }

    const changeState = (value)=>{
        setCheckShowMenu(value)
    }
    // console.log(checkShowMenu)

  return (
    <div className="w-full flex absolute z-[1000] pb-[20px]">
        <div className={`${isMobile?`${checkShowMenu?'transition ease-in-out translate-x-[0] absolute z-[100] top-0 left-0':'translate-x-[-100%]'} w-[100%]`:'w-[20%]'} h-screen bg-white shadow-2xl border-[1px] border-[#ddd] fixed overflow-y-scroll`}>
            <div className="w-full h-[100px] border-b-[1px] py-[10px]">
                <img src={image_new} className="w-[80px] h-auto mx-auto rounded-[50%]" />
                <LuMenuSquare size={'35px'} className={`${isMobile?'absolute top-[30px] right-[10px]':'hidden'}`}
                    onClick={()=>setCheckShowMenu(false)} />
            </div>

            <div className="w-full h-auto text-[15px]">
                <p onClick={()=>handleClickPage(1)} className={design_css.p_tabar}>
                    <IoNewspaperSharp size={'18px'} color='#001666' className='inline-block mr-[10px]'/> BÀI VIẾT</p>

                <p onClick={()=>handleClickPage(13)} className={design_css.p_tabar}>
                    <IoIosListBox size={'18px'} color='#001666' className='inline-block mr-[10px]'/> DANH MỤC CHÍNH</p>
                
                <p onClick={()=>handleClickPage(2)} className={design_css.p_tabar}>
                    <AiOutlineCluster size={'18px'} color='#001666' className='inline-block mr-[10px]'/> DANH MỤC CẤP 1</p>

                <p onClick={()=>handleClickPage(7)} className={design_css.p_tabar}>
                    <AiOutlineCluster size={'20px'} color='#001666' className='inline-block mr-[10px]'/> DANH MỤC CẤP 2</p>
                
                <p onClick={()=>handleClickPage(8)} className={design_css.p_tabar}>
                    <BiSolidCategory size={'18px'} color='#001666' className='inline-block mr-[10px]'/> THỂ LOẠI GIẢI ĐÁP</p>
                
                <p onClick={()=>handleClickPage(4)} className={design_css.p_tabar}>
                    <IoMenu size={'20px'} color='#001666' className='inline-block mr-[10px]'/> MENU</p>
                
                <p onClick={()=>handleClickPage(9)} className={design_css.p_tabar}>
                    <FaComments size={'18px'} color='#001666' className='inline-block mr-[10px]'/> BÌNH LUẬN</p>
                
                <p onClick={()=>handleClickPage(12)} className={design_css.p_tabar}>
                    <MdEmail size={'18px'} color='#001666' className='inline-block mr-[10px]'/> HÒM THƯ</p>

                <p onClick={()=>handleClickPage(3)} className={design_css.p_tabar}>
                    <FaHandshake size={'18px'} color='#001666' className='inline-block mr-[10px]'/> NHÀ TÀI TRỢ</p>
                
                <p onClick={()=>handleClickPage(10)} className={design_css.p_tabar}>
                    <IoQrCodeOutline size={'20px'} color='#001666' className='inline-block mr-[10px]'/> QR_CODE</p>
                
                <p onClick={()=>handleClickPage(15)} className={design_css.p_tabar}>
                    <FaFileAlt size={'20px'} color='#001666' className='inline-block mr-[10px]'/> QUẢN LÝ FILE</p>

                <p onClick={()=>handleClickPage(14)} className={design_css.p_tabar}>
                    <FaSliders size={'20px'} color='#001666' className='inline-block mr-[10px]'/>HÌNH ẢNH SLIDE</p>

                <p onClick={()=>handleClickPage(6)} className={design_css.p_tabar}>
                    <IoHome size={'18px'} color='#001666' className='inline-block mr-[10px]'/> TRANG CHỦ</p>
                
                <p onClick={()=>handleClickPage(5)} className={design_css.p_tabar}>
                    <MdContactMail size={'18px'} color='#001666' className='inline-block mr-[10px]'/> THÔNG TIN LIÊN HỆ</p>
                
                <p onClick={()=>handleClickPage(11)} className={design_css.p_tabar}>
                    <PiPasswordFill size={'20px'} color='#001666' className='inline-block mr-[10px]'/> ĐỔI MẬT KHẨU</p>
                
                <p onClick={Logout} className={design_css.p_tabar}>
                    <IoLogOutOutline size={'18px'} color='#001666' className='inline-block mr-[10px]'/> ĐĂNG XUẤT</p>
            </div>
        </div>
        
        <div className={`${isMobile?`w-[100%] h-screen`:'w-[80%]'} ${checkShowMenu?'hidden':''} h-auto absolute right-0 px-[10px]`}>
            {
                news ? <News changeState={changeState}/> :
                category ? <Category changeState={changeState}/> :
                donors ? <Donors changeState={changeState}/> :
                menu ? <Menu changeState={changeState}/> :
                inforContect ? <InforContact changeState={changeState}/> :
                trangchu ? <TrangChu changeState={changeState}/> :
                cluster ? <Cluster changeState={changeState}/> :
                answers ? <Answers changeState={changeState}/> :
                comment ? <Comment changeState={changeState}/> :
                qr_code ? <QR_code changeState={changeState}/> :
                changePass ? <ChangePass changeState={changeState}/> :
                message ? <Message changeState={changeState}/>:
                danhmuc ? <DanhMuc changeState={changeState}/>:
                img_slide ? <Img_slide changeState={changeState}/> :
                filePDF ? <FilePDF changeState={changeState}/> :
                <Background changeState={changeState} />
            }
        </div>
    </div>
  )
}
